import React, { useMemo } from "react";
import { Handle } from "reactflow";
import { Button, Flex, Heading, Text } from "@radix-ui/themes";
import { gray } from "@radix-ui/colors";
import ConditionBuilder from "../ConditionBuilder";
import { useTouchpoint } from "../context/TouchpointContext";
import { NODE_STYLE } from "../utils/constants";
import { Target, User } from "lucide-react";

const CriteriaNode = ({
  data,
  title,
  icon: Icon,
  description,
  criteriaKey,
}) => {
  const { touchpoint, setTouchpoint } = useTouchpoint();

  const validConditions = useMemo(
    () =>
      data.conditions.filter(
        (c) => c.field && c.operator && String(c.value).trim()
      ),
    [data.conditions]
  );

  const hasUnsavedChanges = useMemo(
    () =>
      JSON.stringify(validConditions) !==
      JSON.stringify(touchpoint.eligibility_criteria?.[criteriaKey] || []),
    [validConditions, touchpoint.eligibility_criteria, criteriaKey]
  );

  const handleSave = () => {
    setTouchpoint({
      ...touchpoint,
      eligibility_criteria: {
        ...touchpoint.eligibility_criteria,
        [criteriaKey]: validConditions,
      },
    });
  };

  return (
    <Flex direction="column" gap="3" style={{ ...NODE_STYLE, width: 480 }}>
      <Flex align="center" gap="2">
        <Icon color={gray.gray8} size={18} />
        <Heading size="3" weight="medium">
          {title}
        </Heading>
      </Flex>
      <Text color="gray">
        {data.conditions.length > 0
          ? description.withConditions
          : description.withoutConditions}
      </Text>
      <ConditionBuilder {...data} />
      <Handle
        type="target"
        position="top"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
      <Handle
        type="source"
        position="bottom"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
      {/* {hasUnsavedChanges && (
        <Button mt="2" mb="1" size="2" onClick={handleSave}>
          Save changes
        </Button>
      )} */}
    </Flex>
  );
};

const UserCriteriaNode = (props) => {
  return (
    <CriteriaNode
      {...props}
      title="Who to target?"
      icon={User}
      description={{
        withConditions: "Users must meet ALL of the following criteria:",
        withoutConditions:
          "Add criteria to specify which users to target with this touchpoint.",
      }}
      criteriaKey="user_criteria"
    />
  );
};

const SessionCriteriaNode = (props) => {
  return (
    <CriteriaNode
      {...props}
      title="When to run?"
      icon={Target}
      description={{
        withConditions:
          "The user's session must meet ALL of the following conditions:",
        withoutConditions:
          "Add conditions to specify when in a user's session to run this touchpoint.",
      }}
      criteriaKey="session_criteria"
    />
  );
};

export { UserCriteriaNode, SessionCriteriaNode };

import React from "react";
import { NodeToolbar } from "reactflow";
import { motion, AnimatePresence } from "framer-motion";

const AnimatedNodeToolbar = ({ children, position = "top", ...props }) => {
  const animationProps = {
    initial: { opacity: 0, y: position === "top" ? 10 : -10, scale: 0.95 },
    animate: { opacity: 1, y: 0, scale: 1 },
    exit: { opacity: 0, y: position === "top" ? 10 : -10, scale: 0.95 },
    transition: { duration: 0.15, ease: [0.4, 0, 0.2, 1] },
  };

  return (
    <NodeToolbar position={position} {...props}>
      <AnimatePresence>
        <motion.div {...animationProps}>{children}</motion.div>
      </AnimatePresence>
    </NodeToolbar>
  );
};

export default AnimatedNodeToolbar;

import React, { createContext, useState, useContext } from "react";

const FlowContext = createContext();

export const useFlow = () => useContext(FlowContext);

export const FlowProvider = ({ children }) => {
  const [isZoomable, setIsZoomable] = useState(true);

  const toggleZoomable = () => {
    setIsZoomable(!isZoomable);
  };

  return (
    <FlowContext.Provider
      value={{
        isZoomable,
        toggleZoomable,
      }}
    >
      {children}
    </FlowContext.Provider>
  );
};

import React from "react";
import { Handle, NodeToolbar } from "reactflow";
import { Flex, Text } from "@radix-ui/themes";
import { violet } from "@radix-ui/colors";
import { NODE_STATUS } from "../utils/constants";
import { Pencil, Plus } from "lucide-react";

const PlaceholderNode = ({ data, selected }) => {
  return (
    <div
      style={{
        background: violet.violet3,
        borderRadius: 4,
        padding: 8,
        ...(selected && {
          border: "1px solid transparent",
          boxShadow: `0 0 0 2px ${violet.violet9}`,
        }),
      }}
    >
      <Flex gap="2" align="center">
        <Plus color={violet.violet9} size={20} strokeWidth={2.2} />
      </Flex>
      {!!data.status && (
        <NodeToolbar>
          <Flex align="center" gap="2" style={{ background: "#F8F8F8" }}>
            <Pencil color={violet.violet9} size={12} strokeWidth={1.75} />
            <Text size="1" color="violet" weight="medium">
              {data.status === NODE_STATUS.CREATING ? "NEW" : "EDITING"}
            </Text>
          </Flex>
        </NodeToolbar>
      )}
      <Handle
        type="target"
        position="top"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
    </div>
  );
};

export default PlaceholderNode;

import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import { OrganizationProvider } from "./context/OrganizationContext";
import { OpportunityProvider } from "./context/OpportunityContext";
import { TouchpointProvider } from "./context/TouchpointContext";
import { OpeningsProvider } from "./context/OpeningsContext";
import { CampaignProvider } from "./context/CampaignContext";
import OpportunityList from "./OpportunityList";
import OpportunityViewer from "./OpportunityViewer";
import TouchpointEditor from "./TouchpointEditor";
import TouchpointList from "./TouchpointList";
import TouchpointDetail from "./TouchpointDetail";
import AccountList from "./AccountList";
import AccountDetail from "./AccountDetail";
import Inbox from "./Inbox";
import LoginPage from "./LoginPage";
import CreateAccountPage from "./CreateAccountPage";
import OrganizationSettings from "./OrganizationSettings";
import Nav from "./Nav";
import HomePage from "./HomePage";
import "./App.css";
import BG from "./assets/bg.png";

export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const Layout = () => (
  <div className="App" style={{ backgroundImage: `url(${BG})` }}>
    <Nav />
    <div className="content">
      <main>
        <Outlet />
      </main>
    </div>
  </div>
);

const AuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/accounts">
          <Route index element={<AccountList />} />
          <Route path=":accountId" element={<AccountDetail />} />
        </Route>
        <Route path="/inbox">
          <Route index element={<Inbox />} />
          <Route path=":openingId" element={<Inbox />} />
        </Route>

        <Route path="/opportunities">
          <Route index element={<OpportunityList />} />
          <Route path=":id">
            <Route path="view" element={<OpportunityViewer />} />
          </Route>
        </Route>
        <Route path="/touchpoints">
          <Route index element={<TouchpointList />} />
          <Route path="create" element={<TouchpointEditor />} />
          <Route path=":id">
            <Route index element={<TouchpointDetail />} />
            <Route path="edit" element={<TouchpointEditor />} />
          </Route>
        </Route>
        <Route path="/org-settings">
          <Route index element={<OrganizationSettings />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};

const AuthenticatedApp = () => {
  return (
    <OrganizationProvider>
      <CampaignProvider>
        <TouchpointProvider>
          <OpportunityProvider>
            <OpeningsProvider>
              <AuthenticatedRoutes />
            </OpeningsProvider>
          </OpportunityProvider>
        </TouchpointProvider>
      </CampaignProvider>
    </OrganizationProvider>
  );
};

const PublicApp = () => (
  <Routes>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/register" element={<CreateAccountPage />} />
    <Route path="*" element={<Navigate to="/login" />} />
  </Routes>
);

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      {isAuthenticated ? <AuthenticatedApp /> : <PublicApp />}
    </BrowserRouter>
  );
}

export default App;

import React, { createContext, useCallback, useState, useContext } from "react";
import { useAuth } from "./AuthContext";
import { BASE_URL } from "../App";

const OpportunityContext = createContext();

export const useOpportunity = () => useContext(OpportunityContext);

export const OpportunityProvider = ({ children }) => {
  const { user } = useAuth();
  const [opportunity, setOpportunity] = useState(null);

  const getOpportunities = useCallback(async () => {
    const response = await fetch(
      `${BASE_URL}/api/v1/organizations/${user.organization_id}/promotional-opportunities`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );
    const data = await response.json();
    return data.promotional_opportunities;
  }, [user.organization_id]);

  const getOpportunity = useCallback(
    async (id) => {
      const opportunities = await getOpportunities();
      return opportunities.find((opportunity) => opportunity.id === id);
    },
    [user.organization_id]
  );

  return (
    <OpportunityContext.Provider
      value={{
        getOpportunities,
        getOpportunity,
        opportunity,
        setOpportunity,
      }}
    >
      {children}
    </OpportunityContext.Provider>
  );
};

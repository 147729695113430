import React, { useEffect, useState } from "react";
import { ReactFlowProvider } from "reactflow";
import { FlowProvider } from "./context/FlowContext";
import Flow from "./Flow";
import useFlowActions from "./hooks/useFlowActions";

const SessionPreview = ({ session }) => {
  const [key, setKey] = useState(0);
  const { nodes, edges } = useFlowData(session);

  // Rerender when session changes
  useEffect(() => {
    setKey((key) => key + 1);
  }, [session]);

  if (!nodes.length || !edges.length) return null;
  return <Flow key={key} initialNodes={nodes} initialEdges={edges} isPreview />;
};

const withProviders = (Component) => (props) => (
  <ReactFlowProvider>
    <FlowProvider>
      <Component {...props} />
    </FlowProvider>
  </ReactFlowProvider>
);

export default withProviders(SessionPreview);

// Custom hook for managing flow data
const useFlowData = (session) => {
  const flowActions = useFlowActions();

  return {
    nodes: initializeNodes(session, flowActions),
    edges: initializeEdges(session, flowActions),
  };
};

// Helper function to initialize nodes
const initializeNodes = (
  session,
  { createUserCountNode, createEventGroupNode }
) => [
  createUserCountNode(),
  ...session.event_groups.map((event_group) => {
    return createEventGroupNode(event_group);
  }),
];

// Helper function to initialize edges
const initializeEdges = (session, { createEdge }) => {
  const edges = [];
  let previousNodeId = "userCount";

  session.event_groups.forEach((event_group) => {
    const currentNodeId = `eventGroup-${event_group.group_id}`;
    edges.push(createEdge(previousNodeId, currentNodeId));
    previousNodeId = currentNodeId;
  });

  return edges;
};

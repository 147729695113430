import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";
import ReactSelect, { components } from "react-select";

const SelectContext = createContext();

const Root = ({ children, value, onValueChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef(null);

  const handleChange = (newValue) => {
    if (onValueChange) {
      onValueChange(newValue);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef.current && !triggerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SelectContext.Provider
      value={{ isOpen, setIsOpen, value, onChange: handleChange, triggerRef }}
    >
      <div style={{ position: "relative" }}>{children}</div>
    </SelectContext.Provider>
  );
};

const Trigger = ({ children }) => {
  const { isOpen, setIsOpen, triggerRef } = useContext(SelectContext);

  return (
    <div
      ref={triggerRef}
      onClick={() => setIsOpen(!isOpen)}
      style={{ cursor: "pointer" }}
    >
      {children}
    </div>
  );
};

const Content = ({ children }) => {
  const { isOpen, value, onChange, triggerRef } = useContext(SelectContext);

  if (!isOpen) return null;

  const options = React.Children.map(children, (child) => ({
    value: child.props.value,
    label: child.props.children,
  }));

  const customStyles = {
    control: () => ({ display: "none" }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "8px",
      padding: "4px 8px",
      position: "absolute",
      width: "auto",
      minWidth: triggerRef.current ? triggerRef.current.offsetWidth : "auto",
      transform: `translateY(${triggerRef.current ? triggerRef.current.offsetHeight : 0}px)`,
      left: 0,
    }),
    menuList: (provided) => ({
      ...provided,
      width: "max-content",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#6E56CF" : "white",
      color: state.isSelected ? "white" : "black",
      padding: "6px 16px",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: state.isSelected ? "#6E56CF" : "#f0f0f0",
      },
    }),
  };

  const selectProps = {
    value: options.find((option) => option.value === value),
    onChange: (selectedOption) => onChange(selectedOption.value),
    options,
    styles: customStyles,
    menuIsOpen: true,
    autoFocus: true,
    backspaceRemovesValue: false,
    components: { Option: Item },
    controlShouldRenderValue: false,
    hideSelectedOptions: false,
    isClearable: false,
    menuShouldBlockScroll: false,
    menuShouldScrollIntoView: false,
    placeholder: "Search...",
    tabSelectsValue: false,
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        width: triggerRef.current ? triggerRef.current.offsetWidth : "auto",
        height: triggerRef.current ? triggerRef.current.offsetHeight : "auto",
      }}
    >
      <ReactSelect {...selectProps} />
    </div>
  );
};

const Item = ({ children, ...props }) => {
  return <components.Option {...props}>{children}</components.Option>;
};

const Select = {
  Root,
  Trigger,
  Content,
  Item,
};

export default Select;

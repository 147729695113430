import React, { useMemo } from "react";
import { Handle, useReactFlow, useViewport } from "reactflow";
import { Button, Flex } from "@radix-ui/themes";
import { violet } from "@radix-ui/colors";
import { Plus } from "lucide-react";
import Icon from "../Icon";
import { NODE_STYLE } from "../utils/constants";
import AnimatedNodeToolbar from "../AnimatedNodeToolbar";
import useFlowActions from "../hooks/useFlowActions";
import { generateUUID } from "../utils";

const ROTATED_SHADOW =
  "-2px -2px 0px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.70) inset, 2px 2px 0px 0px rgba(0, 0, 0, 0.02) inset, -0.125px -0.125px 0px 0px rgba(0, 0, 0, 0.8) inset";

const VariantRootNode = ({ id, data, selected }) => {
  const { zoom } = useViewport();
  const toolbarOffset = useMemo(
    () => (selected ? zoom * 16 : 0),
    [selected, zoom]
  );

  const { getNode, setNodes, setEdges } = useReactFlow();
  const { createVariantNode, createEdge } = useFlowActions();

  const handleAddNode = () => {
    const variantNode = createVariantNode(
      {
        variant_id: generateUUID(),
        variant_name: "",
        variant_description: "",
        eligibility_criteria: [],
        variant_data: {},
      },
      true
    );

    setNodes((nds) => [
      ...nds.map((node) =>
        node.id === id ? { ...node, selected: false } : node
      ),
      variantNode,
    ]);
    setEdges((eds) => [...eds, createEdge(`variantRoot`, variantNode.id)]);
  };

  return (
    <div onDoubleClick={handleAddNode}>
      <Flex direction="column" gap="2" position="relative">
        <Flex
          style={{
            ...NODE_STYLE,
            backgroundColor: "#F2F2F5",
            borderRadius: 8,
            width: 56,
            height: 56,
            transform: "rotate(45deg)",
            boxShadow: ROTATED_SHADOW,
            ...(selected && {
              border: "1px solid transparent",
              boxShadow: `0 0 0 2px ${violet.violet9}, ` + ROTATED_SHADOW,
            }),
          }}
        />
        <Icon
          name="MessageDotsIcon"
          color="#000"
          height={30}
          width={30}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            opacity: 0.3,
          }}
        />
      </Flex>
      <AnimatedNodeToolbar position="bottom" offset={toolbarOffset}>
        <Button
          onClick={handleAddNode}
          size="1"
          variant="outline"
          style={{ background: "#F8F8F8" }}
        >
          <Plus size={12} />
          Add variant
        </Button>
      </AnimatedNodeToolbar>
      <Handle
        type="target"
        position="top"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
      <Handle
        type="source"
        position="bottom"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
    </div>
  );
};

export default VariantRootNode;

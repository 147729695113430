import React, { createContext, useState, useContext } from "react";
import { BASE_URL } from "../App";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const getFromLocalStorage = (key, defaultValue) => () => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") || false
  );
  const [user, setUser] = useState(getFromLocalStorage("user", null));

  const createUser = async (email, password, inviteCode, agreedTOS) => {
    const response = await fetch(`${BASE_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        inviteCode: inviteCode,
        agreedTOS: agreedTOS,
      }),
      credentials: "include",
    });

    if (response.ok) {
      const data = await response.json();
      const user = data.user;
      setIsAuthenticated(true);
      localStorage.setItem("isAuthenticated", true);

      setUser(user);
      localStorage.setItem("user", JSON.stringify(user));

      return true;
    } else {
      return false;
    }
  };

  const login = async (email, password) => {
    const response = await fetch(`${BASE_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
      credentials: "include",
    });

    if (response.ok) {
      const data = await response.json();
      const user = data.user;
      setIsAuthenticated(true);
      localStorage.setItem("isAuthenticated", true);

      setUser(user);
      localStorage.setItem("user", JSON.stringify(user));

      return true;
    } else {
      return false;
    }
  };

  const logout = async () => {
    const response = await fetch(`${BASE_URL}/logout`, {
      method: "POST",
      credentials: "include",
    });

    setIsAuthenticated(false);
    localStorage.removeItem("isAuthenticated");

    setUser(null);
    localStorage.removeItem("user");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, login, logout, createUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

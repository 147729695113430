import UserCountNode from "./UserCountNode";
import EventGroupNode from "./EventGroupNode";
import EventsNode from "./EventsNode";
import PlaceholderNode from "./PlaceholderNode";
import VariantNode from "./VariantNode";
import BadgeNode from "./BadgeNode";
import { UserCriteriaNode, SessionCriteriaNode } from "./CriteriaNode";
import SegmentRootNode from "./SegmentRootNode";
import SegmentNode from "./SegmentNode";
import LocationNode from "./LocationNode";
import VariantRootNode from "./VariantRootNode";
const nodeTypes = {
  userCriteria: UserCriteriaNode,
  sessionCriteria: SessionCriteriaNode,
  location: LocationNode,
  variantRoot: VariantRootNode,
  segmentRoot: SegmentRootNode,
  segment: SegmentNode,
  userCount: UserCountNode,
  eventGroup: EventGroupNode,
  events: EventsNode,
  placeholder: PlaceholderNode,
  variant: VariantNode,
  badge: BadgeNode,
};

export default nodeTypes;

export const NODE_STYLE = {
  padding: 16,
  border: "1px solid rgba(0, 0, 0, 0.12)",
  borderRadius: 12,
  boxShadow:
    "0px -3px 0px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.70) inset, 0px 2px 0px 0px rgba(0, 0, 0, 0.02) inset",
  background: "#fff",
  fontSize: "14px",
  color: "#000",
  textAlign: "left",
};

export const NODE_STATUS = {
  EDITING: "editing",
  CREATING: "creating",
};

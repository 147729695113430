import React, { useEffect, useState } from "react";
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  useStoreApi,
  getNodesBounds,
} from "reactflow";
import "reactflow/dist/style.css";
import nodeTypes from "./NodeTypes";
import useAutoLayout from "./hooks/useAutoLayout";
import { useFlow } from "./context/FlowContext";
import OverviewCard from "./OverviewCard";
import Sidebar from "./Sidebar";
import MiniMap from "./MiniMap";
import "./Flow.css";

const TRANSLATE_EXTENT_PADDING = { px: 1600, py: 600 };

const Flow = ({ initialNodes, initialEdges, isPreview = false }) => {
  const store = useStoreApi();
  const { isZoomable } = useFlow();
  // todo: move state to Editor
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [translateExtent, setTranslateExtent] = useState([
    [0, 0],
    [Infinity, Infinity],
  ]);
  useAutoLayout();

  const activeNodeId = nodes.find((node) => node.data?.status)?.id;

  useEffect(() => {
    const nodes = Array.from(store.getState().nodeInternals.values());
    if (!nodes.length) return;

    const { x, y, width, height } = getNodesBounds(nodes);
    const { px, py } = TRANSLATE_EXTENT_PADDING;

    setTranslateExtent([
      [x - px, y - py],
      [x + width + px, y + height + py],
    ]);
  }, [nodes, store]);

  const handleNodesChange = (changes) => {
    if (activeNodeId) {
      // Prevent other nodes from being selected and ensure activeNodeId remains selected
      changes = changes.map((change) =>
        change.type === "select"
          ? { ...change, selected: change.id === activeNodeId }
          : change
      );
    }

    onNodesChange(changes);
  };

  return (
    <div className="flow-container">
      <div className="canvas">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={handleNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          translateExtent={translateExtent}
          {...getFlowOptions(isZoomable, isPreview)}
        >
          {!isPreview && (
            <>
              <Controls
                showInteractive={false}
                showFitView={false}
                position="bottom-left"
              />
              <OverviewCard />
              <MiniMap activeNodeId={activeNodeId} />
              <Sidebar activeNodeId={activeNodeId} />
            </>
          )}
          <Background color="#DFDFDF" variant="dots" gap={24} size={2} />
        </ReactFlow>
      </div>
    </div>
  );
};

const getFlowOptions = (isZoomable, isPreview) => ({
  proOptions: { hideAttribution: true },
  selectionOnDrag: true,
  selectionKeyCode: null,
  defaultEdgeOptions: { deletable: false },
  minZoom: 0.75,
  maxZoom: 1.25,
  zoomOnDoubleClick: false,
  panOnScrollSpeed: 1,
  panOnScroll: isZoomable,
  zoomOnPinch: isZoomable,
  zoomOnScroll: isZoomable,
  ...(isPreview && { elementsSelectable: false, minZoom: 0.25, maxZoom: 1.25 }),
});

export default Flow;

import React, { useMemo } from "react";
import { Handle, NodeToolbar, useViewport } from "reactflow";
import { Button, Flex, Text } from "@radix-ui/themes";
import { violet } from "@radix-ui/colors";
import { Pencil } from "lucide-react";
import Icon from "../Icon";
import { NODE_STATUS, NODE_STYLE } from "../utils/constants";
import AnimatedNodeToolbar from "../AnimatedNodeToolbar";

const ROTATED_SHADOW =
  "-2px -2px 0px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.70) inset, 2px 2px 0px 0px rgba(0, 0, 0, 0.02) inset, -0.125px -0.125px 0px 0px rgba(0, 0, 0, 0.8) inset";

const SegmentRootNode = ({ data, selected }) => {
  const { zoom } = useViewport();
  const toolbarOffset = useMemo(
    () => (selected ? zoom * 16 : 0),
    [selected, zoom]
  );

  return (
    <div onDoubleClick={() => data.updateStatus(NODE_STATUS.EDITING)}>
      <Flex direction="column" gap="2" position="relative">
        <Flex
          style={{
            ...NODE_STYLE,
            borderRadius: 8,
            width: 64,
            height: 64,
            transform: "rotate(45deg)",
            boxShadow: ROTATED_SHADOW,
            ...(selected && {
              border: "1px solid transparent",
              boxShadow: `0 0 0 2px ${violet.violet9}, ` + ROTATED_SHADOW,
            }),
          }}
        />
        <Icon
          name="PersonFilledIcon"
          color="#000"
          height={30}
          width={30}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -56%)",
            opacity: 0.3,
          }}
        />
      </Flex>
      {!!data.status ? (
        <NodeToolbar offset={toolbarOffset}>
          <Flex align="center" gap="2" style={{ background: "#F8F8F8" }}>
            <Pencil color={violet.violet9} size={12} strokeWidth={1.75} />
            <Text size="1" color="violet" weight="medium">
              EDITING
            </Text>
          </Flex>
        </NodeToolbar>
      ) : (
        <>
          <AnimatedNodeToolbar offset={toolbarOffset}>
            <Button
              onClick={() => data.updateStatus(NODE_STATUS.EDITING)}
              size="1"
              variant="outline"
              style={{ background: "#F8F8F8" }}
            >
              Edit
            </Button>
          </AnimatedNodeToolbar>
        </>
      )}
      <Handle
        type="target"
        position="top"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
      <Handle
        type="source"
        position="bottom"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
    </div>
  );
};

export default SegmentRootNode;

import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Text,
  Flex,
  TextField,
  Button,
  Badge,
  ScrollArea,
  IconButton,
  Table,
  Avatar,
} from "@radix-ui/themes";
import { gray, grayA, violet } from "@radix-ui/colors";
import {
  ChevronDown,
  Calendar,
  Building,
  SlidersHorizontal,
  ArrowDownWideNarrow,
  Route,
  SearchX,
  Radar,
  Building2,
  Activity,
  PiggyBank,
  CalendarCheck,
  Save,
  Siren,
  TriangleAlert,
  CircleCheckBig,
  EllipsisVertical,
  Users,
  Boxes,
  ChevronsUpDown,
} from "lucide-react";
import { DotsVerticalIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import Select from "./Select";
import { abbrevNum, daysBetween, pluralize, toTimeAgo } from "./utils";
import "./AccountList.css";

const ListSelector = ({ value, onChange }) => {
  const options = [
    { value: "reach", label: "All" },
    { value: "dateDiscovered", label: "Recently discovered" },
    { value: "message_saturation", label: "Least saturated" },
  ];

  const selectedOption =
    options.find((option) => option.value === value) || options[0];

  return (
    <Select.Root value={value} onValueChange={onChange}>
      <Select.Trigger variant="ghost">
        <Flex align="center" gap="1" style={{ color: violet.violet10 }}>
          <Text size="3" weight="medium">
            {selectedOption.label}
          </Text>
          <ChevronDown size={16} />
        </Flex>
      </Select.Trigger>
      <Select.Content>
        {options.map((option) => (
          <Select.Item key={option.value} value={option.value}>
            {option.label}
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};

const Filter = ({ icon: Icon, value, options, onChange }) => {
  const selectedOption =
    options.find((option) => option.value === value) || options[0];

  return (
    <Flex
      display={{ initial: "none", sm: "flex" }}
      style={{ userSelect: "none" }}
    >
      <Select.Root value={value} onValueChange={onChange}>
        <Select.Trigger variant="ghost" color="gray">
          <Flex align="center" gap="2">
            <Icon size={14} color={grayA.grayA9} />
            <Text size="1" weight="medium" style={{ color: grayA.grayA10 }}>
              {selectedOption.label}
            </Text>
            <ChevronDown size={14} color={gray.gray9} />
          </Flex>
        </Select.Trigger>
        <Select.Content position="popper">
          {options.map((option) => (
            <Select.Item key={option.value} value={option.value}>
              {option.label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    </Flex>
  );
};

const FilterBar = ({ filters, onFilterChange }) => (
  <Flex
    justify="between"
    align="center"
    px="4"
    py="2"
    ml="2"
    style={{
      backgroundColor: "#F7F7F9",
      borderRadius: 8,
    }}
  >
    <Flex gap="20px">
      <Filter
        key="status"
        icon={Activity}
        value={filters.status}
        options={[
          { value: "all", label: "All statuses" },
          { value: "high-risk", label: "High risk" },
          { value: "at-risk", label: "At risk" },
          { value: "on-track", label: "On track" },
          { value: "collecting-data", label: "Collecting data" },
        ]}
        onChange={(value) => onFilterChange("status", value)}
      />
      <Filter
        key="revenue"
        icon={PiggyBank}
        value={filters.revenue}
        options={[
          { value: "all", label: "Any revenue" },
          { value: "top-5", label: "Top 5%" },
          { value: "top-10", label: "Top 10%" },
          { value: "top-25", label: "Top 25%" },
        ]}
        onChange={(value) => onFilterChange("revenue", value)}
      />
      <Filter
        key="date"
        icon={CalendarCheck}
        value={filters.date}
        options={[
          { value: "all", label: "Any close date" },
          { value: "7", label: "Last 7 days" },
          { value: "30", label: "Last 30 days" },
          { value: "90", label: "Last 90 days" },
        ]}
        onChange={(value) => onFilterChange("date", value)}
      />
      <Button variant="ghost" color="gray" radius="large" disabled mr="1">
        <Text
          size="1"
          weight="medium"
          style={{ color: grayA.grayA10, opacity: 0.6 }}
        >
          + Save as list
        </Text>
      </Button>
    </Flex>
  </Flex>
);

// TODO: create shared components for Toolbar, Search, EmptyState, Filter, ActionCard, FilterBar
const Toolbar = ({
  onSearch,
  onSortChange,
  sortBy,
  filters,
  onFilterChange,
}) => {
  return (
    <Flex
      direction="column"
      justify="between"
      align="center"
      style={{ borderBottom: `1px solid ${gray.gray6}` }}
    >
      <Flex
        align="center"
        justify="between"
        px="5"
        style={{
          width: "100%",
          height: 60,
        }}
      >
        <Flex align="center" gap="4">
          <Flex align="center" gap="10px">
            <Building2 size={20} strokeWidth={2} color={grayA.grayA8} />
            <Text size="5" weight="medium">
              Accounts
            </Text>
          </Flex>
          <ListSelector
            value="reach"
            onChange={(value) => console.log(value)}
          />
          <FilterBar filters={filters} onFilterChange={onFilterChange} />
        </Flex>
        <Search onSearch={onSearch} />
      </Flex>
    </Flex>
  );
};

const Search = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    onSearch(term);
  };

  return (
    <TextField.Root
      size="2"
      placeholder="Search..."
      radius="large"
      style={{
        width: "224px",
        boxShadow: "none",
        border: "1px solid var(--gray-4)",
      }}
      value={searchTerm}
      onChange={handleSearch}
    >
      <TextField.Slot>
        <MagnifyingGlassIcon height="16" width="16" />
      </TextField.Slot>
    </TextField.Root>
  );
};

// todo: move to shared component
export const StatusBadge = ({ size = 2, status }) => {
  const options = {
    "high-risk": { color: "red", icon: Siren, label: "High risk" },
    "at-risk": { color: "amber", icon: TriangleAlert, label: "At risk" },
    "on-track": { color: "green", icon: CircleCheckBig, label: "On track" },
    "collecting-data": {
      color: "gray",
      icon: Radar,
      label: "Collecting data",
    },
  };

  const content = options[status];

  return (
    <Badge
      color={content.color}
      radius="large"
      size={size}
      style={{ width: "fit-content" }}
    >
      <content.icon size={12} />
      {content.label}
    </Badge>
  );
};

const Attribute = ({ icon: Icon, text }) => (
  <Flex align="center" gap="5px">
    <Icon size={14} color={gray.gray8} />
    <Text size="1" style={{ color: gray.gray10 }}>
      {text}
    </Text>
  </Flex>
);

const AccountCard = ({ account }) => {
  const navigate = useNavigate();

  return (
    <Flex
      className="account-card"
      onClick={() => navigate(`/accounts/${account.account_id}`)}
      direction="column"
      gap="3"
      px="4"
      py="3"
      style={{
        background: "white",
        borderRadius: 16,
        cursor: "default",
        width: 348,
        height: 184,
      }}
    >
      <Flex align="center" justify="between">
        <Flex align="center" gap="3">
          <Flex
            align="center"
            justify="center"
            style={{
              width: 28,
              height: 28,
              border: `1px solid var(--gray-4)`,
              borderRadius: 8,
            }}
          >
            <Building2 size={14} color={grayA.grayA10} />
          </Flex>
          <Text className="account-card-title" size="3" weight="medium">
            {account.account_name}
          </Text>
        </Flex>
        <IconButton variant="ghost" color="gray" size="1">
          <EllipsisVertical size={16} />
        </IconButton>
      </Flex>
      <Flex align="center" gap="2">
        <StatusBadge status={account.account_status} />
        <Flex
          align="center"
          gap="4"
          px="4"
          py="1"
          style={{ background: gray.gray2, borderRadius: 6 }}
        >
          <Attribute icon={PiggyBank} text={account.monthly_value} />
          <Attribute
            icon={Users}
            text={account.account_usage.account_users_count}
          />
          <Attribute
            icon={Boxes}
            text={`${account.account_usage.account_products_used} ${pluralize(
              "product",
              account.account_usage.account_products_used
            )}`}
          />
        </Flex>
      </Flex>
      <Text size="2" style={{ color: grayA.grayA10 }}>
        <ul style={{ margin: 0, paddingLeft: 16 }}>
          {account.account_usage.account_usage_summary.map((usage_item, i) => (
            <li key={i}>{usage_item}</li>
          ))}
        </ul>
      </Text>
    </Flex>
  );
};

const RequiresAttention = ({ accounts }) => {
  const highRiskAccounts = accounts.filter(
    (account) => account.account_status === "high-risk"
  );

  return (
    <Flex
      direction="column"
      pt="3"
      pb="20px"
      px="5"
      gap="3"
      style={{
        background: "var(--violet-1)",
      }}
    >
      <Flex justify="between" align="center">
        <Flex align="center" gap="3">
          <Text size="2" weight="medium" style={{ color: grayA.grayA11 }}>
            Requires attention
          </Text>
          <Flex
            px="1"
            py="2px"
            style={{ background: gray.gray3, borderRadius: 4 }}
          >
            <Text size="1" weight="medium" style={{ color: grayA.grayA10 }}>
              {highRiskAccounts.length}
            </Text>
          </Flex>
        </Flex>
        <Button variant="ghost" color="gray" radius="large" mx="1">
          <Flex align="center" gap="2">
            <ChevronsUpDown size={14} color={grayA.grayA10} />
            <Text size="2" style={{ color: grayA.grayA10 }}>
              Expand
            </Text>
          </Flex>
        </Button>
      </Flex>
      <Flex align="center" gap="4">
        {highRiskAccounts.map((account) => (
          <AccountCard account={account} key={account.account_id} />
        ))}
      </Flex>
    </Flex>
  );
};

const AccountRow = ({ account, onRowClick }) => (
  <Table.Row className="table-row" onClick={onRowClick}>
    <Table.Cell>
      <Flex align="center" gap="2" ml="3">
        <Avatar
          size="1"
          //   src={account.opening_user.user_organization.organization_avatar}
          style={{
            padding: 3,
            backgroundColor: "white",
            border: `1px solid ${grayA.grayA4}`,
            height: 20,
            width: 20,
          }}
        />
        <Text size="2" weight="medium">
          {account.account_name}
        </Text>
      </Flex>
    </Table.Cell>
    <Table.Cell>
      <Flex direction="column">
        <StatusBadge size="1" status={account.account_status} />
        {account.account_status !== "on-track" && (
          <Text size="2" mt="2" mb="1" style={{ color: grayA.grayA10 }}>
            <ul style={{ margin: 0, paddingLeft: 16 }}>
              {account.account_usage.account_usage_summary.map(
                (usage_item, i) => (
                  <li key={i}>{usage_item}</li>
                )
              )}
            </ul>
          </Text>
        )}
      </Flex>
    </Table.Cell>
    <Table.Cell>
      <Text size="2" mr="6px">
        ${account.account_value.toLocaleString()}
      </Text>
      <Text size="2" style={{ color: grayA.grayA8 }}>
        ({account.monthly_value})
      </Text>
    </Table.Cell>
    <Table.Cell>
      <Text size="2">{account.account_usage.account_users_count}</Text>
    </Table.Cell>
    <Table.Cell>
      <Text size="2">{account.account_usage.account_products_used}</Text>
    </Table.Cell>
    <Table.Cell>
      <Text size="2">
        {account.account_usage?.deal_closed
          ? toTimeAgo(account.account_usage.deal_closed, true)
          : ""}
      </Text>
    </Table.Cell>
  </Table.Row>
);

const SortableColumnHeader = ({ label, sortKeys, sortBy, onSortChange }) => {
  const isCurrentSort = Array.isArray(sortKeys)
    ? sortKeys.includes(sortBy)
    : sortBy === sortKeys;

  return (
    <Flex
      align="center"
      gap="2"
      style={{
        cursor: "default",
        userSelect: "none",
        color: isCurrentSort ? violet.violet10 : grayA.grayA10,
      }}
    >
      {label}
      {isCurrentSort && (
        <ArrowDownWideNarrow
          size={12}
          strokeWidth={1.5}
          color={violet.violet10}
        />
      )}
    </Flex>
  );
};

const AccountsTable = ({ accounts, sortBy, setSortBy }) => {
  const navigate = useNavigate();

  const filteredAccounts = accounts.filter(
    (account) => account.account_status !== "high-risk"
  );

  return (
    <Table.Root className="accounts-table" variant="surface">
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell style={{ width: "25%" }}>
            <SortableColumnHeader
              label={
                <Flex align="center" ml="3">
                  Company
                </Flex>
              }
              sortKeys={["company"]}
              sortBy={sortBy}
              onSortChange={setSortBy}
            />
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell style={{ width: "38%" }}>
            <SortableColumnHeader
              label="Status"
              sortKeys={["account_status"]}
              sortBy={sortBy}
              onSortChange={setSortBy}
            />
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell style={{ width: "14%" }}>
            <SortableColumnHeader
              label="ARR"
              sortKeys={["account_value"]}
              sortBy={sortBy}
              onSortChange={setSortBy}
            />
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell style={{ width: "7%" }}>
            <SortableColumnHeader
              label="Users"
              sortKeys={["account_usage.account_users_count"]}
              sortBy={sortBy}
              onSortChange={setSortBy}
            />
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell style={{ width: "8%" }}>
            <SortableColumnHeader
              label="Products used"
              sortKeys={["account_usage.account_products_used"]}
              sortBy={sortBy}
              onSortChange={setSortBy}
            />
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell style={{ width: "8%" }}>
            <SortableColumnHeader
              label="Deal closed"
              sortKeys={["account_usage.deal_closed"]}
              sortBy={sortBy}
              onSortChange={setSortBy}
            />
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {filteredAccounts.map((account) => (
          <AccountRow
            key={account.account_id}
            account={account}
            onRowClick={() => navigate(`/accounts/${account.account_id}`)}
          />
        ))}
      </Table.Body>
    </Table.Root>
  );
};

const EmptyState = ({ message, icon: Icon }) => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    py="200px"
    gap="4"
    style={{ borderBottom: `1px solid ${grayA.grayA4}` }}
  >
    <Icon size={64} strokeWidth={1.5} color={gray.gray6} />
    <Text size="2" color="gray" align="center">
      {message}
    </Text>
  </Flex>
);

const AccountList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [accounts, setAccounts] = useState(mockData.accounts);
  const [sortBy, setSortBy] = useState("account_status");
  const [filters, setFilters] = useState({
    status: "all",
    revenue: "all",
    date: "all",
  });

  const handleSortChange = useCallback((value) => {
    setSortBy(value);
  }, []);

  const handleSearch = (term) => {
    setSearchTerm(term.toLowerCase());
  };

  const handleFilterChange = (filterType, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterType]: value,
    }));
  };

  const filteredAccounts = useMemo(() => {
    let filtered = accounts;

    // Apply search filter
    if (searchTerm) {
      filtered = filtered.filter((account) => {
        const searchableText = JSON.stringify({
          ...account,
          account_suggested_actions: undefined,
          account_usage_sources: undefined,
        }).toLowerCase();
        return searchableText.includes(searchTerm.toLowerCase());
      });
    }

    // Apply status filter
    if (filters.status !== "all") {
      filtered = filtered.filter(
        (account) => account.account_status === filters.status
      );
    }

    // Apply revenue filter
    if (filters.revenue !== "all") {
      const sorted = [...filtered].sort(
        (a, b) => b.account_value - a.account_value
      );
      const totalAccounts = sorted.length;
      let cutoff;

      switch (filters.revenue) {
        case "top-5":
          cutoff = Math.ceil(totalAccounts * 0.05);
          break;
        case "top-10":
          cutoff = Math.ceil(totalAccounts * 0.1);
          break;
        case "top-25":
          cutoff = Math.ceil(totalAccounts * 0.25);
          break;
        default:
          cutoff = totalAccounts;
      }

      filtered = sorted.slice(0, cutoff);
    }

    // Apply date filter
    if (filters.date !== "all") {
      const daysAgo = parseInt(filters.date);
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - daysAgo);

      filtered = filtered.filter((account) => {
        const dealClosedDate = account.account_usage?.deal_closed;
        if (dealClosedDate) {
          return new Date(dealClosedDate) >= cutoffDate;
        }
        return true;
      });
    }

    return filtered;
  }, [accounts, searchTerm, filters]);

  return (
    <Flex className="account-list-container" direction="column">
      <Toolbar
        onSearch={handleSearch}
        onSortChange={handleSortChange}
        sortBy={sortBy}
        filters={filters}
        onFilterChange={handleFilterChange}
      />
      <ScrollArea
        scrollbars="vertical"
        type="scroll"
        style={{ height: "calc(100vh - 80px)" }}
      >
        <Flex
          direction="column"
          gap="5"
          style={{
            minWidth: 860,
            margin: "0 auto",
            width: "100%",
          }}
        >
          <Flex direction="column" gap="4">
            {filteredAccounts.length > 0 ? (
              <Flex direction="column">
                <RequiresAttention accounts={filteredAccounts} />
                <AccountsTable
                  accounts={filteredAccounts}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </Flex>
            ) : (
              <EmptyState
                message={
                  <>
                    No matching accounts found. <br /> Try adjusting your
                    search.
                  </>
                }
                icon={SearchX}
              />
            )}
          </Flex>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

export default AccountList;

export const mockData = {
  accounts: [
    {
      account_id: 1,
      account_name: "Springdale Custom Builders",
      account_status: "high-risk",
      account_value: 12000,
      monthly_value: "$12k",
      account_usage: {
        account_users_count: 4,
        account_products_used: 3,
        deal_closed: new Date(Date.now() - 28 * 24 * 60 * 60 * 1000),
        account_usage_summary: [
          "Accounting team stuck on QuickBooks sync setup with 5 failed attempts this week",
          "Multiple failed attempts to import vendor list from legacy system",
        ],
      },
    },
    {
      account_id: 2,
      account_name: "TIMBERIDGE Homes",
      account_status: "high-risk",
      account_value: 9000,
      monthly_value: "$9k",
      account_usage: {
        account_users_count: 2,
        account_products_used: 1,
        deal_closed: new Date(Date.now() - 35 * 24 * 60 * 60 * 1000),
        account_usage_summary: [
          "Economic buyer spent 30 minutes reviewing cancellation policy",
          "Accounting team hasn't logged in since kickoff meeting",
        ],
      },
    },
    {
      account_id: 3,
      account_name: "DMM Construction",
      account_status: "high-risk",
      account_value: 9000,
      monthly_value: "$9k",
      account_stakeholders: [
        {
          user_name: "Alex Kim",
          user_avatar: "https://i.pravatar.cc/150?img=34",
          user_role: "Cheif financial officer",
          user_email: "alex@jacksonbuilt.com",
          is_buyer: true,
        },
        {
          user_name: "Cal Atwood",
          user_avatar: "https://i.pravatar.cc/150?img=11",
          user_role: "Finance manager",
          user_email: "cal@jacksonbuilt.com",
          is_buyer: false,
        },
      ],
      account_usage: {
        account_users_count: 3,
        account_products_used: 1,
        deal_closed: new Date(Date.now() - 28 * 24 * 60 * 60 * 1000),
        account_usage_summary: [
          "No progress on setting up automated payment workflows",
          "Finance team exported vendor data back to previous system",
        ],
        account_usage_pros: `
- Cal Atwood (Finance manager) attended the first webinar 4 weeks ago
- Successfully uploaded a bill shortly after first webinar
- Team has since captured 3 receipts and two members login on a weekly basis
        `,
        account_usage_cons: `
- Finance team's budget template imports are consistently failing, with 4 failed attempts in the past week
- No successful bill uploads since the initial training webinar 4 weeks ago
        `,
        active_users: {
          daily: 0,
          weekly: 2,
          monthly: 3,
        },
        account_usage_sources: {},
        account_usage_details: {
          last_synced: "32m ago",
          events_analyzed: 32184,
          account_stage: {
            stage_name: "Onboarding",
            stage_metrics: [
              {
                metric_name: "Bills uploaded",
                metric_value: 1,
                metric_total: 11,
              },
              {
                metric_name: "Receipts captured",
                metric_value: 3,
                metric_total: 21,
              },
              {
                metric_name: "Payments sent",
                metric_value: 0,
                metric_total: 6,
              },
              {
                metric_name: "Invoices generated",
                metric_value: 0,
                metric_total: 6,
              },
            ],
          },
        },
      },
      account_suggested_actions: [
        {
          action_title: "Send email offering help with failing imports",
          action_type: "email",
          action_content: {
            email_subject: "Help with budget template imports",
            email_to: [
              {
                name: "Cal Atwood",
                email: "cal@jacksonbuilt.com",
              },
            ],
            email_cc: [],
            email_body: `Hi Cal!

I noticed your team has been working on import budget templates, and I wanted to share some resources that might help:

- [Common budget template troubleshooting steps]()
- [Sample implementation project]()

If you want to walk through your specific setup, you can book directly on my calendar here: [https://calendly.com/jake-technical-specialist]()

Best regards,
Jake`,
          },
        },
        {
          action_title:
            "Send email with personalized tutorial for bill uploads",
          action_type: "email",
          action_content: {
            email_subject: "Bill upload issues? Try this tutorial",
            email_to: [
              {
                name: "Cal Atwood",
                email: "cal@jacksonbuilt.com",
              },
            ],
            email_cc: [],
            email_body: `Hey Cal - noticed you and the team ran into issues uploading billing invoices.

We’ll actually cover this in our webinar next week (sign up here), but here’s my calendar link if you wanted to talk before then: [https://calendly.com/jake-tech-specialist]()

Best,
Jake`,
          },
        },
      ],
    },
    {
      account_id: 4,
      account_name: "Schwartz Custom Homes",
      account_status: "at-risk",
      account_value: 12000,
      monthly_value: "$1k/mo",
      account_usage: {
        account_users_count: 3,
        account_products_used: 1,
        deal_closed: new Date(Date.now() - 28 * 24 * 60 * 60 * 1000),
        account_usage_summary: [
          "No progress on setting up automated payment workflows",
          "Finance team exported vendor data back to previous system",
        ],
        account_usage_pros: `
- Cal Atwood (Finance manager) attended the first webinar 4 weeks ago
- Successfully uploaded a bill shortly after first webinar
- Team has since captured 3 receipts and two members login on a weekly basis
        `,
        account_usage_cons: `
- Finance team's budget template imports are consistently failing, with 4 failed attempts in the past week
- No successful bill uploads since the initial training webinar 4 weeks ago
        `,
        account_usage_sources: {},
        account_usage_details: {
          last_synced: "32m ago",
          events_analyzed: 32184,
        },
      },
    },
    {
      account_id: 5,
      account_name: "Resonate Custom Builds",
      account_status: "at-risk",
      account_value: 9000,
      monthly_value: "$750/mo",
      account_usage: {
        account_users_count: 5,
        account_products_used: 2,
        deal_closed: new Date(Date.now() - 35 * 24 * 60 * 60 * 1000),
        account_usage_summary: [
          "Draw management setup abandoned halfway through",
          "Accounts team struggling with chart of accounts migration after 4 attempts",
        ],
      },
    },
    {
      account_id: 6,
      account_name: "JacksonBuilt",
      account_status: "at-risk",
      account_value: 7000,
      monthly_value: "$570/mo",
      account_usage: {
        account_users_count: 3,
        account_products_used: 1,
        deal_closed: new Date(Date.now() - 28 * 24 * 60 * 60 * 1000),
        account_usage_summary: [
          "No progress on setting up automated payment workflows",
          "Finance team exported vendor data back to previous system",
        ],
        account_usage_pros: `
        - Cal Atwood (Finance manager) attended the first webinar 4 weeks ago
        - Successfully uploaded a bill shortly after first webinar
        - Team has since captured 3 receipts and two members login on a weekly basis
        `,
        account_usage_cons: `
        - Finance team's budget template imports are consistently failing, with 4 failed attempts in the past week
        - No successful bill uploads since the initial training webinar 4 weeks ago
        `,
        account_usage_sources: {},
        account_usage_details: {
          last_synced: "32m ago",
          events_analyzed: 32184,
        },
      },
    },
    {
      account_id: 7,
      account_name: "King Built",
      account_status: "at-risk",
      account_value: 7000,
      monthly_value: "$570/mo",
      account_usage: {
        account_users_count: 2,
        account_products_used: 1,
        deal_closed: new Date(Date.now() - 42 * 24 * 60 * 60 * 1000),
        account_usage_summary: [
          "No activity in system since last webinar",
          "Support escalation about automated bill coding accuracy",
        ],
      },
    },
    {
      account_id: 8,
      account_name: "Joseph: Design + Build",
      account_status: "on-track",
      account_value: 12000,
      monthly_value: "$1k/mo",
      account_usage: {
        account_users_count: 4,
        account_products_used: 2,
        deal_closed: new Date(Date.now() - 28 * 24 * 60 * 60 * 1000),
      },
    },
    {
      account_id: 9,
      account_name: "MLD: Custom Homes",
      account_status: "on-track",
      account_value: 7000,
      monthly_value: "$350/mo",
      account_usage: {
        account_users_count: 6,
        account_products_used: 2,
        deal_closed: new Date(Date.now() - 35 * 24 * 60 * 60 * 1000),
      },
    },
  ],
};

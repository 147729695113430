import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { useAuth } from "./AuthContext";
import { BASE_URL } from "../App";

const OrganizationContext = createContext();

export const useOrganization = () => useContext(OrganizationContext);

export const OrganizationProvider = ({ children }) => {
  const { user } = useAuth();
  const [touchpointLocations, setTouchpointLocations] = useState([]);

  const createTouchpointLocation = async (
    name,
    type,
    description,
    fields,
    url
  ) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/organizations/${user.organization_id}/in-app-touchpoint-locations`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          location_name: name,
          location_type: type,
          location_description: description,
          location_fields: fields,
          url,
        }),
        credentials: "include",
      }
    );

    const data = await response.json();

    return data;
  };

  const getTouchpointLocations = useCallback(async () => {
    const response = await fetch(
      `${BASE_URL}/api/v1/organizations/${user.organization_id}/in-app-touchpoint-locations`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    const data = await response.json();

    setTouchpointLocations(data.touchpoint_locations);
  }, [user.organization_id]);

  useEffect(() => {
    getTouchpointLocations();
  }, [getTouchpointLocations]);

  return (
    <OrganizationContext.Provider
      value={{
        getTouchpointLocations,
        createTouchpointLocation,
        touchpointLocations,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

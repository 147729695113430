import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Text,
  Flex,
  TextField,
  Button,
  Badge,
  ScrollArea,
  Link,
} from "@radix-ui/themes";
import { gray, grayA } from "@radix-ui/colors";
import {
  ChevronDown,
  Calendar,
  Building,
  ArrowDownWideNarrow,
  Route,
  SearchX,
  MessageSquareDot,
  Activity,
  MessageSquareText,
  ActivityIcon,
  Eye,
  MessageSquareX,
  FilterIcon,
  MapPin,
} from "lucide-react";
import { MagnifyingGlassIcon, PlusIcon } from "@radix-ui/react-icons";
import { useTouchpoint } from "./context/TouchpointContext";
import { pluralize, toPercent } from "./utils";
import Select from "./Select";

// TODO: create shared components for Toolbar, Search, Filter, FilterBar, EmptyState
const Toolbar = ({ onSearch }) => {
  const navigate = useNavigate();

  return (
    <Flex
      direction="column"
      justify="between"
      align="center"
      style={{ borderBottom: `1px solid ${grayA.grayA4}` }}
    >
      <Flex
        align="center"
        justify="between"
        px="5"
        style={{
          width: "100%",
          maxWidth: 1024,
          height: 60,
        }}
      >
        <Flex align="center" gap="3">
          <MessageSquareDot size={20} strokeWidth={2} color={grayA.grayA8} />
          <Text size="5" style={{ fontWeight: 600 }}>
            Touchpoints
          </Text>
          <Button
            variant="soft"
            size="1"
            style={{ marginLeft: 8 }}
            onClick={() => navigate("/touchpoints/create")}
          >
            <PlusIcon size={14} />
            Create
          </Button>
        </Flex>
        <Search onSearch={onSearch} />
      </Flex>
    </Flex>
  );
};

const Search = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    onSearch(term);
  };

  return (
    <TextField.Root
      size="2"
      placeholder="Search..."
      radius="large"
      style={{ width: "240px" }}
      value={searchTerm}
      onChange={handleSearch}
    >
      <TextField.Slot>
        <MagnifyingGlassIcon height="16" width="16" />
      </TextField.Slot>
    </TextField.Root>
  );
};

const EmptyState = ({ message, icon: Icon }) => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    py="9"
    gap="4"
    style={{ border: `1px solid ${grayA.grayA4}`, borderRadius: 16 }}
  >
    <Icon size={40} strokeWidth={1.5} color={gray.gray6} />
    <Text size="2" color="gray" align="center">
      {message}
    </Text>
  </Flex>
);

const Filter = ({ icon: Icon, value, options, onChange }) => {
  const selectedOption =
    options.find((option) => option.value === value) || options[0];

  return (
    <Flex
      display={{ initial: "none", sm: "flex" }}
      style={{ userSelect: "none" }}
    >
      <Select.Root value={value} onValueChange={onChange}>
        <Select.Trigger variant="ghost" color="gray">
          <Flex align="center" gap="2">
            <Icon size={14} color={grayA.grayA9} />
            <Text size="1" weight="medium" style={{ color: grayA.grayA10 }}>
              {selectedOption.label}
            </Text>
            <ChevronDown size={14} color={gray.gray9} />
          </Flex>
        </Select.Trigger>
        <Select.Content position="popper">
          {options.map((option) => (
            <Select.Item key={option.value} value={option.value}>
              {option.label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    </Flex>
  );
};

const FilterBar = ({ sortBy, onSortChange }) => (
  <Flex
    justify="between"
    align="center"
    px="5"
    py="3"
    style={{
      backgroundColor: "#F7F7F9",
      borderRadius: 12,
    }}
  >
    <Flex gap="5">
      <Filter
        key="status"
        icon={ActivityIcon}
        value="all"
        options={[
          { value: "all", label: "All touchpoints" },
          { value: "active", label: "Active" },
          { value: "inactive", label: "Inactive" },
          { value: "archived", label: "Archived" },
        ]}
      />
      <Filter
        key="workflow"
        icon={Route}
        value="all"
        options={[
          { value: "all", label: "All workflows" },
          { value: "team-collaboration", label: "Team collaboration" },
          { value: "theme-customization", label: "Theme customization" },
          { value: "watermark", label: "Watermark" },
          { value: "screenshot-enhancement", label: "Screenshot enhancement" },
        ]}
      />
      <Filter
        key="customer"
        icon={Building}
        value="all"
        options={[
          { value: "all", label: "All customers" },
          { value: "ibm", label: "IBM" },
          { value: "microsoft", label: "Microsoft" },
          { value: "google", label: "Google" },
          { value: "amazon", label: "Amazon" },
          { value: "facebook", label: "Facebook" },
          { value: "twitter", label: "Twitter" },
          { value: "linkedin", label: "LinkedIn" },
          { value: "instagram", label: "Instagram" },
          { value: "snapchat", label: "Snapchat" },
          { value: "tiktok", label: "TikTok" },
        ]}
      />
      <Filter
        key="date"
        icon={Calendar}
        value="30"
        options={[
          { value: "7", label: "Last 7 days" },
          { value: "30", label: "Last 30 days" },
          { value: "90", label: "Last 90 days" },
        ]}
      />
    </Flex>
    <Flex align="center" gap="2">
      <Filter
        icon={ArrowDownWideNarrow}
        value={sortBy}
        options={[{ value: "dateCreated", label: "Recently created" }]}
        onChange={onSortChange}
      />
    </Flex>
  </Flex>
);

const PerformanceStat = ({ icon: Icon, title, value }) => (
  <Flex align="center" justify="between">
    <Flex align="center" gap="3">
      <Icon size={16} strokeWidth={1.75} color={grayA.grayA8} />
      <Text size="1" style={{ color: grayA.grayA11 }}>
        {title}
      </Text>
    </Flex>
    <Flex
      align="center"
      justify="center"
      px="2"
      py="2px"
      style={{ backgroundColor: "white", borderRadius: 999 }}
    >
      <Text size="1" style={{ color: grayA.grayA11 }}>
        {value}
      </Text>
    </Flex>
  </Flex>
);

const PerformanceBreakdown = ({ performance }) => {
  const getPerformanceRating = ({
    total_impressions,
    conversion_rate,
    snap_dismissal_rate,
  }) => {
    if (total_impressions === 0) return "No data";
    if (conversion_rate > 0.1 && snap_dismissal_rate < 0.05) return "Excellent";
    if (conversion_rate > 0.05 && snap_dismissal_rate < 0.1) return "Good";
    if (conversion_rate > 0.02 && snap_dismissal_rate < 0.15) return "Fair";
    return "Poor";
  };

  const performanceRating = getPerformanceRating(performance);
  const ratingColor = {
    "No data": "gray",
    Excellent: "green",
    Good: "blue",
    Fair: "yellow",
    Poor: "red",
  };

  return (
    <Flex
      direction="column"
      style={{ backgroundColor: gray.gray2, borderRadius: 10, width: 320 }}
    >
      <Flex
        align="center"
        justify="between"
        px="4"
        py="3"
        style={{ borderBottom: `1px solid ${grayA.grayA4}` }}
      >
        <Flex align="center" gap="3">
          <Activity size={14} color={grayA.grayA8} />
          <Text
            size="1"
            weight="medium"
            wrap="nowrap"
            style={{ color: grayA.grayA11 }}
          >
            Performance
          </Text>
          <Badge
            variant="outline"
            color={ratingColor[performanceRating]}
            style={{ opacity: 0.8, fontSize: 10, lineHeight: "12px" }}
          >
            {performanceRating}
          </Badge>
        </Flex>
        {performanceRating !== "No data" && (
          <Link size="1" weight="medium" style={{ cursor: "pointer" }}>
            Learn why
          </Link>
        )}
      </Flex>
      {performanceRating === "No data" ? (
        <Flex align="center" justify="center" p="4">
          <Text size="1" style={{ color: grayA.grayA10 }}>
            Touchpoint active, collecting performance data...
          </Text>
        </Flex>
      ) : (
        <Flex direction="column" gap="4" p="4">
          <PerformanceStat
            icon={Eye}
            title="Total impressions"
            value={performance.total_impressions}
          />
          <PerformanceStat
            icon={FilterIcon}
            title="Conversion rate"
            value={toPercent(performance.conversion_rate, 1)}
          />
          <PerformanceStat
            icon={MessageSquareX}
            title="Snap dismissal rate"
            value={toPercent(performance.snap_dismissal_rate, 1)}
          />
        </Flex>
      )}
    </Flex>
  );
};

const Attribute = ({ icon: Icon, text }) => (
  <Flex align="center" gap="5px" style={{ opacity: 0.8 }}>
    <Icon size={14} color={grayA.grayA10} />
    <Text size="1" style={{ color: grayA.grayA10 }}>
      {text}
    </Text>
  </Flex>
);

const TouchpointCard = ({ touchpoint, onClick }) => {
  const {
    touchpoint_name,
    touchpoint_description,
    touchpoint_location_name,
    variant_count,
  } = touchpoint;

  return (
    <Flex
      justify="between"
      style={{
        borderRadius: 16,
        border: `1px solid ${grayA.grayA4}`,
        boxShadow:
          "0px 1px 3px 0px rgba(0, 0, 0, 0.01), 0px 2px 1px -1px rgba(0, 0, 0, 0.01), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.01)",
      }}
    >
      <Flex
        direction="column"
        justify="between"
        px="5"
        py="20px"
        style={{ cursor: "pointer", flex: 1 }}
        onClick={onClick}
      >
        <Badge variant="outline" color="green" style={{ width: "fit-content" }}>
          <span style={{ fontSize: 14, margin: "0 -1px 2px 1px" }}>•</span>
          Active
        </Badge>

        <Flex direction="column" gap="2" mt="4">
          <Text size="3" weight="medium">
            {touchpoint_name}
          </Text>
          {touchpoint_description && (
            <Text size="2" style={{ color: grayA.grayA10 }}>
              {touchpoint_description}
            </Text>
          )}
        </Flex>

        <Flex gap="4" align="center" mt="4">
          {/* <Attribute icon={Calendar} text={toTimeAgo(created_at)} /> */}
          <Attribute
            icon={MessageSquareText}
            text={`${variant_count} ${pluralize("variant", variant_count)}`}
          />
          <Attribute icon={MapPin} text={touchpoint_location_name} />
        </Flex>
      </Flex>
      <Flex p="20px" pl="0">
        <PerformanceBreakdown performance={touchpoint.performance} />
      </Flex>
    </Flex>
  );
};

const TouchpointList = () => {
  const navigate = useNavigate();
  const { getTouchpoints } = useTouchpoint();
  const [touchpoints, setTouchpoints] = useState([]);
  const [sortBy, setSortBy] = useState("dateCreated");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getTouchpoints().then(setTouchpoints);
  }, [getTouchpoints, setTouchpoints]);

  const handleSortChange = useCallback((value) => {
    setSortBy(value);
  }, []);

  const handleSearch = useCallback((term) => {
    setSearchTerm(term);
  }, []);

  const filteredTouchpoints = useMemo(() => {
    let filtered = touchpoints;

    if (searchTerm) {
      filtered = filtered.filter((touchpoint) =>
        Object.values(touchpoint).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // return filtered.sort((a, b) => {
    //   if (sortBy === "dateCreated") {
    //     return new Date(b.created_at) - new Date(a.created_at);
    //   }
    //   return 0;
    // });
    return filtered.reverse(); // todo: remove once we have touchpoint.created_at
  }, [touchpoints, searchTerm, sortBy]);

  return (
    <Flex direction="column">
      <Toolbar onSearch={handleSearch} />
      <ScrollArea
        scrollbars="vertical"
        type="scroll"
        style={{ height: "calc(100vh - 80px)" }}
      >
        <Flex
          direction="column"
          gap="5"
          style={{
            maxWidth: 1024,
            minWidth: 860,
            margin: "0 auto",
            padding: "24px 24px 48px",
            width: "100%",
          }}
        >
          <FilterBar sortBy={sortBy} onSortChange={handleSortChange} />

          <Flex direction="column" gap="4">
            {filteredTouchpoints.length > 0 ? (
              filteredTouchpoints.map((touchpoint) => (
                <TouchpointCard
                  key={touchpoint.touchpoint_id}
                  touchpoint={touchpoint}
                  onClick={() =>
                    navigate(`/touchpoints/${touchpoint.touchpoint_id}`)
                  }
                />
              ))
            ) : (
              <EmptyState
                message={
                  <>
                    No matching touchpoints found. <br /> Try adjusting your
                    search.
                  </>
                }
                icon={SearchX}
              />
            )}
          </Flex>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

export default TouchpointList;

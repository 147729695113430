import React, { createContext, useCallback, useContext } from "react";
import { useAuth } from "./AuthContext";
import { BASE_URL } from "../App";

const OpeningsContext = createContext();

export const useOpenings = () => useContext(OpeningsContext);

export const OpeningsProvider = ({ children }) => {
  const { user } = useAuth();

  const getOpenings = useCallback(async () => {
    // const response = await fetch(
    //   `${BASE_URL}/api/v1/organizations/${user.organization_id}/openings`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     credentials: "include",
    //   }
    // );
    // const data = await response.json();
    // return data.openings;

    return mockData.openings;
  }, [user.organization_id]);

  return (
    <OpeningsContext.Provider
      value={{
        getOpenings,
      }}
    >
      {children}
    </OpeningsContext.Provider>
  );
};

const mockData = {
  openings: [
    {
      opening_id: 3,
      opening_status: "active",
      opening_title: "Sarah revisiting pricing page for upgrade",
      created_at: new Date(Date.now() - 2 * 60 * 1000),
      opening_user: {
        user_name: "Sarah Chen",
        user_organization: {
          organization_name: "Oracle",
          organization_value: 280000,
          organization_top_tier: false,
          organization_entitlement: "under",
          organization_avatar:
            "https://cdn.iconscout.com/icon/free/png-256/free-oracle-logo-icon-download-in-svg-png-gif-file-formats--brand-development-tools-pack-logos-icons-226044.png?f=webp&w=256",
          renewal_date: "2025-02-15",
          economic_buyer: {
            name: "James Wilson",
            role: "VP of Engineering",
            avatar: "https://i.pravatar.cc/150?img=61",
          },
        },
        user_avatar: "https://i.pravatar.cc/150?img=25",
        user_email: "schen@oracle.com",
        user_role: "Product",
        user_metrics: {
          last_login: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000),
          recent_activity: [
            "Viewed pricing pages",
            "Compared enterprise plans",
            "Downloaded pricing PDF",
          ],
          average_session: [
            "Regular visits to pricing section",
            "Downloads documentation",
            "Logs in 2x/week, avg. 15m in product",
          ],
          recent_interactions: [
            { opening_title: "Pricing consultation", created_at: "8/28" },
            { opening_title: "Plan upgrade discussion", created_at: "8/15" },
          ],
        },
      },
      opening_details: {
        summary:
          "Sarah has been frequently visiting the pricing pages and comparing enterprise plans. This activity suggests she may be evaluating pricing options for a potential upgrade.",
        supporting_data: [
          "Multiple visits to pricing documentation",
          "Downloaded enterprise comparison sheet",
          "Viewed upgrade options",
        ],
        account_insights: [
          "Oracle is a $280k ARR account with 3x expansion potential",
          "They're currently using basic tier - upgrade could be $500k+ opportunity",
          "Competitor X has been actively targeting their team",
        ],
        suggested_action:
          "Schedule a pricing consultation to discuss upgrade options",
        draft_email: {
          email_to: "Sarah Chen",
          email_cc: "",
          email_subject: "Questions about our pricing plans?",
          email_body: `Hi Sarah,

I noticed you've been exploring our pricing pages recently. I wanted to reach out to see if you had any questions about our plans or if you're considering an upgrade.

I'd be happy to walk you through the different options and help you find the best fit for your needs.

Best,
Jake
`,
        },
        relevant_context: [
          "Renewal discussion starting next month",
          "Recent budget approval for platform expansion",
          "Buyer expressed interest in premium features",
        ],
        retention_risks: [
          "Only 35% of purchased seats logged in last month",
          "Documentation visits dropped 50% month-over-month",
          "No product login from economic buyer in 60 days",
        ],
        growth_opportunities: [],
      },
    },
    //     {
    //       opening_id: 2,
    //       opening_status: "active",
    //       opening_title: "Stuck on Java integration",
    //       created_at: new Date(Date.now() - 3 * 60 * 60 * 1000),
    //       opening_user: {
    //         user_name: "Steve Jobs",
    //         user_organization: {
    //           organization_name: "Apple",
    //           organization_value: 450000,
    //           organization_top_tier: false,
    //           organization_entitlement: "healthy",
    //           organization_avatar:
    //             "https://images.vexels.com/media/users/3/129234/isolated/lists/73970c892d748c7507db8e10d71535b0-apple-logo-icon.png",
    //           renewal_date: "2025-01-20",
    //           economic_buyer: {
    //             name: "Alex Kim",
    //             role: "Engineering Manager",
    //             avatar: "https://i.pravatar.cc/150?img=34",
    //           },
    //         },
    //         user_avatar:
    //           "https://www.famousbirthdays.com/faces/jobs-steven-image.jpg",
    //         user_email: "steve@apple.com",
    //         user_role: "Engineer",
    //         user_metrics: {
    //           last_login: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
    //           recent_activity: [
    //             "Repeated visits to API docs",
    //             "Monitored active collections",
    //             "Configured backups for 2 databases",
    //           ],
    //           average_session: [
    //             "Skims database charts, error logs",
    //             "Configures new database",
    //             "Logs in 4x/week, avg. 32m in product",
    //           ],
    //           recent_interactions: [
    //             { opening_title: "Feature usage check-in", created_at: "8/24" },
    //             {
    //               opening_title: "Troubleshoot network resolution",
    //               created_at: "7/4",
    //             },
    //             { opening_title: "Invalid team permissions", created_at: "5/16" },
    //             { opening_title: "Vercel integration help", created_at: "3/29" },
    //           ],
    //         },
    //       },
    //       opening_details: {
    //         summary:
    //           "Steve visited [MongoDB Java Drivers](https://www.mongodb.com/docs/languages/java) every day this week. The repeated documentation visits and configuration updates suggest they're actively blocked in setting up their Java application.",
    //         supporting_data: [
    //           "Visited [MongoDB Java Drivers](https://www.mongodb.com/docs/languages/java) for at least 40 minutes every day",
    //           "Downloading API logs from the developer console twice per day",
    //           "Multiple driver configuration changes in the last 24hrs",
    //         ],
    //         account_insights: [
    //           "Apple's renewal coming up in 90 days",
    //           "Economic buyer (Alex Kim) is the engineering manager of this user",
    //           "No contact with this economic buyer in the last 4 months",
    //         ],
    //         suggested_action:
    //           "Offer to answer questions or connect them with an expert",
    //         draft_email: {
    //           email_to: "Steve Jobs",
    //           email_cc: "Alex Kim",
    //           email_subject: "Help with Java integration?",
    //           email_body: `Hi Steve!

    // I'm on the technical account team here at MongoDB and noticed you've been diving deep into our Java documentation recently. As someone whose job is to help developers be successful with MongoDB, I'm curious about what you're working on?

    // If you're running into any specific challenges with the Java driver or have questions about best practices, I'd be happy to help or connect you with our specialists who focus on Java implementations.

    // No pressure - just want to make sure you have the support you need.

    // Best,
    // Jake
    // `,
    //         },
    //         relevant_context: [
    //           "No contact with buyer in 4 months",
    //           "Buyer is manager of this user",
    //         ],
    //         retention_risks: [
    //           "Average session duration decreased by 40%",
    //           "Key user hasn't logged in for 10 days",
    //         ],
    //         growth_opportunities: [
    //           "5 new team members accessed admin console this week",
    //         ],
    //       },
    //     },
    {
      opening_id: 4,
      opening_status: "active",
      opening_title: "Migrating data out of product",
      created_at: new Date(Date.now() - 4 * 60 * 60 * 1000),
      opening_user: {
        user_name: "Maria Rodriguez",
        user_organization: {
          organization_name: "Oracle",
          organization_value: 280000,
          organization_top_tier: false,
          organization_entitlement: "under",
          organization_avatar:
            "https://cdn.iconscout.com/icon/free/png-256/free-oracle-logo-icon-download-in-svg-png-gif-file-formats--brand-development-tools-pack-logos-icons-226044.png?f=webp&w=256",
          renewal_date: "2025-02-15",
          economic_buyer: {
            name: "James Wilson",
            role: "VP of Engineering",
            avatar: "https://i.pravatar.cc/150?img=61",
          },
        },
        user_avatar: "https://i.pravatar.cc/150?img=47",
        user_email: "mrodriguez@oracle.com",
        user_role: "Engineer",
        user_metrics: {
          last_login: new Date(),
          recent_activity: [
            "Downloaded 3 complete db exports",
            "Accessed migration docs 12 times",
            "Enabled advanced monitoring",
          ],
          average_session: [
            "Spends 45m/day in data console",
            "Regular schema validation checks",
            "Frequent access to backup configs",
            "Logs in daily, avg. 2.5h in product",
          ],
          recent_interactions: [
            { opening_title: "Performance review", created_at: "8/15" },
            { opening_title: "Schema design consultation", created_at: "8/1" },
            {
              opening_title: "Initial architecture planning",
              created_at: "7/25",
            },
          ],
        },
      },
      opening_details: {
        summary:
          "Maria has shown patterns suggesting she may be evaluating alternative platforms. Her recent activities indicate a critical opportunity to demonstrate our platform's unique value and ensure her team's needs are being fully met.",
        supporting_data: [
          "Exported over 500GB of data across multiple collections in the past week",
          "High engagement with migration-related documentation",
          "Significant increase in monitoring and performance tracking activities",
          "Recent expansion of API permissions and tooling access",
        ],
        account_insights: [
          "Oracle is our #3 target account for this quarter",
          "Risk of churn could impact $280k ARR",
          "Data migration patterns often precede platform switches",
        ],
        suggested_action:
          "Schedule technical review to demonstrate platform value",
        draft_email: {
          email_to: "Maria Rodriguez",
          email_cc: "Technical Architecture Team",
          email_subject: "Let's discuss your data architecture needs",
          email_body: `Hi Maria,

I noticed your team has been working extensively with our platform's data management features recently. Given the scale of your operations, I wanted to reach out to ensure you're getting the most value from our enterprise capabilities.

We've recently enhanced our performance optimization tools and introduced several features that could significantly improve your data operations. I'd love to schedule a brief technical review to share these updates and understand your specific requirements better.

Many of our enterprise customers have found our advanced clustering and data distribution features particularly valuable for large-scale operations. I'd be happy to connect you with our solution architects to explore how these capabilities could benefit your use case.

Best,
Jake
`,
        },
        relevant_context: [
          "Buyer mentioned performance concerns last week",
          "Team evaluating competitive solutions",
          "Critical migration project deadline approaching",
        ],
        retention_risks: [
          "Only 35% of purchased seats logged in last month",
          "Documentation visits dropped 50% month-over-month",
          "No product login from economic buyer in 60 days",
        ],
        growth_opportunities: [],
      },
    },
    {
      opening_id: 5,
      opening_status: "active",
      opening_title: "Increased documentation activity",
      created_at: new Date(Date.now() - 8 * 60 * 60 * 1000),
      opening_user: {
        user_name: "Michael Smith",
        user_organization: {
          organization_name: "Netflix",
          organization_value: 820000,
          organization_top_tier: true,
          organization_entitlement: "over",
          organization_avatar:
            "https://assets.nflxext.com/us/ffe/siteui/common/icons/nficon2016.ico",
          renewal_date: "2025-01-07",
          economic_buyer: {
            name: "Rachel Park",
            role: "Director of Engineering",
            avatar: "https://i.pravatar.cc/150?img=45",
          },
        },
        user_avatar: "https://i.pravatar.cc/150?img=51",
        user_email: "msmith@netflix.com",
        user_role: "DevOps",
        user_metrics: {
          last_login: new Date(Date.now() - 12 * 60 * 60 * 1000),
          recent_activity: [
            "Multiple visits to best practices docs",
            "Downloaded implementation guides",
            "Accessed API documentation",
          ],
          average_session: [
            "Daily documentation visits",
            "Regular API reference checks",
            "Logs in 5x/day, avg. 45m in product",
          ],
          recent_interactions: [
            { opening_title: "Implementation review", created_at: "8/29" },
            { opening_title: "Architecture discussion", created_at: "8/20" },
          ],
        },
      },
      opening_details: {
        summary:
          "Michael's team has shown increased activity in our documentation, particularly around implementation patterns and best practices. This could indicate they're working on a new feature or implementation.",
        supporting_data: [
          "Frequent visits to implementation guides",
          "Multiple documentation downloads",
          "High engagement with API reference",
        ],
        account_insights: [
          "$820k ARR at stake - our largest media customer",
          "Current usage indicates 2x expansion opportunity",
          "Strategic reference for media industry vertical",
        ],
        suggested_action:
          "Offer implementation guidance and best practices review",
        draft_email: {
          email_to: "Michael Chang",
          email_subject: "Help with your implementation?",
          email_body: `Hi Michael,

I noticed you've been exploring our documentation extensively recently. I wanted to reach out to see if you're working on a new implementation or have any questions about best practices.

Our team has extensive experience with similar implementations. I'd be happy to set up a call to discuss your use case and share relevant patterns we've seen work well.

Best,
Jake
`,
        },
        relevant_context: [
          "Expansion discussion next quarter",
          "User reports directly to buyer",
          "Strategic reference account for media vertical",
        ],
        retention_risks: ["Decreased login frequency from admin users"],
        growth_opportunities: [
          "90% of available seats actively used last month",
          "Consistent usage of analytics dashboard by 3 teams",
          "Multiple teams accessing enterprise documentation",
        ],
      },
    },
    {
      opening_id: 6,
      opening_status: "active",
      opening_title: "Exploring sharding options",
      created_at: new Date(Date.now() - 16 * 60 * 60 * 1000),
      opening_user: {
        user_name: "Emma Wilson",
        user_organization: {
          organization_name: "Spotify",
          organization_value: 650000,
          organization_top_tier: false,
          organization_entitlement: "healthy",
          organization_avatar: "https://www.spotify.com/favicon.ico",
          renewal_date: "2024-12-22",
          economic_buyer: {
            name: "Mike Thompson",
            role: "Head of Infrastructure",
            avatar: "https://i.pravatar.cc/150?img=17",
          },
        },
        user_avatar: "https://i.pravatar.cc/150?img=32",
        user_email: "ewilson@spotify.com",
        user_role: "Architecture",
        user_metrics: {
          last_login: new Date(Date.now() - 4 * 60 * 60 * 1000),
          recent_activity: [
            "Reviewed sharding documentation",
            "Accessed cluster scaling guides",
            "Downloaded architecture whitepapers",
          ],
          average_session: [
            "Regular architecture reviews",
            "Cluster monitoring",
            "Logs in 3x/week, avg. 1h in product",
          ],
          recent_interactions: [
            { opening_title: "Architecture consultation", created_at: "8/25" },
            {
              opening_title: "Scaling strategy discussion",
              created_at: "8/10",
            },
          ],
        },
      },
      opening_details: {
        summary:
          "Emma is researching sharding and distributed architecture options, indicating potential scale planning for their growing deployment.",
        supporting_data: [
          "Multiple visits to sharding documentation",
          "Downloaded distributed systems whitepaper",
          "Reviewing cluster configuration options",
        ],
        account_insights: [
          "$650k ARR with identified $1.2M expansion opportunity",
          "Sharding interest indicates major growth - perfect timing",
          "Technical blocker could delay expansion by 6+ months",
        ],
        suggested_action:
          "Share scaling best practices and architecture guidance",
        draft_email: {
          email_to: "Emma Wilson",
          email_subject: "Planning for scale? Let's chat",
          email_body: `Hi Emma,

I noticed you've been exploring our sharding and distributed architecture documentation. Are you planning for future scale?

I'd love to schedule some time to discuss your scaling strategy and share some best practices we've learned from similar deployments.

Best,
Jake
`,
        },
        relevant_context: [
          "Infrastructure review meeting next week",
          "Buyer concerned about scaling costs",
          "Competitive POC in progress",
        ],
        retention_risks: ["Dashboard engagement declined 20% this month"],
        growth_opportunities: [
          "5 users viewed enterprise features this week",
          "Increased usage of reporting features",
        ],
      },
    },
    {
      opening_id: 7,
      opening_status: "active",
      opening_title: "Security compliance review",
      created_at: new Date(Date.now() - 24 * 60 * 60 * 1000),
      opening_user: {
        user_name: "David Kim",
        user_organization: {
          organization_name: "Airbnb",
          organization_value: 720000,
          organization_top_tier: true,
          organization_entitlement: "over",
          organization_avatar:
            "https://a0.muscache.com/airbnb/static/icons/android-icon-192x192-c0465f9f0380893768972a31a614b670.png",
          renewal_date: "2025-01-31",
          economic_buyer: {
            name: "Sarah Martinez",
            role: "Engineering director",
            avatar: "https://i.pravatar.cc/150?img=44",
          },
        },
        user_avatar: "https://i.pravatar.cc/150?img=68",
        user_email: "dkim@airbnb.com",
        user_role: "Security",
        user_metrics: {
          last_login: new Date(Date.now() - 1 * 60 * 60 * 1000),
          recent_activity: [
            "Accessed security documentation",
            "Downloaded compliance reports",
            "Reviewed audit logs",
          ],
          average_session: [
            "Daily security reviews",
            "Regular compliance checks",
            "Logs in daily, avg. 30m in product",
          ],
          recent_interactions: [
            { opening_title: "Security assessment", created_at: "8/28" },
            { opening_title: "Compliance review", created_at: "8/15" },
          ],
        },
      },
      opening_details: {
        summary:
          "David is conducting a comprehensive security review, focusing on compliance documentation and security features.",
        supporting_data: [
          "Reviewing security certifications",
          "Accessing compliance documentation",
          "Analyzing audit capabilities",
        ],
        account_insights: [
          "$720k ARR at risk if security requirements aren't met",
          "Security approval unlocks $500k expansion opportunity",
          "Competitor Y recently got SOC 2 certification",
        ],
        suggested_action:
          "Provide additional security documentation and compliance details",
        draft_email: {
          email_to: "David Kim",
          email_subject: "Supporting your security review",
          email_body: `Hi David,

I noticed you've been reviewing our security documentation and compliance materials. I wanted to reach out to see if you need any additional information or clarification.

We have extensive security resources and can arrange a detailed review with our security team if helpful.

Best,
Jake
`,
        },
        relevant_context: [
          "Security audit due next month",
          "Buyer prioritizing infrastructure stability",
          "Recent outage escalated to executive team",
        ],
        retention_risks: [],
        growth_opportunities: [
          "High engagement with security documentation",
          "8 teams accessing advanced feature guides",
          "Increased usage of team management features",
        ],
      },
    },
  ],
};

import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Text,
  Flex,
  ScrollArea,
  IconButton,
  Avatar,
  Button,
  Badge,
  Popover,
  Separator,
} from "@radix-ui/themes";
import {
  blue,
  cyan,
  gray,
  grayA,
  green,
  indigo,
  pink,
  red,
  violet,
} from "@radix-ui/colors";
import {
  Send,
  ChevronDown,
  Mail,
  ExternalLink,
  ClipboardCheck,
  ClipboardCopy,
  RefreshCw,
  Blocks,
  EllipsisVertical,
  Sparkles,
  Bolt,
  Zap,
  Users,
  PiggyBank,
  CalendarRange,
  CalendarFold,
  Shapes,
  CalendarDays,
  Unplug,
  HistoryIcon,
} from "lucide-react";
import { mockData, StatusBadge } from "./AccountList";
import AccountIcon from "./assets/account.svg";
import EmailIcon from "./assets/email.svg";
import EmailSentIcon from "./assets/email-sent.svg";
import Markdown from "react-markdown";
import { pluralize, toTimeAgo } from "./utils";
import "./AccountDetail.css";

const Toolbar = () => {
  return (
    <Flex
      direction="column"
      justify="between"
      align="center"
      style={{ borderBottom: `1px solid ${grayA.grayA4}` }}
    >
      <Flex
        align="center"
        justify="between"
        px="5"
        style={{
          width: "100%",
          minWidth: 860,
          maxWidth: 1024, // todo: make responsive
          height: 53,
        }}
      >
        <Flex align="center" gap="2">
          <Flex p="1" style={{ backgroundColor: pink.pink3, borderRadius: 6 }}>
            <img src={AccountIcon} alt="Account" />
          </Flex>
          <Text size="2" weight="medium" style={{ color: grayA.grayA10 }}>
            Account
          </Text>
        </Flex>
        <IconButton
          variant="outline"
          color="gray"
          radius="large"
          size="1"
          style={{
            boxShadow: "none",
            border: "1px solid var(--gray-a4)",
            borderRadius: 8,
          }}
        >
          <EllipsisVertical size={16} color={grayA.grayA9} />
        </IconButton>
      </Flex>
    </Flex>
  );
};

// todo: move to shared component
const Attribute = ({ icon: Icon, text }) => (
  <Flex align="center" gap="5px">
    <Icon size={14} color={gray.gray8} />
    <Text size="1" style={{ color: gray.gray10 }}>
      {text}
    </Text>
  </Flex>
);

const AccountStage = ({ account }) => {
  const StageMetricBar = ({
    metric_name,
    metric_value,
    metric_total,
    isLast,
  }) => {
    return (
      <Flex
        px="3"
        py="2"
        style={{
          position: "relative",
          borderBottom: isLast ? "none" : `1px solid ${gray.gray3}`,
        }}
      >
        <Flex
          justify="between"
          align="center"
          width="100%"
          style={{ zIndex: 1 }}
        >
          <Text size="1" weight="medium" style={{ color: grayA.grayA11 }}>
            {metric_name}
          </Text>
          <Text size="1" weight="medium" style={{ color: grayA.grayA9 }}>
            {metric_value} / {metric_total}
          </Text>
        </Flex>
        <Flex
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: `${(metric_value / metric_total) * 100}%`,
            backgroundColor: violet.violet5,
          }}
        />
      </Flex>
    );
  };

  return (
    <Flex direction="column" gap="2">
      <Flex align="center" justify="between">
        <Text size="1" weight="medium" style={{ color: grayA.grayA10 }}>
          ACCOUNT STAGE
        </Text>
        <Text size="1" style={{ color: indigo.indigo11, fontWeight: 500 }}>
          {account.account_usage.account_usage_details.account_stage.stage_name}
        </Text>
      </Flex>
      <Flex
        direction="column"
        style={{
          backgroundColor: gray.gray2,
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        {account.account_usage.account_usage_details.account_stage.stage_metrics.map(
          (metric, index, array) => (
            <StageMetricBar
              key={metric.metric_name}
              {...metric}
              isLast={index === array.length - 1}
            />
          )
        )}
      </Flex>
    </Flex>
  );
};

const ActiveUsers = ({ account }) => {
  const UserCount = ({ label, icon: Icon, value }) => (
    <Flex direction="column" width="100%">
      <Flex align="center" justify="between">
        <Text size="2" weight="medium" style={{ color: gray.gray11 }}>
          {value}
        </Text>
        <Icon size={14} color={gray.gray8} />
      </Flex>
      <Text
        weight="medium"
        style={{ color: grayA.grayA11, fontSize: 10, marginTop: -1 }}
      >
        {label}
      </Text>
    </Flex>
  );

  return (
    <Flex direction="column" gap="2">
      <Text size="1" weight="medium" style={{ color: grayA.grayA10 }}>
        ACTIVE USERS
      </Text>
      <Flex
        direction="row"
        align="center"
        gap="10px"
        py="2"
        px="3"
        style={{
          backgroundColor: gray.gray2,
          borderRadius: 8,
        }}
      >
        <UserCount
          label="Daily"
          icon={CalendarDays}
          value={account.account_usage.active_users.daily}
        />
        <Separator orientation="vertical" size="2" />
        <UserCount
          label="Weekly"
          icon={CalendarRange}
          value={account.account_usage.active_users.weekly}
        />
        <Separator orientation="vertical" size="2" />
        <UserCount
          label="Monthly"
          icon={CalendarFold}
          value={account.account_usage.active_users.monthly}
        />
      </Flex>
    </Flex>
  );
};

const PersonInfo = ({
  user_name,
  user_avatar,
  user_role,
  user_email,
  is_buyer,
}) => {
  return (
    <Flex align="center" gap="10px">
      <Avatar
        radius="full"
        src={user_avatar}
        style={{
          border: `1px solid ${grayA.grayA4}`,
          height: 28,
          width: 28,
        }}
      />
      <Flex direction="column" style={{ flex: 1 }}>
        <Flex align="center" gap="1">
          <Text size="1" weight="medium" style={{ color: grayA.grayA11 }}>
            {user_name}
          </Text>
          {is_buyer && (
            <Badge
              size="1"
              color="gray"
              variant="outline"
              style={{
                opacity: 0.7,
                fontSize: 10,
                padding: "0px 4px",
                lineHeight: 1.35,
                fontWeight: 400,
              }}
            >
              Buyer
            </Badge>
          )}
        </Flex>
        <Text
          style={{
            color: grayA.grayA9,
            fontSize: 10,
            lineHeight: 1.2,
            marginBottom: 1,
          }}
        >
          {user_role}
        </Text>
      </Flex>
      <IconButton variant="ghost" size="1" radius="large" color="gray">
        <EllipsisVertical size={14} color={grayA.grayA9} />
      </IconButton>
    </Flex>
  );
};

const DetailsPane = ({ account }) => {
  return (
    <Flex
      direction="column"
      gap="5"
      px="4"
      pt="3"
      pb="5"
      style={{
        background: "white",
        borderRadius: 16,
        border: `1px solid ${gray.gray4}`,
        minWidth: 272,
        maxWidth: 280,
        height: "fit-content",
      }}
    >
      <Flex direction="column" gap="4">
        <Flex justify="between" align="center">
          <Text size="2" weight="medium">
            Usage
          </Text>
          <Flex align="center" gap="2">
            <Attribute
              icon={Users}
              text={`${account.account_usage.account_users_count} ${pluralize(
                "user",
                account.account_usage.account_users_count
              )}`}
            />
            <Attribute
              icon={Blocks}
              text={`${account.account_usage.account_products_used} ${pluralize(
                "product",
                account.account_usage.account_products_used
              )}`}
            />
          </Flex>
        </Flex>
        <AccountStage account={account} />
        <ActiveUsers account={account} />
      </Flex>
      <Flex direction="column" gap="2">
        <Text size="2" weight="medium">
          Upcoming events
        </Text>
        <Flex
          align="center"
          gap="3"
          px="3"
          py="2"
          style={{
            cursor: "pointer",
            borderRadius: 10,
            border: `1px solid ${gray.gray3}`,
          }}
        >
          <Unplug size={16} strokeWidth={1.75} color={gray.gray8} />
          <Text size="1" weight="medium" style={{ color: grayA.grayA11 }}>
            Link your calendar
          </Text>
        </Flex>
      </Flex>
      <Flex direction="column" gap="3">
        <Text size="2" weight="medium">
          Key stakeholders
        </Text>
        <Flex direction="column" gap="3">
          {account.account_stakeholders.map((stakeholder) => (
            <PersonInfo key={stakeholder.user_name} {...stakeholder} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

const Header = ({ account }) => {
  return (
    <Flex align="center" gap="5" mt="5" mb="6">
      <Avatar
        size="4"
        //   src={account.opening_user.user_organization.organization_avatar}
        style={{
          padding: 12,
          backgroundColor: "white",
          border: `1px solid ${grayA.grayA4}`,
          borderRadius: 16,
          height: 88,
          width: 88,
        }}
      />
      <Flex direction="column" gap="2">
        <Text size="8" weight="medium">
          {account.account_name}
        </Text>
        <Flex align="center" gap="3" mb="6px">
          <StatusBadge status={account.account_status} />
          <Flex align="center" gap="2">
            <PiggyBank size={14} color={gray.gray9} />
            <Text size="1" style={{ color: gray.gray10 }}>
              ${account.account_value.toLocaleString()}
              <span style={{ color: grayA.grayA8, marginLeft: 4 }}>
                (closed {toTimeAgo(account.account_usage.deal_closed, true)})
              </span>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

// todo: move to shared component
const SectionTitle = ({
  title,
  icon: Icon,
  description,
  color = violet.violet11,
}) => (
  <Flex
    align="center"
    gap="4"
    pb="2"
    px="2"
    style={{ borderBottom: `1px solid ${grayA.grayA4}`, margin: "0 -8px" }}
  >
    <Flex gap="2" align="center">
      {Icon && <Icon size={16} color={color} />}
      <Text size="3" weight="medium" style={{ color }}>
        {title}
      </Text>
    </Flex>
    {description && (
      <Text size="2" style={{ color: grayA.grayA9 }}>
        {description}
      </Text>
    )}
  </Flex>
);

const RelevantData = ({ account }) => {
  return (
    <Flex px="4" py="3" style={{ background: gray.gray2, borderRadius: 12 }}>
      <Text size="2" style={{ color: grayA.grayA10 }}>
        Relevant data from SFDC, Intercom, and three other sources -->
      </Text>
    </Flex>
  );
};

const Summary = ({ account }) => {
  return (
    <Flex direction="column" gap="5" mb="7" pr="2">
      <SectionTitle title="Summary" icon={Sparkles} />
      <Flex direction="column" gap="2" mb="2">
        <Text size="3" weight="medium" style={{ color: grayA.grayA11 }}>
          What's going well
        </Text>
        <Text size="3" style={{ color: grayA.grayA10 }}>
          <Markdown className="markdown-content">
            {account.account_usage.account_usage_pros}
          </Markdown>
        </Text>
      </Flex>

      <Flex direction="column" gap="2" mb="2">
        <Text size="3" weight="medium" style={{ color: grayA.grayA11 }}>
          What's wrong
        </Text>
        <Text size="3" style={{ color: grayA.grayA10 }}>
          <Markdown className="markdown-content">
            {account.account_usage.account_usage_cons}
          </Markdown>
        </Text>
      </Flex>

      <RelevantData account={account} />
    </Flex>
  );
};

const EmailPreview = ({
  email,
  isEmailSent,
  isEditing,
  setIsEditing,
  onSave,
  style,
}) => {
  const [copied, setCopied] = useState(false);

  const handleMarkAsSent = () => {
    onSave({
      item_status: "completed",
    });
  };

  const EmailClientButton = ({
    icon: Icon,
    label,
    color = gray.gray11,
    externalLink = true,
    disabled,
    onClick,
  }) => (
    <Button
      variant="ghost"
      size="2"
      radius="large"
      style={{
        padding: "8px 12px",
        color,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Flex align="center" justify="between" style={{ width: "100%" }}>
        <Flex align="center" gap="2">
          <Icon size={15} strokeWidth={1.5} />
          <Text size="2" weight="medium">
            {label}
          </Text>
        </Flex>
        {externalLink && (
          <ExternalLink size={14} strokeWidth={1.5} color={grayA.grayA8} />
        )}
      </Flex>
    </Button>
  );

  return (
    <Flex
      direction="column"
      style={{
        background: "white",
        border: `1px solid ${grayA.grayA4}`,
        borderRadius: 8,
        overflow: "hidden",
        ...style,
      }}
    >
      <Flex
        justify="between"
        align="center"
        px="4"
        py="2"
        style={{
          borderBottom: `1px solid ${grayA.grayA4}`,
        }}
      >
        <Flex align="center" gap="4">
          <img
            src={isEmailSent ? EmailSentIcon : EmailIcon}
            alt="Email"
            style={{
              width: 24,
              height: 24,
            }}
          />
          <Flex direction="column" gap="2px">
            <Text size="1" weight="medium">
              {email.email_subject}
            </Text>
            <Flex direction="row" gap="2" style={{ color: grayA.grayA9 }}>
              <Text size="1">
                To: {email.email_to.map((to) => to.name).join(", ")}
              </Text>
              {email.email_cc?.length > 0 && (
                <Text size="1">CC: {email.email_cc.join(", ")}</Text>
              )}
            </Flex>
          </Flex>
        </Flex>

        <Flex gap="2">
          {isEmailSent ? (
            <Badge
              size="1"
              color="gray"
              variant="outline"
              radius="full"
              style={{ opacity: 0.6 }}
            >
              <RefreshCw size={12} style={{ marginLeft: 2 }} />
              Synced with SFDC
            </Badge>
          ) : (
            <>
              <Popover.Root>
                <Popover.Trigger asChild>
                  <Button
                    size="1"
                    radius="large"
                    variant="outline"
                    style={{
                      boxShadow: "none",
                      border: "1px solid var(--violet-a5)",
                    }}
                  >
                    <Send size={12} style={{ marginRight: 2 }} />
                    Send
                    <ChevronDown
                      size={12}
                      color={violet.violet11}
                      style={{ marginLeft: 0 }}
                    />
                  </Button>
                </Popover.Trigger>

                <Popover.Content
                  align="end"
                  radius="large"
                  size="1"
                  style={{
                    width: 248,
                  }}
                >
                  <Flex direction="column" gap="3">
                    <Flex
                      direction="column"
                      gap="2px"
                      p="3"
                      style={{
                        backgroundColor: gray.gray2,
                        borderRadius: 8,
                      }}
                    >
                      <Text size="2" weight="medium">
                        Send email
                      </Text>
                      <Text size="1" style={{ color: grayA.grayA11 }}>
                        Choose your preferred email client
                      </Text>
                    </Flex>

                    <Flex direction="column" gap="2" px="1">
                      <EmailClientButton
                        icon={Mail}
                        label="Open in Gmail"
                        color={red.red11}
                        onClick={() =>
                          window.open(
                            `https://mail.google.com/mail/?view=cm&fs=1&to=${email.email_to}&su=${encodeURIComponent(email.email_subject)}&body=${encodeURIComponent(email.email_body)}`
                          )
                        }
                      />
                      <EmailClientButton
                        icon={Mail}
                        label="Open in Outlook"
                        color={blue.blue11}
                        onClick={() =>
                          window.open(
                            `ms-outlook://compose?to=${email.email_to}&subject=${encodeURIComponent(email.email_subject)}&body=${encodeURIComponent(email.email_body)}`
                          )
                        }
                      />
                      <EmailClientButton
                        icon={Mail}
                        label="Open in default client"
                        onClick={() =>
                          window.open(
                            `mailto:${email.email_to}?subject=${encodeURIComponent(email.email_subject)}&body=${encodeURIComponent(email.email_body)}`
                          )
                        }
                      />

                      <Separator size="4" my="1" style={{ opacity: 0.3 }} />

                      <EmailClientButton
                        icon={copied ? ClipboardCheck : ClipboardCopy}
                        externalLink={false}
                        label={copied ? "Copied!" : "Copy to clipboard"}
                        color={copied ? green.green11 : gray.gray11}
                        disabled={copied}
                        onClick={() => {
                          navigator.clipboard.writeText(email.email_body);
                          setCopied(true);
                          setTimeout(() => setCopied(false), 2000);
                        }}
                      />

                      {/* <Separator size="4" my="1" style={{ opacity: 0.3 }} />

                      <Button
                        size="2"
                        radius="large"
                        variant="soft"
                        color="green"
                        onClick={handleMarkAsSent}
                      >
                        <CircleCheckBig size={12} />
                        Mark as sent
                      </Button> */}
                    </Flex>
                  </Flex>
                </Popover.Content>
              </Popover.Root>
            </>
          )}
        </Flex>
      </Flex>

      <Flex direction="column" gap="5" p="5" py="4">
        <Flex
          direction="column"
          style={{
            color: gray.gray11,
            lineHeight: "1.5",
          }}
        >
          <Text size="2">
            <Markdown className="markdown-content">{email.email_body}</Markdown>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const SuggestedAction = ({ action_title, action_content: email }) => (
  <Flex
    direction="column"
    gap="3"
    p="2"
    pt="3"
    style={{
      background: gray.gray1,
      border: `1px solid ${grayA.grayA3}`,
      borderRadius: 12,
    }}
  >
    <Flex gap="2" align="center" mx="2">
      <Zap size={16} color={gray.gray9} style={{ opacity: 0.7 }} />
      <Text size="2" weight="medium">
        {action_title}
      </Text>
    </Flex>
    <EmailPreview email={email} />
  </Flex>
);

const SuggestedActions = ({ account }) => {
  return (
    <Flex direction="column" gap="5" mb="7" pr="2">
      <SectionTitle title="Suggested actions" icon={Shapes} />
      <Flex direction="column" gap="5">
        {account.account_suggested_actions.map((action) => (
          <SuggestedAction key={action.id} {...action} />
        ))}
      </Flex>
    </Flex>
  );
};

const History = ({ account }) => {
  return (
    <Flex direction="column" gap="5" mb="7" pr="2">
      <SectionTitle title="History" icon={HistoryIcon} color={gray.gray11} />
    </Flex>
  );
};

const AccountDetail = () => {
  const { accountId } = useParams();
  const account = mockData.accounts.find(
    (a) => a.account_id === Number(accountId)
  );

  if (!account) {
    return null;
  }

  return (
    <Flex direction="column" className="account-detail-container">
      <Toolbar />
      <ScrollArea
        scrollbars="vertical"
        type="scroll"
        style={{ height: "calc(100vh - 80px)" }}
      >
        <Flex
          gap="6"
          style={{
            maxWidth: 1024,
            minWidth: 860,
            margin: "0 auto",
            padding: "24px 24px 48px",
            width: "100%",
          }}
        >
          <Flex direction="column" gap="2" style={{ width: "100%" }}>
            <Header account={account} />
            <Summary account={account} />
            <SuggestedActions account={account} />
            <History account={account} />
          </Flex>
          <DetailsPane account={account} />
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

export default AccountDetail;

import { formatDistanceToNowStrict, format } from "date-fns";

export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const toFullDate = (dateString) => {
  if (!dateString) return "";
  return format(new Date(dateString), "MMM d, yyyy");
};

export const toTimeAgo = (dateString, short = false) => {
  if (!dateString) return "";

  try {
    let timeAgo = formatDistanceToNowStrict(new Date(dateString), {
      addSuffix: true,
      width: short ? "short" : "long",
    });

    if (short) {
      const abbreviations = {
        minute: "m",
        minutes: "m",
        second: "s",
        seconds: "s",
        hour: "h",
        hours: "h",
        day: "d",
        days: "d",
        month: "mo",
        months: "mo",
        year: "y",
        years: "y",
      };

      timeAgo = timeAgo.replace(
        /(\d+)\s+(minute|second|hour|day|month|year)s?/g,
        (match, num, unit) => `${num}${abbreviations[unit]}`
      );
    }

    return timeAgo;
  } catch (error) {
    return "";
  }
};

export const abbrevNum = (num) => {
  const formatNumber = (n, suffix) => {
    const formatted = n.toFixed(1);
    return formatted.endsWith(".0")
      ? formatted.slice(0, -2) + suffix
      : formatted + suffix;
  };

  if (num >= 1000000) return formatNumber(num / 1000000, "M");
  if (num >= 1000) return formatNumber(num / 1000, "k");
  return formatNumber(num, "");
};

export const unabbrevMonth = (abbreviation) => {
  const monthNames = {
    Jan: "January",
    Feb: "February",
    Mar: "March",
    Apr: "April",
    May: "May",
    Jun: "June",
    Jul: "July",
    Aug: "August",
    Sep: "September",
    Oct: "October",
    Nov: "November",
    Dec: "December",
  };

  return monthNames[abbreviation] || abbreviation;
};

export const daysBetween = (date1, date2) =>
  Math.round(
    Math.abs(new Date(date1) - new Date(date2)) / (1000 * 60 * 60 * 24)
  );

export const generateUUID = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });

export const pluralize = (word, count, plural = `${word}s`) => {
  return count === 1 ? word : plural;
};

export const toPercent = (value, decimalPlaces = 0) => {
  if (value === 0) return "0%";
  return `${(value * 100).toFixed(decimalPlaces)}%`;
};

// todo: update for stuck clusters
export const getClusterStatus = ({
  start_date,
  end_date,
  published_at,
  is_paused,
}) => {
  if (!start_date || !published_at)
    return {
      label: "Unresolved",
      color: "gray",
      tooltip:
        "No fix yet. Add one to a point of friction and publish to activate.",
    };

  const now = new Date();
  const start = new Date(start_date);
  const end = end_date ? new Date(end_date) : null;

  if (is_paused)
    return {
      label: "Paused",
      color: "yellow",
      tooltip: "Fix is temporarily halted. Resume to continue running.",
    };
  if (now >= start && (!end || now <= end))
    return {
      label: "Active",
      color: "green",
      tooltip:
        "Fix is live and running" +
        (end ? `. Will end on ${toFullDate(end)}.` : ""),
    };
  return {
    label: "Inactive",
    color: "gray",
    tooltip:
      now < start ? `Fix will start on ${toFullDate(start)}` : "Fix has ended",
  };
};

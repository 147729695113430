import React from "react";
import { Panel } from "reactflow";
import { Text, Flex, Badge, Button, IconButton } from "@radix-ui/themes";
import { amber, blue, gray, grayA, green } from "@radix-ui/colors";
import {
  Calendar,
  Users,
  TrendingUp,
  Route,
  MessageCircleWarning,
  MessageSquareHeart,
  CircleHelp,
  GraduationCap,
  MessageSquareText,
  Radar,
} from "lucide-react";
import {
  abbrevNum,
  capitalize,
  pluralize,
  toPercent,
  toTimeAgo,
} from "./utils";
import { useOpportunity } from "./context/OpportunityContext";
import Icon from "./Icon";
import { Cross1Icon, Cross2Icon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { useTouchpoint } from "./context/TouchpointContext";

const MetaData = ({ icon: Icon, text, color = gray.gray9, size = 14 }) => (
  <Flex align="center" gap="1">
    <Icon color={color} size={14} style={{ marginBottom: 1 }} />
    <Text color="gray" size="1">
      {text}
    </Text>
  </Flex>
);

const OpportunityCard = ({ opportunity }) => (
  <Flex direction="column" p="4" style={style}>
    <Flex direction="column" gap="2px" mb="3">
      <Flex direction="column" gap="4">
        <Badge
          color="gray"
          radius="full"
          style={{ paddingLeft: 10, paddingRight: 10, width: "fit-content" }}
        >
          <Route size={12} color={grayA.grayA10} />
          {opportunity.opportunity_workflow}
        </Badge>
        <Flex align="center" gap="2">
          {/* <Radar size={14} color={grayA.grayA9} /> */}
          <Text size="1" weight="medium" style={{ color: grayA.grayA9 }}>
            Opportunity
          </Text>
        </Flex>
      </Flex>
      <Text size="3" weight="medium">
        {opportunity.opportunity_name}
      </Text>
    </Flex>
    <Text color="gray" mb="4">
      {opportunity.opportunity_summary}
    </Text>
    <Flex gap="4" style={{ opacity: 0.7 }}>
      <MetaData icon={Calendar} text={`${toTimeAgo(opportunity.created_at)}`} />
      <MetaData
        icon={MessageSquareHeart}
        text={`${toPercent(opportunity.message_saturation)} saturation`}
      />
      {/* <MetaData
        icon={Users}
        text={`${abbrevNum(data.estimated_reach)} ${pluralize("user", data.estimated_reach)}`}
      /> */}
    </Flex>
  </Flex>
);

const LinkedOpportunity = ({ opportunity }) => (
  <Flex direction="column" gap="6px" mt="4">
    <Text size="1" color="gray">
      From opportunity:
    </Text>
    <Flex
      direction="column"
      p="2"
      gap="2"
      style={{
        border: `1px solid ${gray.gray4}`,
        borderRadius: 6,
      }}
    >
      <Flex direction="column" gap="1" px="1">
        {/* <Badge
          color="gray"
          radius="full"
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            width: "fit-content",
          }}
        >
          <Route size={12} color={grayA.grayA10} />
          {opportunity.workflow}
        </Badge> */}
        <Flex align="center" gap="2">
          <Radar size={12} color={grayA.grayA8} />
          <Text size="1" weight="medium">
            {opportunity.opportunity_name}
          </Text>
        </Flex>
        {/* <Text size="1" color="gray">
          {opportunity.opportunity_summary}
        </Text> */}
      </Flex>
      {/* <Flex gap="4" style={{ opacity: 0.7 }}>
        <MetaData
          icon={Calendar}
          text={`${toTimeAgo(opportunity.created_at)}`}
        />
        <MetaData
          icon={MessageSquareHeart}
          text={`${toPercent(opportunity.message_saturation)} saturation`}
        />
      </Flex> */}
    </Flex>
  </Flex>
);

const TouchpointCard = ({ touchpoint, opportunity }) => (
  <Flex direction="column" p="4" style={style}>
    <Flex direction="column" gap="3" mb="3">
      {touchpoint.touchpoint_id ? (
        <Badge variant="outline" color="green" style={{ width: "fit-content" }}>
          <span style={{ fontSize: 14, margin: "0 -1px 2px 1px" }}>•</span>
          Active
        </Badge>
      ) : (
        <Badge
          size="1"
          variant="outline"
          color="gray"
          style={{ width: "fit-content", opacity: 0.8 }}
        >
          Unsaved
        </Badge>
      )}
      <Text size="3" weight="medium">
        {touchpoint.touchpoint_name}
      </Text>
    </Flex>
    {touchpoint.touchpoint_description && (
      <Text color="gray" mb="4">
        {touchpoint.touchpoint_description}
      </Text>
    )}
    <Flex gap="4" style={{ opacity: 0.7 }}>
      {/* <MetaData
          icon={Calendar}
          text={`${toTimeAgo(touchpoint.created_at)}`}
        /> */}
      <MetaData
        icon={MessageSquareText}
        text={`${touchpoint.variants.length} ${pluralize("variant", touchpoint.variants.length)}`}
      />
      {/* <MetaData
        icon={Users}
        text={`${abbrevNum(data.estimated_reach)} ${pluralize("user", data.estimated_reach)}`}
      /> */}
    </Flex>
    {/* {opportunity && <LinkedOpportunity opportunity={opportunity} />} */}
  </Flex>
);

const InfoCard = ({ title, description }) => (
  <Flex
    direction="column"
    gap="2"
    p="4"
    style={{ ...style, background: gray.gray3 }}
  >
    <Flex align="center" gap="2">
      <CircleHelp size={16} color={grayA.grayA9} />
      <Text size="2" weight="medium">
        {title}
      </Text>
    </Flex>
    <Text size="1" color="gray">
      {description}
    </Text>
  </Flex>
);

const OpportunityOverview = ({ opportunity }) => (
  <Flex direction="column" gap="4">
    <OpportunityCard opportunity={opportunity} />
    <InfoCard
      title="Get started by creating a touchpoint"
      description="Click 'Create touchpoint' on the right to engage with users when this event occurs in their session."
    />
  </Flex>
);

const getInfoCardContent = (opportunity, touchpoint) => {
  if (opportunity) {
    return {
      title: "Get started writing your first message",
      description:
        "We've set up your touchpoint based on the opportunity details. Now, craft your message in the sidebar and hit 'Save & deploy' to activate it.",
    };
  } else if (
    touchpoint.touchpoint_id &&
    touchpoint.variants &&
    touchpoint.variants.length > 0
  ) {
    return {
      title: "Create your first message variant",
      description:
        "With variants, you can tailor your message for different segments. It's perfect for creating targeted, personalized content based on user roles, preferences, or behaviors. Click on the gray message node to get started.",
    };
  } else {
    return {
      title: "Set up your touchpoint",
      description:
        "Specify who to target, when to run, and where. Finally, click the message node at the bottom to write your first message content.",
    };
  }
};

const TouchpointOverview = ({ touchpoint, opportunity }) => {
  const { title, description } = getInfoCardContent(opportunity, touchpoint);

  return (
    <Flex direction="column" gap="4">
      <TouchpointCard touchpoint={touchpoint} opportunity={opportunity} />
      <InfoCard title={title} description={description} />
    </Flex>
  );
};

const OverviewCard = () => {
  const { opportunity } = useOpportunity();
  const { touchpoint } = useTouchpoint();

  if (!opportunity && !touchpoint) return null;

  // todo: clean this up
  const isTouchpointEditor = window.location.pathname.includes("touchpoints");
  const Overview = isTouchpointEditor
    ? TouchpointOverview
    : OpportunityOverview;

  return (
    <Panel
      position="top-left"
      style={{ boxShadow: "none", border: "none", overflow: "visible" }}
    >
      <Overview opportunity={opportunity} touchpoint={touchpoint} />
    </Panel>
  );
};

export default OverviewCard;

const style = {
  background: "#fff",
  color: "#000",
  fontSize: "14px",
  width: 360,
  borderRadius: 12,
  border: "1px solid #dddde3",
  boxShadow:
    "0px 1px 3px 0px rgba(0, 0, 0, 0.01), 0px 2px 1px -1px rgba(0, 0, 0, 0.01), 0px 1px 4px 0px rgba(2, 2, 52, 0.08), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.01)",
};

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import { initTelemetry, stopTelemetry } from "@lateral-ai/telemetry";
import LateralUI from "@lateral-ai/ui-react";
import "inter-ui/inter.css";
import "./theme-config.css";
import "./index.css";

const LATERAL_API_KEY = process.env.REACT_APP_LATERAL_API_KEY;
const LATERAL_MODE = process.env.REACT_APP_LATERAL_MODE;

function InitializeTracking() {
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const client = initTelemetry({
        apiKey: LATERAL_API_KEY,
        userId: user.id,
        mode: LATERAL_MODE,
      });

      LateralUI.init(client);
    } else {
      stopTelemetry();
    }
  }, [user]);

  return;
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <Theme accentColor="violet" grayColor="mauve" panelBackground="solid">
      <AuthProvider>
        <InitializeTracking />
        <App />
      </AuthProvider>
    </Theme>
  </>
);

import React, { useEffect } from "react";
import { useReactFlow } from "reactflow";
import { Flex, Text, Heading, IconButton, ScrollArea } from "@radix-ui/themes";
import { Cross2Icon } from "@radix-ui/react-icons";
import { gray, green } from "@radix-ui/colors";
import {
  Bell,
  Blocks,
  Component,
  Fullscreen,
  Plus,
  Route,
  Siren,
  Slack,
  Webhook,
} from "lucide-react";
import useFlowActions from "./hooks/useFlowActions";
import useEscapeKey from "./hooks/useEscapeKey";
import { useOrganization } from "./context/OrganizationContext";
import InterventionIcon from "./assets/intervention.svg";
import ActionIcon from "./assets/action.svg";
import "./AddNodeForm.css";

const NODE_TYPES = {
  embedded: {
    label: "Embedded",
    description: "Add a touchpoint that appears within the editor.",
    options: [
      {
        label: "Homepage - Banner (above editor)",
        value: "homepage_banner",
        icon: Component,
        iconStroke: 1.5,
      },
      {
        label: "Homepage - Toast",
        value: "homepage_toast",
        icon: Component,
        iconStroke: 1.5,
      },
    ],
  },
  overlay: {
    label: "Overlay",
    description: "Add a touchpoint that appears within the editor.",
    options: [
      {
        label: "Toast",
        value: "toast",
        icon: Bell,
      },
      {
        label: "Full-screen modal",
        value: "full_screen_modal",
        icon: Fullscreen,
      },
    ],
  },
};

const Category = ({ category, children }) => (
  <label>
    <Flex direction="column" mb="4" gap="2">
      <Text size="3" weight="medium" style={{ color: gray.gray11 }}>
        {category.label}
      </Text>
      {/* <Text style={{ color: gray.gray11 }}>{category.description}</Text> */}
    </Flex>
    {children}
  </label>
);

const NodeCard = ({ onClick, text, icon: Icon, iconStroke = 1.6, style }) => {
  return (
    <Flex
      onClick={onClick}
      align="center"
      justify="between"
      className="card"
      style={style}
    >
      <Flex gap="3" align="center">
        <Icon color={gray.gray9} size={16} strokeWidth={iconStroke} />
        <Text size="2">{text}</Text>
      </Flex>
      <Plus color={gray.gray9} size={16} strokeWidth={1.8} />
    </Flex>
  );
};

const Form = ({ interventionLocations, onTypeClick, onCancel }) => {
  return (
    <Flex direction="column" className="add-node-form">
      <Flex
        align="center"
        justify="between"
        px="5"
        py="4"
        style={{ borderBottom: `1px solid ${gray.gray6}` }}
      >
        <Flex gap="4" align="center">
          {/* <img
            src={InterventionIcon}
            alt="Touchpoint"
            style={{ width: 22, height: 22 }}
          /> */}
          <Heading size="4" weight="medium">
            Add variant
          </Heading>
        </Flex>
        <IconButton variant="ghost" color="gray" onClick={onCancel}>
          <Cross2Icon />
        </IconButton>
      </Flex>

      <ScrollArea scrollbars="vertical">
        <Flex
          direction="column"
          gap="7"
          p="5"
          style={{ flexGrow: 1, overflow: "scroll" }}
        >
          <Category category={NODE_TYPES.embedded}>
            <Flex direction="column" gap="3">
              {NODE_TYPES.embedded.options.map((intervention) => (
                <NodeCard
                  key={intervention.value}
                  onClick={() =>
                    onTypeClick(
                      interventionLocations[0].intervention_location_id // todo: hook up
                    )
                  }
                  text={intervention.label}
                  icon={intervention.icon}
                  iconStroke={intervention.iconStroke}
                  style={{ border: `1px solid ${gray.gray4}`, borderRadius: 8 }}
                />
              ))}
            </Flex>
          </Category>

          <Category category={NODE_TYPES.overlay}>
            <Flex direction="column" gap="3">
              {NODE_TYPES.overlay.options.map((intervention) => (
                <NodeCard
                  key={intervention.value}
                  onClick={() =>
                    onTypeClick(
                      interventionLocations[0].intervention_location_id // todo: hook up
                    )
                  }
                  text={intervention.label}
                  icon={intervention.icon}
                  iconStroke={intervention.iconStroke}
                  style={{ border: `1px solid ${gray.gray4}`, borderRadius: 8 }}
                />
              ))}
            </Flex>
          </Category>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

const AddNodeForm = () => {
  const { getNode, getNodes, getEdges, setNodes, setEdges } = useReactFlow();
  const { createInterventionNode, createEdge } = useFlowActions();
  const { interventionLocations, getInterventionLocations } = useOrganization();

  const placeholderNode = getNode("placeholder");

  useEffect(() => {
    getInterventionLocations();
  }, [getInterventionLocations]);

  const handleCancel = () => {
    const edges = getEdges();
    const nodes = getNodes();
    const selectedNode =
      placeholderNode ||
      nodes.find((node) => node.selected && node.type === "intervention");
    const eventGroupId = edges.find(
      (e) =>
        e.target === edges.find((e) => e.target === selectedNode.id)?.source
    )?.source;

    const beforeBadgeExists = nodes.some(
      (node) => node.id === `badge-${eventGroupId}-BEFORE`
    );

    const nodesToRemove = [
      selectedNode.id,
      ...(beforeBadgeExists
        ? [`badge-${eventGroupId}-BEFORE`, `badge-${eventGroupId}-RESOLVED`]
        : []),
    ];

    setNodes((nodes) =>
      nodes.filter((node) => !nodesToRemove.includes(node.id))
    );

    setEdges((edges) => {
      if (beforeBadgeExists) {
        const beforeBadgeEdge = edges.find(
          (e) => e.source === `badge-${eventGroupId}-BEFORE`
        );
        const restoredEdge = beforeBadgeEdge
          ? createEdge(eventGroupId, beforeBadgeEdge.target)
          : null;

        return [
          ...edges.filter(
            (e) =>
              !nodesToRemove.includes(e.source) &&
              !nodesToRemove.includes(e.target) &&
              e.source !== eventGroupId
          ),
          ...(restoredEdge ? [restoredEdge] : []),
        ];
      } else {
        // If BEFORE/RESOLVED badges don't exist, simply remove the edge to the placeholder
        return edges.filter(
          (e) => e.target !== selectedNode.id && e.source !== selectedNode.id
        );
      }
    });
  };

  useEscapeKey(handleCancel);

  const handleTypeClick = async (type) => {
    const newId = Date.now();
    const newIntervention = {
      intervention_id: newId,
      intervention_location_id: type,
      intervention_data: {},
      intervention_criteria: [],
    };

    setNodes((nds) => [
      ...nds.filter((node) => node.id !== placeholderNode.id),
      createInterventionNode(
        placeholderNode.data.segment,
        newIntervention,
        true,
        placeholderNode.position
      ),
    ]);
    setEdges((eds) => [
      ...eds.filter((edge) => edge.target !== placeholderNode.id),
      createEdge(
        eds.find((edge) => edge.target === placeholderNode.id).source,
        `intervention-${newIntervention.intervention_id}`
      ),
    ]);
  };

  return (
    <Form
      interventionLocations={interventionLocations}
      onTypeClick={handleTypeClick}
      onCancel={handleCancel}
    />
  );
};

export default AddNodeForm;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Text,
  Flex,
  Table,
  Avatar,
  Badge,
  ScrollArea,
  Tooltip,
  HoverCard,
  Button,
  IconButton,
} from "@radix-ui/themes";
import { amber, gray, grayA, violet } from "@radix-ui/colors";
import {
  ArrowRight,
  Activity,
  Building2,
  Briefcase,
  Radar,
  ArrowDownNarrowWide,
  Repeat,
  UsersRound,
  Send,
  MessageCircleHeart,
  ExternalLink,
  EllipsisVertical,
  ChevronDown,
  ListFilter,
} from "lucide-react";
import { useOpenings } from "./context/OpeningsContext";
import Select from "./Select";
import { toTimeAgo, pluralize, abbrevNum, daysBetween } from "./utils";
import { FunnelMoneyIcon, TrophyIcon } from "./assets/icons";
import "./HomePage.css";

const SectionHeader = ({ icon: Icon, title, children }) => (
  <Flex
    justify="between"
    align="center"
    mb="5"
    pb="4"
    style={{ borderBottom: `1px solid ${grayA.grayA4}` }}
  >
    <Flex align="center" gap="2">
      <Flex align="center" gap="3">
        <Icon size={24} strokeWidth={1.75} color={gray.gray9} />
        <Text size="6" weight="medium">
          {title}
        </Text>
      </Flex>
    </Flex>
    <Flex gap="3">{children}</Flex>
  </Flex>
);

const sortOptions = {
  accounts: {
    "Last detected": (a, b) => new Date(b.created_at) - new Date(a.created_at),
    "Soonest renewal": (a, b) =>
      daysBetween(
        new Date(),
        new Date(a.opening_user.user_organization.renewal_date)
      ) -
      daysBetween(
        new Date(),
        new Date(b.opening_user.user_organization.renewal_date)
      ),
    company: (a, b) =>
      a.opening_user.user_organization.organization_name.localeCompare(
        b.opening_user.user_organization.organization_name
      ),
  },
  activities: {
    latest: (a, b) => new Date(b.performed_at) - new Date(a.performed_at),
    Teammate: (a, b) => a.teammate.name.localeCompare(b.teammate.name),
    account: (a, b) => a.account_name.localeCompare(b.account_name),
  },
};

const SectionSubheader = ({
  icon: Icon,
  title,
  onSeeAll,
  sortBy,
  onSortChange,
  sortOptions,
}) => (
  <Flex justify="between" align="center" mb="4">
    <Flex align="center" gap="4">
      <Flex align="center" gap="10px">
        <Icon size={16} strokeWidth={1.5} color={gray.gray8} />
        <Text size="3" weight="medium">
          {title}
        </Text>
      </Flex>
      <Select.Root value={sortBy} onValueChange={onSortChange}>
        <Select.Trigger variant="ghost">
          <Flex align="center" gap="1" style={{ color: violet.violet10 }}>
            <Text size="2" weight="medium">
              {sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}
            </Text>
            <ChevronDown size={14} />
          </Flex>
        </Select.Trigger>
        <Select.Content>
          {Object.keys(sortOptions).map((option) => (
            <Select.Item key={option} value={option}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    </Flex>

    <Flex
      align="center"
      gap="1"
      style={{ cursor: "pointer" }}
      onClick={onSeeAll}
    >
      <Text size="2" weight="medium" style={{ color: gray.gray9 }}>
        See all
      </Text>
      <ArrowRight size={13} strokeWidth={2} color={gray.gray9} />
    </Flex>
  </Flex>
);

const AccountHoverCard = ({ children, account }) => (
  <HoverCard.Root>
    <HoverCard.Trigger style={{ cursor: "default" }}>
      {children}
    </HoverCard.Trigger>

    <HoverCard.Content size="2" style={{ width: 280 }}>
      <Flex direction="column" gap="4">
        <Flex justify="between" align="center">
          <Flex align="center" gap="3">
            <Avatar
              size="3"
              src={account.opening_user.user_organization.organization_avatar}
              radius="large"
              style={{
                padding: 6,
                backgroundColor: "white",
                border: `1px solid ${grayA.grayA4}`,
              }}
            />
            <Flex direction="column">
              <Text size="4" weight="medium">
                {account.opening_user.user_organization.organization_name}
              </Text>
              <Text
                size="1"
                style={{
                  color: grayA.grayA9,
                  marginTop: -2,
                  marginBottom: 3,
                }}
              >
                $
                {abbrevNum(
                  account.opening_user.user_organization.organization_value
                )}{" "}
                ARR
              </Text>
            </Flex>
          </Flex>
          <Button
            variant="outline"
            color="gray"
            size="1"
            radius="large"
            style={{ opacity: 0.7 }}
          >
            Open <ExternalLink size={12} strokeWidth={1.5} />
          </Button>
        </Flex>

        <Flex align="center" gap="2">
          {account.opening_user.user_organization.organization_top_tier && (
            <Flex
              align="center"
              gap="1"
              style={{
                color: amber.amber11,
                backgroundColor: amber.amber2,
                borderRadius: 6,
                padding: "4px 12px",
                fontSize: 12,
                minWidth: "fit-content",
              }}
            >
              <TrophyIcon width={12} height={12} />
              <Text>Top account</Text>
            </Flex>
          )}

          <Flex
            align="center"
            gap="1"
            style={{
              color: gray.gray11,
              backgroundColor: gray.gray2,
              borderRadius: 6,
              padding: "4px 12px",
              fontSize: 12,
              flex: 1,
            }}
          >
            <Repeat size={12} />
            <Text>
              Renewal in{" "}
              {daysBetween(
                new Date(),
                new Date(account.opening_user.user_organization.renewal_date)
              )}
              d
            </Text>
          </Flex>
        </Flex>

        <Flex direction="column" gap="1">
          <Text
            style={{
              fontSize: 10,
              textTransform: "uppercase",
              color: gray.gray10,
            }}
          >
            ECONOMIC BUYER
          </Text>

          <Flex
            align="center"
            justify="between"
            style={{
              border: `1px solid ${grayA.grayA3}`,
              borderRadius: 8,
              padding: "4px 8px",
            }}
          >
            <Flex align="center" gap="2">
              <Avatar
                size="2"
                src={
                  account.opening_user.user_organization.economic_buyer.avatar
                }
                radius="full"
                style={{
                  border: `1px solid ${grayA.grayA4}`,
                  height: 24,
                  width: 24,
                }}
              />
              <Flex direction="column">
                <Text weight="medium" size="1">
                  {account.opening_user.user_organization.economic_buyer.name}
                </Text>
                <Text
                  color="gray"
                  style={{
                    color: grayA.grayA9,
                    fontSize: 10,
                    marginTop: -3,
                  }}
                >
                  {account.opening_user.user_organization.economic_buyer.role}
                </Text>
              </Flex>
            </Flex>
            <IconButton variant="ghost" size="1" color="gray">
              <EllipsisVertical size={14} />
            </IconButton>
          </Flex>
        </Flex>
      </Flex>
    </HoverCard.Content>
  </HoverCard.Root>
);

const AccountRow = ({ account, onRowClick }) => (
  <Table.Row className="table-row" onClick={onRowClick}>
    <Table.Cell>
      <Flex align="center" gap="3">
        <AccountHoverCard account={account}>
          <Flex align="center" gap="3">
            <Flex align="center" gap="2">
              <Avatar
                size="1"
                src={account.opening_user.user_organization.organization_avatar}
                style={{
                  padding: 3,
                  backgroundColor: "white",
                  border: `1px solid ${grayA.grayA4}`,
                  height: 20,
                  width: 20,
                }}
              />
              <Text size="2">
                {account.opening_user.user_organization.organization_name}
              </Text>
            </Flex>
          </Flex>
        </AccountHoverCard>

        {account.opening_user.user_organization.organization_top_tier && (
          <Tooltip content="Top account">
            <TrophyIcon size={16} strokeWidth={1.5} color={amber.amber10} />
          </Tooltip>
        )}
      </Flex>
    </Table.Cell>
    <Table.Cell>
      <Text size="2">{account.opening_title}</Text>
    </Table.Cell>
    <Table.Cell>
      <Text size="2" color="gray">
        ${abbrevNum(account.opening_user.user_organization.organization_value)}{" "}
        ARR
      </Text>
    </Table.Cell>
    <Table.Cell>
      <Text size="2" color="gray">
        {daysBetween(
          new Date(),
          new Date(account.opening_user.user_organization.renewal_date)
        )}{" "}
        days
      </Text>
    </Table.Cell>
    <Table.Cell>
      <Text size="2" color="gray">
        {toTimeAgo(account.created_at, true)}
      </Text>
    </Table.Cell>
  </Table.Row>
);

const TeammateHoverCard = ({ children, teammate }) => (
  <HoverCard.Root>
    <HoverCard.Trigger style={{ cursor: "default" }}>
      {children}
    </HoverCard.Trigger>

    <HoverCard.Content size="2" style={{ width: 280 }}>
      <Flex direction="column" gap="4">
        <Flex justify="between" align="center">
          <Flex align="center" gap="3">
            <Avatar
              size="3"
              src={teammate.avatar}
              radius="full"
              style={{
                backgroundColor: "white",
                border: `1px solid ${grayA.grayA4}`,
              }}
            />
            <Flex direction="column">
              <Text size="4" weight="medium">
                {teammate.name}
              </Text>
              <Text
                size="1"
                style={{
                  color: grayA.grayA9,
                  marginTop: -2,
                  marginBottom: 3,
                }}
              >
                {teammate.total_accounts_managed} accounts
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" gap="1">
          <Text
            size="1"
            style={{
              fontSize: 10,
              textTransform: "uppercase",
              color: gray.gray10,
            }}
          >
            This week
          </Text>
          <Flex align="center" gap="2">
            <Flex
              align="center"
              gap="3"
              style={{
                color: gray.gray11,
                backgroundColor: gray.gray2,
                borderRadius: 6,
                padding: "4px 12px",
                fontSize: 12,
                flex: 1,
              }}
            >
              <Flex align="center" gap="1" style={{ color: gray.gray9 }}>
                <Send size={11} />
                <Text>{teammate.weekly_emails_sent} sent</Text>
              </Flex>
              <Flex align="center" gap="1" style={{ color: gray.gray9 }}>
                <MessageCircleHeart size={12} />
                <Text>
                  {teammate.weekly_positive_responses} positive responses
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </HoverCard.Content>
  </HoverCard.Root>
);

const TeamActivityRow = ({ activity }) => (
  <Table.Row className="table-row">
    <Table.Cell>
      <TeammateHoverCard teammate={activity.teammate}>
        <Flex align="center" gap="2">
          <Avatar
            size="1"
            src={activity.teammate.avatar}
            radius="full"
            style={{
              border: `1px solid ${grayA.grayA4}`,
              height: 20,
              width: 20,
            }}
          />
          <Text size="2">{activity.teammate.name}</Text>
        </Flex>
      </TeammateHoverCard>
    </Table.Cell>
    <Table.Cell>
      <Text size="2">{activity.action}</Text>
    </Table.Cell>
    <Table.Cell>
      <Flex align="center" gap="2">
        <AccountHoverCard account={activity.account}>
          <Flex align="center" gap="3">
            <Flex align="center" gap="2">
              <Avatar
                size="1"
                src={
                  activity.account.opening_user.user_organization
                    .organization_avatar
                }
                style={{
                  padding: 3,
                  backgroundColor: "white",
                  border: `1px solid ${grayA.grayA4}`,
                  height: 20,
                  width: 20,
                }}
              />
              <Text size="2">
                {
                  activity.account.opening_user.user_organization
                    .organization_name
                }
              </Text>
            </Flex>
          </Flex>
        </AccountHoverCard>

        {activity.account.opening_user.user_organization
          .organization_top_tier && (
          <Tooltip content="Top account">
            <TrophyIcon size={16} strokeWidth={1.5} color={amber.amber10} />
          </Tooltip>
        )}
      </Flex>
    </Table.Cell>
    <Table.Cell>
      <Text size="2" color="gray">
        {toTimeAgo(activity.performed_at, true)}
      </Text>
    </Table.Cell>
  </Table.Row>
);

const StatBadge = ({ icon: Icon, children, ...props }) => (
  <Badge
    size="3"
    variant="soft"
    radius="large"
    color="gray"
    style={{ background: gray.gray2, height: 32 }}
    {...props}
  >
    <Icon color={gray.gray9} size={14} />
    {children}
  </Badge>
);

const SortableColumnHeader = ({ label, sortKeys, sortBy }) => (
  <Flex align="center" gap="2">
    {label}
    {(Array.isArray(sortKeys)
      ? sortKeys.includes(sortBy)
      : sortBy === sortKeys) && (
      <ArrowDownNarrowWide size={12} strokeWidth={1.5} />
    )}
  </Flex>
);

const AccountsSection = ({ accounts }) => {
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState("Soonest renewal");
  const sortedAccounts = [...accounts].sort(sortOptions.accounts[sortBy]);

  return (
    <Flex direction="column" mb="6">
      <SectionHeader
        icon={Building2}
        title="Accounts"
        subtitle="companies"
        count={accounts.length}
      >
        <StatBadge icon={Briefcase}>
          {accounts.length} {pluralize("company", accounts.length, "companies")}
        </StatBadge>
        <StatBadge icon={FunnelMoneyIcon}>
          $
          {abbrevNum(
            accounts.reduce(
              (sum, account) =>
                sum + account.opening_user.user_organization.organization_value,
              0
            )
          )}{" "}
          ARR
        </StatBadge>
      </SectionHeader>

      <SectionSubheader
        icon={Radar}
        title="Active openings"
        onSeeAll={() => navigate("/inbox")}
        sortBy={sortBy}
        onSortChange={setSortBy}
        sortOptions={sortOptions.accounts}
      />

      <Table.Root variant="surface">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell style={{ width: "18%" }}>
              <SortableColumnHeader
                label="Account"
                sortKeys={["company"]}
                sortBy={sortBy}
              />
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell style={{ width: "38%" }}>
              Opening
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell style={{ width: "16%" }}>
              Account size
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell style={{ width: "15%" }}>
              <SortableColumnHeader
                label="Account renewal"
                sortKeys={["Soonest renewal"]}
                sortBy={sortBy}
              />
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell style={{ width: "12%" }}>
              <SortableColumnHeader
                label="Detected"
                sortKeys={["Last detected"]}
                sortBy={sortBy}
              />
            </Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sortedAccounts.map((account) => (
            <AccountRow
              key={account.opening_id}
              account={account}
              onRowClick={() => navigate(`/inbox/${account.opening_id}`)}
            />
          ))}
        </Table.Body>
      </Table.Root>
    </Flex>
  );
};

const TeamSection = ({ activities }) => {
  const [sortBy, setSortBy] = useState("latest");
  const sortedActivities = [...activities].sort(sortOptions.activities[sortBy]);

  return (
    <Flex direction="column">
      <SectionHeader icon={UsersRound} title="Team">
        <StatBadge icon={Send}>243 emails sent this week</StatBadge>
        <StatBadge icon={MessageCircleHeart}>202 positive responses</StatBadge>
      </SectionHeader>

      <SectionSubheader
        icon={Activity}
        title="Activity"
        onSeeAll={() => {
          /* handle see all click */
        }}
        sortBy={sortBy}
        onSortChange={setSortBy}
        sortOptions={sortOptions.activities}
      />

      <Table.Root variant="surface">
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>
              <SortableColumnHeader
                label="Teammate"
                sortKeys={["Teammate"]}
                sortBy={sortBy}
              />
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Action</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell style={{ width: "16%" }}>
              <SortableColumnHeader
                label="Account"
                sortKeys={["account"]}
                sortBy={sortBy}
              />
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>
              <SortableColumnHeader
                label="Performed"
                sortKeys={["latest"]}
                sortBy={sortBy}
              />
            </Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {sortedActivities.map((activity) => (
            <TeamActivityRow key={activity.id} activity={activity} />
          ))}
        </Table.Body>
      </Table.Root>
    </Flex>
  );
};

const HomePage = () => {
  const { getOpenings } = useOpenings();
  const [accounts, setAccounts] = useState([]);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    getOpenings().then(setAccounts);
    setActivities(mockData.activities);
  }, [getOpenings]);

  return (
    <ScrollArea
      type="scroll"
      scrollbars="vertical"
      style={{ height: "calc(100vh - 18px)" }}
    >
      <Flex
        direction="column"
        className="home-page-container"
        gap="6"
        style={{
          maxWidth: 1028,
          margin: "0 auto",
          padding: "24px",
        }}
      >
        <AccountsSection accounts={accounts} />
        <TeamSection activities={activities} />
      </Flex>
    </ScrollArea>
  );
};

const mockData = {
  activities: [
    {
      id: 1,
      teammate: {
        name: "Jane Ives",
        avatar: "https://i.pravatar.cc/150?img=1",
        value: 250000,
        weekly_emails_sent: 82,
        weekly_positive_responses: 71,
        total_accounts_managed: 15,
      },
      action: 'Followed up on "Migrating data out of product"',
      performed_at: new Date(Date.now() - 15 * 60 * 1000),
      account: {
        opening_user: {
          user_organization: {
            organization_name: "Oracle",
            organization_value: 280000,
            organization_top_tier: false,
            organization_avatar:
              "https://cdn.iconscout.com/icon/free/png-256/free-oracle-logo-icon-download-in-svg-png-gif-file-formats--brand-development-tools-pack-logos-icons-226044.png?f=webp&w=256",
            renewal_date: "2025-02-15",
            economic_buyer: {
              name: "James Wilson",
              role: "VP of Engineering",
              avatar: "https://i.pravatar.cc/150?img=61",
            },
          },
        },
      },
    },
    {
      id: 2,
      teammate: {
        name: "Jane Ives",
        avatar: "https://i.pravatar.cc/150?img=1",
        value: 250000,
        weekly_emails_sent: 82,
        weekly_positive_responses: 71,
        total_accounts_managed: 15,
      },
      action: 'Followed up on "Revisiting pricing page"',
      performed_at: new Date(Date.now() - 32 * 60 * 1000),
      account: {
        opening_user: {
          user_organization: {
            organization_name: "Oracle",
            organization_value: 280000,
            organization_top_tier: false,
            organization_avatar:
              "https://cdn.iconscout.com/icon/free/png-256/free-oracle-logo-icon-download-in-svg-png-gif-file-formats--brand-development-tools-pack-logos-icons-226044.png?f=webp&w=256",
            renewal_date: "2025-02-15",
            economic_buyer: {
              name: "James Wilson",
              role: "VP of Engineering",
              avatar: "https://i.pravatar.cc/150?img=61",
            },
          },
        },
      },
    },
    {
      id: 3,
      teammate: {
        name: "Priya Patel",
        avatar: "https://i.pravatar.cc/150?img=38",
        value: 100000,
        weekly_emails_sent: 65,
        weekly_positive_responses: 52,
        total_accounts_managed: 12,
      },
      action: 'Sent initial email for "Stuck on Python integration"',
      performed_at: new Date(Date.now() - 2 * 60 * 60 * 1000),
      account: {
        opening_user: {
          user_organization: {
            organization_name: "Apple",
            organization_value: 450000,
            organization_top_tier: false,
            organization_avatar:
              "https://images.vexels.com/media/users/3/129234/isolated/lists/73970c892d748c7507db8e10d71535b0-apple-logo-icon.png",
            renewal_date: "2025-01-20",
            economic_buyer: {
              name: "Alex Kim",
              role: "Engineering Manager",
              avatar: "https://i.pravatar.cc/150?img=34",
            },
          },
        },
      },
    },
    {
      id: 4,
      teammate: {
        name: "Marcus Jordan",
        avatar: "https://i.pravatar.cc/150?img=64",
        value: 50000,
        weekly_emails_sent: 54,
        weekly_positive_responses: 45,
        total_accounts_managed: 8,
      },
      action: 'Positive response on "Exploring monitoring features"',
      performed_at: new Date(Date.now() - 3 * 60 * 60 * 1000),
      account: {
        opening_user: {
          user_organization: {
            organization_name: "Airbnb",
            organization_value: 720000,
            organization_top_tier: true,
            organization_avatar:
              "https://a0.muscache.com/airbnb/static/icons/android-icon-192x192-c0465f9f0380893768972a31a614b670.png",
            renewal_date: "2025-01-31",
            economic_buyer: {
              name: "Sarah Martinez",
              role: "VP of Platform Engineering",
              avatar: "https://i.pravatar.cc/150?img=44",
            },
          },
        },
      },
    },
    {
      id: 5,
      teammate: {
        name: "Marcus Jordan",
        avatar: "https://i.pravatar.cc/150?img=64",
        value: 50000,
        weekly_emails_sent: 54,
        weekly_positive_responses: 45,
        total_accounts_managed: 8,
      },
      action: 'Marked as completed "Investigating rate limiting configuration"',
      performed_at: new Date(Date.now() - 3 * 60 * 60 * 1000),
      account: {
        opening_user: {
          user_organization: {
            organization_name: "Slack",
            organization_value: 650000,
            organization_top_tier: false,
            organization_avatar: "https://slack.com/favicon.ico",
            renewal_date: "2024-12-22",
            economic_buyer: {
              name: "Mike Thompson",
              role: "Head of Infrastructure",
              avatar: "https://i.pravatar.cc/150?img=17",
            },
          },
        },
      },
    },
    {
      id: 6,
      teammate: {
        name: "Adam O'Brien",
        avatar: "https://i.pravatar.cc/150?img=8",
        value: 30000,
        weekly_emails_sent: 42,
        weekly_positive_responses: 34,
        total_accounts_managed: 6,
      },
      action:
        'Sent initial email for "Multiple visits to data migration guides"',
      performed_at: new Date(Date.now() - 5 * 60 * 60 * 1000),
      account: {
        opening_user: {
          user_organization: {
            organization_name: "Netflix",
            organization_value: 820000,
            organization_top_tier: true,
            organization_avatar:
              "https://assets.nflxext.com/us/ffe/siteui/common/icons/nficon2016.ico",
            renewal_date: "2025-01-07",
            economic_buyer: {
              name: "Rachel Park",
              role: "Director of Engineering",
              avatar: "https://i.pravatar.cc/150?img=45",
            },
          },
        },
      },
    },
  ],
};

export default HomePage;

import React, { useMemo } from "react";
import { Handle } from "reactflow";
import { Button, Flex, Heading, Text, Select } from "@radix-ui/themes";
import { gray } from "@radix-ui/colors";
import { MapPin } from "lucide-react";
import { useTouchpoint } from "../context/TouchpointContext";
import { NODE_STYLE } from "../utils/constants";
import { useOrganization } from "../context/OrganizationContext";

const LocationNode = ({ data }) => {
  const { touchpointLocations } = useOrganization();
  const { touchpoint, setTouchpoint } = useTouchpoint();

  const hasUnsavedChanges = useMemo(
    () =>
      data.selectedLocation?.location_id !==
      touchpoint.touchpoint_location?.location_id,
    [data.selectedLocation, touchpoint.touchpoint_location]
  );

  const handleLocationChange = (value) => {
    const selectedLocation = touchpointLocations.find(
      (location) => location.location_id === value
    );
    data.onChange({ selectedLocation });
  };

  const handleSave = () => {
    setTouchpoint({
      ...touchpoint,
      touchpoint_location: data.selectedLocation,
    });
  };

  return (
    <Flex direction="column" gap="3" style={{ ...NODE_STYLE, width: 358 }}>
      <Flex align="center" gap="2">
        <MapPin color={gray.gray8} size={18} />
        <Heading size="3" weight="medium">
          Where to run?
        </Heading>
      </Flex>
      <Text color="gray">
        The touchpoint will run in the following location:
      </Text>
      <Select.Root
        value={data.selectedLocation?.location_id}
        onValueChange={handleLocationChange}
      >
        <Select.Trigger placeholder="Select location" />
        <Select.Content>
          {touchpointLocations.map((location) => (
            <Select.Item
              key={location.location_id}
              value={location.location_id}
            >
              {location.location_name}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
      <Handle
        type="target"
        position="top"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
      <Handle
        type="source"
        position="bottom"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
      {hasUnsavedChanges && (
        <Button mt="2" size="2" onClick={handleSave}>
          Save changes
        </Button>
      )}
    </Flex>
  );
};

export default LocationNode;

import React from "react";
import { Handle, NodeToolbar } from "reactflow";
import { Button, Code, Flex, Text } from "@radix-ui/themes";
import { gray, slate, violet } from "@radix-ui/colors";
import { Pencil, AppWindow, Zap } from "lucide-react";
import { useOrganization } from "../context/OrganizationContext";
import AnimatedNodeToolbar from "../AnimatedNodeToolbar";
import { NODE_STATUS, NODE_STYLE } from "../utils/constants";
import InterventionIcon from "../assets/intervention.svg";
import SuggestedInterventionIcon from "../assets/intervention-suggested.svg";
import { useTouchpoint } from "../context/TouchpointContext";

const VariantNode = ({ data, selected, ...props }) => {
  const { touchpoint } = useTouchpoint();

  const { touchpointLocations } = useOrganization();
  const touchpointLocation = touchpointLocations.find(
    (location) => location.location_id === touchpoint.touchpoint_location_id
  );

  const isSuggested = data.variant.suggested;
  const filledCriteriaCount = getFilledCriteriaCount(
    data.variant.eligibility_criteria
  );

  const joinedVariantData =
    touchpointLocation?.location_fields.reduce(
      (acc, field) => ({
        ...acc,
        [field.field_id]: {
          ...field,
          value: data.variant.variant_data[field.field_id] || "",
        },
      }),
      {}
    ) || {};

  const filledFields = Object.values(joinedVariantData).filter(
    (field) => field.value
  );

  return (
    <div onDoubleClick={() => data.updateStatus(NODE_STATUS.EDITING)}>
      <Flex
        direction="column"
        style={{
          ...NODE_STYLE,
          width: 328,
          padding: 0,
          overflow: "hidden",
          ...(selected && {
            border: "1px solid transparent",
            boxShadow: `0 0 0 2px ${violet.violet9}, ` + NODE_STYLE.boxShadow,
          }),
          ...(isSuggested && { background: violet.violet4 }),
        }}
      >
        <Flex direction="column" gap="3" p="4">
          {filledCriteriaCount > 0 && (
            <CriteriaSection criteria={data.variant.eligibility_criteria} />
          )}
          <VariantHeader variantName={data.variant.variant_name} />
          {filledFields.length > 0 && (
            <FilledFieldsSection
              fields={filledFields}
              isSuggested={isSuggested}
            />
          )}
        </Flex>
        {/* {touchpointLocation?.url && (
          <UrlSection
            url={touchpointLocation.url}
            isSuggested={isSuggested}
          />
        )} */}
        <NodeToolbarSection data={data} />
      </Flex>
      <Handle
        type="target"
        position="top"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
      <Handle
        type="source"
        position="right"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
    </div>
  );
};

const getFilledCriteriaCount = (criteria) =>
  criteria.filter((c) => c.field && c.operator && String(c.value).trim())
    .length;

const Criteria = ({ criteria }) => {
  if (!criteria || criteria.length === 0) return null;

  const { field, operator, value } = criteria[0];
  const filledCriteriaCount = getFilledCriteriaCount(criteria);

  return (
    <Flex align="center" justify="between">
      <Text size="1" weight="medium" style={{ color: gray.gray9 }}>
        When{" "}
        <Code color="gray" style={{ opacity: 0.8 }}>
          {field}
        </Code>{" "}
        {operator}{" "}
        <Code color="gray" style={{ opacity: 0.8 }}>
          {`${value}`}
        </Code>
      </Text>
      {filledCriteriaCount > 1 && (
        <Text size="1" weight="medium" ml="2" style={{ color: gray.gray8 }}>
          + {filledCriteriaCount - 1} more
        </Text>
      )}
    </Flex>
  );
};

const CriteriaSection = ({ criteria }) => (
  <Flex
    align="center"
    gap="2"
    py="6px"
    px="4"
    style={{
      background: slate.slate1,
      border: `1px solid ${slate.slate4}`,
      borderRadius: 8,
      margin: "-8px -8px 2px -8px",
    }}
  >
    <Zap
      color={slate.slate9}
      size={13}
      strokeWidth={2}
      style={{ opacity: 0.8 }}
    />
    <Criteria criteria={criteria} />
  </Flex>
);

const VariantHeader = ({ variantName }) => (
  <Flex gap="3" align="center">
    <img src={InterventionIcon} alt="Intervention" draggable="false" />
    <Flex direction="column">
      <Text
        size="1"
        weight="medium"
        style={{
          color: gray.gray9,
          marginBottom: -1,
        }}
      >
        Message
      </Text>
      <Text size="2" weight="medium">
        {variantName || "Untitled"}
      </Text>
    </Flex>
  </Flex>
);

const FilledFieldsSection = ({ fields, isSuggested }) => (
  <Flex direction="column" gap="3">
    {fields.slice(0, 2).map((field) => (
      <Flex direction="column" key={field.field_id}>
        {fields.length > 1 && (
          <Text size="1" style={{ color: gray.gray9 }}>
            {field.field_name}
          </Text>
        )}
        <Text style={{ opacity: isSuggested ? 0.6 : 0.9 }}>{field.value}</Text>
      </Flex>
    ))}
    {fields.length > 2 && (
      <Text size="1" weight="medium" style={{ color: gray.gray9 }}>
        +{fields.length - 2} more
      </Text>
    )}
  </Flex>
);

const UrlSection = ({ url, isSuggested }) => (
  <Flex
    align="center"
    gap="2"
    py="2"
    px="4"
    style={{
      background: isSuggested ? violet.violet5 : slate.slate3,
      boxShadow: `rgba(0, 0, 0, 0.05) 0px -3px 0px 0px inset, rgba(255, 255, 255, 0.5) 0px -1px 0px 1px inset`,
      borderRadius: "0px 0px 11px 11px",
      paddingBottom: "11px",
    }}
  >
    <AppWindow
      color={isSuggested ? violet.violet9 : slate.slate9}
      size={14}
      strokeWidth={1.7}
      style={{ opacity: isSuggested ? 0.6 : 0.8 }}
    />
    <Text
      weight="medium"
      size="1"
      style={{
        color: isSuggested ? violet.violet9 : slate.slate9,
        opacity: isSuggested ? 0.7 : 1,
      }}
    >
      {url}
    </Text>
  </Flex>
);

const NodeToolbarSection = ({ data }) => {
  if (data.status) {
    return (
      data.isLayouted && (
        <NodeToolbar>
          <Flex align="center" gap="2" style={{ background: "#F8F8F8" }}>
            <Pencil color={violet.violet9} size={12} strokeWidth={1.75} />
            <Text size="1" color="violet" weight="medium">
              {data.status === NODE_STATUS.CREATING ? "NEW" : "EDITING"}
            </Text>
          </Flex>
        </NodeToolbar>
      )
    );
  }

  return (
    <AnimatedNodeToolbar position="bottom">
      <Flex gap="2">
        <Button
          onClick={() => data.updateStatus(NODE_STATUS.EDITING)}
          size="1"
          variant="outline"
          style={{ background: "#F8F8F8" }}
        >
          <Pencil size={12} strokeWidth={1.5} />
          Edit
        </Button>
      </Flex>
    </AnimatedNodeToolbar>
  );
};

export default VariantNode;

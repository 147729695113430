import { Position } from "reactflow";

export const getSourceHandlePosition = (direction) => {
  switch (direction) {
    case "DOWN":
      return Position.Bottom;
    case "UP":
      return Position.Top;
    case "RIGHT":
      return Position.Right;
    case "LEFT":
      return Position.Left;
  }
};

export const getTargetHandlePosition = (direction) => {
  switch (direction) {
    case "DOWN":
      return Position.Top;
    case "UP":
      return Position.Bottom;
    case "RIGHT":
      return Position.Left;
    case "LEFT":
      return Position.Right;
  }
};

import React from "react";
import { Handle, useReactFlow } from "reactflow";
import { Button, Flex, Text } from "@radix-ui/themes";
import { gray, violet } from "@radix-ui/colors";
import Icon from "../Icon";
import { NODE_STYLE } from "../utils/constants";
import useFlowActions from "../hooks/useFlowActions";
import AnimatedNodeToolbar from "../AnimatedNodeToolbar";

const SegmentNode = ({ id, data, selected }) => {
  const { getNode, setNodes, setEdges } = useReactFlow();
  const { createPlaceholderNode, createEdge } = useFlowActions();

  const handleAddNode = () => {
    setNodes((nds) => [
      ...nds.map((node) =>
        node.id === id ? { ...node, selected: false } : node
      ),
      createPlaceholderNode(data.segment, getNode(id)),
    ]);
    setEdges((eds) => [
      ...eds,
      createEdge(`segment-${data.segment.segment_id}`, "placeholder"),
    ]);
  };

  return (
    <div
      style={{
        ...NODE_STYLE,
        padding: "12px 16px 13px",
        ...(selected && {
          border: "1px solid transparent",
          boxShadow: `0 0 0 2px ${violet.violet9}, ` + NODE_STYLE.boxShadow,
        }),
      }}
    >
      <Flex gap="2" align="center">
        <Icon name="PersonIcon" color={gray.gray8} height={20} width={18} />
        <Text color="gray" size="3" weight="medium">
          {data.segment.segment_name}
        </Text>
      </Flex>
      <Handle
        type="target"
        position="top"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
      <Handle
        type="source"
        position="bottom"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
      <AnimatedNodeToolbar position="bottom">
        <Button onClick={handleAddNode} size="1">
          + Add
        </Button>
      </AnimatedNodeToolbar>
    </div>
  );
};

export default SegmentNode;

import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { DropdownMenu, Text, Flex } from "@radix-ui/themes";
import { GearIcon, ExitIcon } from "@radix-ui/react-icons";
import { grayA } from "@radix-ui/colors";
import { useAuth } from "./context/AuthContext";
import ProductLogo from "./assets/product_logo.svg";
import "./Nav.css";
import {
  BookOpen,
  Briefcase,
  Building2,
  ChartSpline,
  Goal,
  House,
  Inbox,
  MessageSquareDot,
  Radar,
  SlidersHorizontal,
  Target,
} from "lucide-react";

const UserAvatar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  return (
    <DropdownMenu.Root size="1">
      <DropdownMenu.Trigger>
        <Flex justify="center" align="center" className="user-avatar">
          <Text weight="medium">{user.email.charAt(0).toUpperCase()}</Text>
        </Flex>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content variant="soft" align="end" sideOffset={8}>
        <DropdownMenu.Label size="1">
          <Text truncate={true} title={user.email} style={{ maxWidth: 172 }}>
            {user.email}
          </Text>
        </DropdownMenu.Label>
        <DropdownMenu.Separator />
        <DropdownMenu.Item onClick={() => navigate("/org-settings")}>
          <GearIcon />
          Organization settings
        </DropdownMenu.Item>
        <DropdownMenu.Separator />
        <DropdownMenu.Item color="red" onClick={() => logout()}>
          <ExitIcon />
          Log out
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

const Link = ({ to, title, icon: Icon }) => {
  return (
    <NavLink to={to} className="nav-link">
      <Flex align="center" gap="10px" py="2" px="3">
        <Icon size={16} color={grayA.grayA9} />
        <Text size="2" weight="medium" truncate>
          {title}
        </Text>
      </Flex>
    </NavLink>
  );
};

const Nav = () => {
  const navigate = useNavigate();

  return (
    <nav>
      <Flex justify="between" align="center" pl="3" pr="1" mb="4">
        <img
          className="logo"
          src={ProductLogo}
          alt="Lateral"
          draggable={false}
          onClick={() => navigate("/")}
        />
        <UserAvatar />
      </Flex>
      <Flex direction="column" gap="1">
        {/* <Link to="/" title="Home" icon={House} /> */}
        <Link to="/accounts" title="Accounts" icon={Building2} />
        {/* <Link to="/inbox" title="Inbox" icon={Inbox} /> */}
        {/* <Link to="/playbooks" title="Playbooks" icon={BookOpen} /> */}
        {/* <Link to="/opportunities" title="Opportunities" icon={Radar} />
        <Link to="/touchpoints" title="Touchpoints" icon={MessageSquareDot} />
        <Link
          to="/mission-control"
          title="Mission Control"
          icon={SlidersHorizontal}
        /> */}
      </Flex>
    </nav>
  );
};

export default Nav;

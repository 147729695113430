import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { FlowProvider } from "./context/FlowContext";
import Flow from "./Flow";
import useFlowActions from "./hooks/useFlowActions";
import { useOpportunity } from "./context/OpportunityContext";

const OpportunityViewer = () => {
  const { id } = useParams();
  const { nodes, edges } = useFlowData(id);

  if (!nodes.length || !edges.length) return null;
  return <Flow initialNodes={nodes} initialEdges={edges} />;
};

const withProviders = (Component) => (props) => (
  <ReactFlowProvider>
    <FlowProvider>
      <Component {...props} />
    </FlowProvider>
  </ReactFlowProvider>
);

export default withProviders(OpportunityViewer);

// Custom hook for managing flow data
const useFlowData = (id) => {
  const { getOpportunity, setOpportunity } = useOpportunity();
  const flowActions = useFlowActions();
  const [flowData, setFlowData] = useState({ nodes: [], edges: [] });

  useEffect(() => {
    const fetchOpportunity = async () => {
      if (id) {
        const opportunity = await getOpportunity(id);
        setOpportunity(opportunity);

        setFlowData({
          nodes: initializeNodes(opportunity, flowActions),
          edges: initializeEdges(opportunity, flowActions),
        });
      }
    };

    fetchOpportunity();
  }, [id, getOpportunity, setOpportunity]);

  return flowData;
};

// Helper function to initialize nodes
const initializeNodes = (
  opportunity,
  { createUserCountNode, createEventGroupNode }
) => [
  createUserCountNode(),
  ...opportunity.archetypal_session.event_groups.map(
    (event_group, index, array) => {
      const isSelected =
        index === array.findIndex((group) => group.is_amenable);
      return createEventGroupNode(event_group, isSelected);
    }
  ),
];

// Helper function to initialize edges
const initializeEdges = (opportunity, { createEdge }) => {
  const edges = [];
  let previousNodeId = "userCount";

  opportunity.archetypal_session.event_groups.forEach((event_group) => {
    const currentNodeId = `eventGroup-${event_group.group_id}`;
    edges.push(createEdge(previousNodeId, currentNodeId));
    previousNodeId = currentNodeId;
  });

  return edges;
};

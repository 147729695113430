import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { BASE_URL } from "../App";

const CampaignContext = createContext();

export const useCampaign = () => useContext(CampaignContext);

export const CampaignProvider = ({ children }) => {
  const [campaign, setCampaign] = useState(null);
  const [qualificationFields, setQualificationFields] = useState([]);

  const getCampaigns = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/api/v1/campaigns`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    const { campaigns } = await response.json();

    return campaigns;
  }, []);

  const getQualificationFields = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/api/v1/qualification-fields`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const data = await response.json();

    setQualificationFields(data.fields);
  }, []);

  useEffect(() => {
    if (qualificationFields.length === 0) {
      getQualificationFields();
    }
  }, [getQualificationFields]);

  const getCampaign = useCallback(async (id) => {
    const response = await fetch(`${BASE_URL}/api/v1/campaigns/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    const data = await response.json();

    return data;
  }, []);

  const createCampaign = async (name, createDefaultSegments) => {
    const response = await fetch(`${BASE_URL}/api/v1/campaigns`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaign_name: name,
        create_default_segments: createDefaultSegments,
      }),
      credentials: "include",
    });

    const data = await response.json();

    return data;
  };

  const setCampaignEligibilityCriteria = async (campaignId, criteria) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/campaigns/${campaignId}/eligibility-criteria`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          eligibility_criteria: criteria,
        }),
        credentials: "include",
      }
    );

    const data = await response.json();

    return data;
  };

  const setCampaignRunDates = async (campaignId, startDate, endDate) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/campaigns/${campaignId}/run-dates`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          start_date: startDate,
          end_date: endDate,
        }),
        credentials: "include",
      }
    );

    const data = await response.json();

    return data;
  };

  const publishCampaign = async (campaignId) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/campaigns/${campaignId}/publish`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    const data = await response.json();

    return data;
  };

  const createCampaignSegment = async (
    campaignId,
    segmentName,
    segmentCriteria
  ) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/campaigns/${campaignId}/segments`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          segment_name: segmentName,
          segment_criteria: segmentCriteria,
        }),
        credentials: "include",
      }
    );

    const data = await response.json();

    return data;
  };

  const getCampaignSegments = useCallback(async (campaignId) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/campaigns/${campaignId}/segments`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    const data = await response.json();

    return data;
  }, []);

  const createIntervention = async (
    campaignId,
    segmentId,
    interventionLocationId,
    interventionData,
    interventionCriteria,
    interventionUrl
  ) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/campaigns/${campaignId}/segments/${segmentId}/interventions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          intervention_location_id: interventionLocationId,
          intervention_data: interventionData,
          intervention_criteria: interventionCriteria,
          intervention_url: interventionUrl,
        }),
        credentials: "include",
      }
    );

    const data = await response.json();

    return data;
  };

  const createInterventionSequence = async (campaignId, segmentId) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/campaigns/${campaignId}/segments/${segmentId}/sequences`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    const data = await response.json();

    return data;
  };

  const getInterventions = async (campaignId, segmentId) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/campaigns/${campaignId}/segments/${segmentId}/interventions`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    const data = await response.json();

    return data;
  };

  const getInterventionSequences = async (campaignId, segmentId) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/campaigns/${campaignId}/segments/${segmentId}/interventions`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    const data = await response.json();

    return data;
  };

  const setOrderInterventionsInSequence = async (
    campaignId,
    segmentId,
    sequenceId,
    orderedInterventionIds
  ) => {
    const response = await fetch(
      `${BASE_URL}/api/v1/campaigns/${campaignId}/segments/${segmentId}/sequences/${sequenceId}/interventions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          intervention_ids: orderedInterventionIds,
        }),
        credentials: "include",
      }
    );

    const data = await response.json();

    return data;
  };

  return (
    <CampaignContext.Provider
      value={{
        campaign,
        setCampaign,
        getCampaigns,
        getCampaign,
        createCampaign,
        setCampaignEligibilityCriteria,
        setCampaignRunDates,
        publishCampaign,
        createCampaignSegment,
        getCampaignSegments,
        qualificationFields,
        createIntervention,
        createInterventionSequence,
        getInterventions,
        getInterventionSequences,
        setOrderInterventionsInSequence,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

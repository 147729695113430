import React from "react";
import { Handle } from "reactflow";
import { Flex, Text } from "@radix-ui/themes";
import { gray } from "@radix-ui/colors";
import { SquareDashedMousePointer, Zap } from "lucide-react";

const eventTypeIconMap = {
  ui_interaction: SquareDashedMousePointer,
  page_load: Zap,
};

const Event = ({ event }) => {
  const Icon = eventTypeIconMap[event.event_type] || Zap;

  return (
    <Flex
      title={event.event_description}
      align="center"
      gap="6px"
      style={{
        background: gray.gray4,
        color: gray.gray9,
        borderRadius: 99,
        padding: "8px 16px",
      }}
    >
      <Icon
        color={gray.gray9}
        size={12}
        strokeWidth={1.5}
        style={{ marginBottom: 0 }}
      />
      <Text size="1" weight="medium">
        {event.event_name}
      </Text>
    </Flex>
  );
};

const EventsNode = ({ data }) => {
  return (
    <Flex>
      <Flex direction="column" align="center" gap="4">
        {data.events.map((event) => (
          <Event key={event.event_name} event={event} />
        ))}
      </Flex>
      <Handle
        type="target"
        position="top"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
      <Handle
        type="source"
        position="bottom"
        isConnectable={false}
        style={{ visibility: "hidden", bottom: 16 }}
      />
    </Flex>
  );
};

export default EventsNode;

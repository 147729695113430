import React, { useContext, useState } from "react";
import {
  Button,
  Flex,
  Text,
  Heading,
  IconButton,
  ScrollArea,
  Card,
  Checkbox,
} from "@radix-ui/themes";
import { useReactFlow } from "reactflow";
import { Cross2Icon } from "@radix-ui/react-icons";
import { gray, violet } from "@radix-ui/colors";
import { useCampaign } from "./context/CampaignContext";
import useEscapeKey from "./hooks/useEscapeKey";

const CheckboxCard = ({ checked, onClick, segment, disabled }) => {
  return (
    <Card
      asChild={!disabled}
      style={{
        paddingTop: 12,
        paddingBottom: 12,
        opacity: disabled ? 0.5 : 1,
        boxShadow: checked ? `inset 0 0 0 1px ${violet.violet9}` : "",
      }}
    >
      <Text as="label" size="2">
        <Flex align="center" justify="between">
          <Flex gap="3">
            <Checkbox checked={checked} disabled={disabled} onClick={onClick} />
            {segment.segment_name}
          </Flex>
          {!disabled && <Text color="gray">150 users</Text>}
        </Flex>
      </Text>
    </Card>
  );
};

const InputLabel = ({ label, children }) => (
  <label>
    <Flex justify="between" align="center" mb="3">
      <Text as="div" size="1" color="gray" weight="medium">
        {label}
      </Text>
    </Flex>
    {children}
  </label>
);

const Form = ({ segments, onChange, onSave, onCancel }) => {
  const [error, setError] = useState(null);

  const handleCheckboxChange = (segmentId) => {
    onChange(segmentId);
  };

  const handleSaveClick = () => {
    if (segments.every((segment) => !segment.is_enabled)) {
      setError("At least one segment must be enabled");
    } else {
      setError(null);
      onSave();
    }
  };

  return (
    <Flex direction="column" style={{ height: "100%", width: 360 }}>
      <Flex
        align="center"
        justify="between"
        px="5"
        py="4"
        style={{ borderBottom: `1px solid ${gray.gray6}` }}
      >
        <Heading size="4" weight="medium">
          Edit segments
        </Heading>
        <IconButton variant="ghost" color="gray" onClick={onCancel}>
          <Cross2Icon />
        </IconButton>
      </Flex>

      <ScrollArea scrollbars="vertical">
        <Flex
          direction="column"
          gap="6"
          p="5"
          style={{ flexGrow: 1, overflow: "scroll" }}
        >
          {error && (
            <Text color="red" size="2">
              {error}
            </Text>
          )}

          <InputLabel label="Persona">
            <Flex direction="column" gap="3">
              {segments.map((segment) => (
                <CheckboxCard
                  key={segment.segment_id}
                  checked={segment.is_enabled}
                  onClick={() => handleCheckboxChange(segment.segment_id)}
                  segment={{
                    ...segment,
                  }}
                />
              ))}
            </Flex>
          </InputLabel>

          <InputLabel label="Behavioral pattern">
            <Flex direction="column" gap="3">
              <CheckboxCard
                segment={{
                  segment_name: "Chargeback resolver",
                  segment_id: "chargeback_resolver",
                  is_enabled: false,
                }}
              />
            </Flex>
          </InputLabel>

          <InputLabel label="Custom (coming soon)">
            <Flex direction="column" gap="3">
              <CheckboxCard
                segment={{
                  segment_name: "“Users who login once per week and ...",
                  segment_id: "custom",
                  is_enabled: false,
                }}
                disabled
              />
            </Flex>
          </InputLabel>
        </Flex>
      </ScrollArea>

      <Flex
        gap="3"
        justify="end"
        px="5"
        py="4"
        style={{ borderTop: `1px solid ${gray.gray6}` }}
      >
        <Button variant="soft" color="gray" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSaveClick}>Save</Button>
      </Flex>
    </Flex>
  );
};

const EditSegmentsForm = () => {
  const { getNode, setNodes } = useReactFlow();
  const { campaign, updateSegments } = useCampaign();
  const [segments, setSegments] = useState(campaign.segments);

  const segmentRootNode = getNode("segmentRoot");

  const handleChange = (segmentId) => {
    setSegments((prevSegments) =>
      prevSegments.map((segment) =>
        segment.segment_id === segmentId
          ? { ...segment, is_enabled: !segment.is_enabled }
          : segment
      )
    );
  };

  const handleCancel = () => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === segmentRootNode.id
          ? { ...node, selected: false, data: { ...node.data, status: null } }
          : node
      )
    );
  };

  useEscapeKey(handleCancel);

  const handleSave = async () => {
    await updateSegments(campaign.campaign_id, segments);

    setNodes((nds) =>
      nds.map((node) =>
        node.id === segmentRootNode.id
          ? {
              ...node,
              selected: false,
              data: { ...node.data, status: null },
            }
          : node
      )
    );
  };

  return (
    <Form
      segments={segments}
      onChange={handleChange}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
};

export default EditSegmentsForm;

import React from "react";
import { Handle } from "reactflow";
import { Flex, Text } from "@radix-ui/themes";
import * as radixColors from "@radix-ui/colors";

const BadgeNode = ({ data: { color = "gray", label } }) => {
  const colorScale = radixColors[color];
  const badgeStyle = {
    background: colorScale[`${color}2`],
    border: `1px solid ${colorScale[`${color}7`]}`,
    borderRadius: 4,
    color: color === "gray" ? colorScale.gray9 : colorScale[`${color}11`],
    padding: "1px 4px",
  };

  return (
    <div style={badgeStyle}>
      <Flex align="center">
        <Text size="1" weight="medium">
          {label}
        </Text>
      </Flex>
      <Handle
        type="target"
        position="top"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
      <Handle
        type="source"
        position="bottom"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
    </div>
  );
};

export default BadgeNode;

import React from "react";
import * as Assets from "./assets/icons";

const Icon = ({ className, name, ...props }) => {
  // Normalize the icon name
  const normalizedName =
    name.replace(/^(.)/, (match) => match.toUpperCase()).replace(/Icon$/, "") +
    "Icon";
  const SvgIcon = Assets[normalizedName];

  if (!SvgIcon) {
    console.warn(`Icon "${name}" not found in /assets/icons`);
    return null;
  }

  return <SvgIcon className={className} {...props} />;
};

export default Icon;

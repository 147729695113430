import React from "react";
import { Flex, Select, TextField, Button, Text } from "@radix-ui/themes";
import { gray } from "@radix-ui/colors";
import { Bolt } from "lucide-react";
import { useFlow } from "./context/FlowContext";

const ConditionRow = ({
  allFields,
  condition,
  onRemove,
  onUpdate,
  onOpenChange,
}) => {
  const field = allFields.find((f) => f.field === condition.attribute);

  const renderValueInput = () => {
    switch (field?.type) {
      case "boolean":
        return (
          <Select.Root
            value={condition.value}
            onValueChange={(newValue) =>
              onUpdate(condition, { value: newValue })
            }
            onOpenChange={onOpenChange}
          >
            <Select.Trigger style={{ width: 152 }} />
            <Select.Content position="popper">
              <Select.Item value={true}>True</Select.Item>
              <Select.Item value={false}>False</Select.Item>
            </Select.Content>
          </Select.Root>
        );
      case "number":
        return (
          <TextField.Root
            type="number"
            value={condition.value}
            onChange={(e) =>
              onUpdate(condition, { value: Number(e.target.value) })
            }
            style={{ width: 152 }}
          />
        );
      default:
        return (
          <TextField.Root
            value={condition.value}
            onChange={(e) => onUpdate(condition, { value: e.target.value })}
            style={{ width: 152 }}
          />
        );
    }
  };

  const getOperatorOptions = () => {
    const type = field?.type;
    const options = {
      boolean: [
        { value: "=", label: "is" },
        { value: "!=", label: "isn't" },
      ],
      number: [
        { value: "=", label: "is" },
        { value: "!=", label: "isn't" },
        { value: "<", label: "less than" },
        { value: ">", label: "greater than" },
        { value: "<=", label: "less than or equal to" },
        { value: ">=", label: "greater than or equal to" },
      ],
      string: [
        { value: "=", label: "is" },
        { value: "!=", label: "isn't" },
      ],
    };

    return (
      <Select.Content position="popper">
        {(options[type] || options.string).map(({ value, label }) => (
          <Select.Item key={value} value={value}>
            {value}
          </Select.Item>
        ))}
      </Select.Content>
    );
  };

  return (
    <Flex align="center" gap="2" mb="2" width="100%">
      <Select.Root
        value={condition.attribute}
        onValueChange={(value) => {
          onUpdate(condition, { attribute: value, value: "" });
        }}
        onOpenChange={onOpenChange}
      >
        <Select.Trigger style={{ width: 152 }}>
          <Flex align="center" gap="2">
            <Bolt color={gray.gray9} size={16} strokeWidth={1.5} />
            <Text>{condition.attribute}</Text>
          </Flex>
        </Select.Trigger>
        <Select.Content position="popper">
          {allFields.map((field) => (
            <Select.Item key={field.field} value={field.field}>
              {field.field}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>

      <Select.Root
        value={condition.operator || (condition.attribute && "=")}
        onValueChange={(value) => onUpdate(condition, { operator: value })}
        onOpenChange={onOpenChange}
      >
        <Select.Trigger style={{ width: 96 }} />
        {getOperatorOptions()}
      </Select.Root>
      {renderValueInput()}

      <Button
        color="gray"
        variant="ghost"
        onClick={() => onRemove(condition)}
        ml="1"
      >
        ✕
      </Button>
    </Flex>
  );
};

const ConditionBuilder = ({ conditions, onChange }) => {
  const { toggleZoomable } = useFlow();
  const qualificationFields = [{ field: "session_duration", type: "number" }]; // TODO: get from backend

  const handleAddCondition = () => {
    onChange([...conditions, { attribute: "", operator: "", value: "" }]);
  };

  const handleUpdateCondition = (oldCondition, updatedFields) => {
    onChange(
      conditions.map((condition) =>
        JSON.stringify(condition) === JSON.stringify(oldCondition)
          ? { ...condition, ...updatedFields }
          : condition
      )
    );
  };

  const handleRemoveCondition = (conditionToRemove) => {
    onChange(
      conditions.filter(
        (condition) =>
          JSON.stringify(condition) !== JSON.stringify(conditionToRemove)
      )
    );
  };

  return (
    <Flex direction="column" align="start">
      {conditions.map((condition, i) => (
        <ConditionRow
          key={i}
          allFields={qualificationFields}
          condition={condition}
          onRemove={handleRemoveCondition}
          onUpdate={handleUpdateCondition}
          onOpenChange={toggleZoomable}
        />
      ))}
      <Button
        size="2"
        variant="ghost"
        disabled={
          conditions.length &&
          !conditions.every((c) => c.attribute && c.operator && String(c.value))
        }
        onClick={handleAddCondition}
        mt="1"
      >
        + Add condition
      </Button>
    </Flex>
  );
};

export default ConditionBuilder;

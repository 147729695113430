import React, { useState, useEffect } from "react";
import Markdown from "react-markdown";
import {
  Text,
  Flex,
  Button,
  ScrollArea,
  Avatar,
  IconButton,
  Heading,
  Separator,
  Badge,
  TextArea,
  Popover,
  TextField,
  Link,
  Grid,
  Table,
} from "@radix-ui/themes";
import {
  amber,
  blue,
  cyan,
  gray,
  grayA,
  green,
  pink,
  red,
  violet,
} from "@radix-ui/colors";
import {
  Search,
  Send,
  Edit,
  ListFilter,
  Activity,
  Route,
  MessagesSquare,
  KeyRound,
  UserSquare2,
  Radar,
  Save,
  Pencil,
  ChevronDown,
  Copy,
  Mail,
  ExternalLink,
  Clipboard,
  ClipboardCheck,
  ClipboardCopy,
  CircleCheckBig,
  FlagTriangleLeft,
  CircleGauge,
  Building,
  Building2,
  Clock,
  TriangleAlert,
  MessageCircleOff,
  Blend,
  RefreshCw,
  Blocks,
  Repeat,
  ArrowRight,
  Gauge,
  BadgeDollarSign,
  TicketCheck,
  CheckCircle2,
  RefreshCcw,
  BarChart2,
  TrendingDown,
  TrendingUp,
  InboxIcon,
  ArrowDown,
  EyeOff,
  EllipsisVertical,
  Sparkles,
  Bolt,
  Zap,
  LifeBuoy,
  FileText,
  GraduationCap,
  Video,
  Calendar,
  Users,
  Ticket,
  BookOpenText,
  Timer,
  PiggyBank,
  ChartNoAxesCombined,
  SquareActivity,
  Receipt,
  CalendarX,
  CalendarRange,
  CalendarFold,
  Headset,
  Telescope,
  Siren,
  CirclePlay,
} from "lucide-react";
import EmailIcon from "./assets/email.svg";
import EmailSentIcon from "./assets/email-sent.svg";
import MeetingIcon from "./assets/meeting.svg";
import MeetingCompleted from "./assets/meeting-completed.svg";
import OpeningIcon from "./assets/opening.svg";

import { abbrevNum, toTimeAgo } from "./utils";
import "./Inbox.css";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { useParams, useNavigate } from "react-router-dom";

const Attribute = ({ icon: Icon, text }) => (
  <Flex align="center" gap="5px">
    <Icon size={12} color={grayA.grayA9} />
    <Text size="1" style={{ color: grayA.grayA10 }}>
      {text}
    </Text>
  </Flex>
);

const ListHeader = ({ title, count }) => (
  <Flex
    px="4"
    py="3"
    align="center"
    justify="between"
    style={{
      borderBottom: `1px solid ${grayA.grayA4}`,
    }}
  >
    <Flex gap="2" align="center">
      <InboxIcon size={20} strokeWidth={1.75} color={gray.gray8} />
      <Text size="3" weight="medium">
        {title}
      </Text>
      {/* {count && (
        <Flex
          align="center"
          px="6px"
          py="2px"
          style={{ borderRadius: 6, backgroundColor: gray.gray3 }}
        >
          <Text size="1" weight="medium" style={{ color: grayA.grayA11 }}>
            {count}
          </Text>
        </Flex>
      )} */}
    </Flex>
    <Flex gap="2">
      <Flex
        align="center"
        gap="4px"
        pl="2"
        pr="6px"
        style={{
          borderRadius: 8,
          border: `1px solid ${grayA.grayA4}`,
          cursor: "default",
          height: 28,
        }}
      >
        <Calendar size={14} strokeWidth={1.85} color={grayA.grayA8} />
        <Text
          size="1"
          weight="medium"
          style={{ color: grayA.grayA10, marginLeft: 2 }}
        >
          Today
        </Text>
        <ChevronDown size={12} strokeWidth={2} color={grayA.grayA8} />
      </Flex>
      <IconButton
        variant="outline"
        size="2"
        color="gray"
        style={{
          borderRadius: 8,
          border: `1px solid ${grayA.grayA4}`,
          boxShadow: "none",
          height: 28,
          width: 28,
        }}
      >
        <ListFilter size={14} strokeWidth={1.75} color={grayA.grayA9} />
      </IconButton>
      <IconButton
        variant="outline"
        size="2"
        color="gray"
        style={{
          borderRadius: 8,
          border: `1px solid ${grayA.grayA4}`,
          boxShadow: "none",
          height: 28,
          width: 28,
        }}
      >
        <Search size={14} strokeWidth={1.75} color={grayA.grayA9} />
      </IconButton>
    </Flex>
  </Flex>
);

const ListSectionTitle = ({ title }) => (
  <Flex
    direction="column"
    gap="4"
    px="4"
    py="1"
    style={{
      borderBottom: `1px solid ${grayA.grayA4}`,
      backgroundColor: gray.gray2,
      userSelect: "none",
    }}
  >
    <Text size="1" weight="medium" style={{ color: grayA.grayA10 }}>
      {title}
    </Text>
  </Flex>
);

const ListItem = ({ item, isSelected, onClick }) => (
  <Flex direction="column">
    <Flex
      p="4"
      mx="1"
      gap="3"
      align="center"
      onClick={onClick}
      className={`list-item ${isSelected ? "selected" : ""} ${
        item.item_status === "unread" ? "unread" : ""
      }`}
      style={{
        borderRadius: 8,
        cursor: "default",
        flex: 1,
        position: "relative",
        userSelect: "none",
      }}
    >
      {item.item_status === "unread" && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
            width: 3,
            height: "50%",
            backgroundColor: blue.blue9,
            borderRadius: "0 2px 2px 0",
          }}
        />
      )}
      {item.item_type === "meeting" ? (
        <Flex
          style={{
            backgroundColor: "white",
            border: `1px solid ${grayA.grayA4}`,
            padding: 6,
            borderRadius: 6,
          }}
        >
          <img src={MeetingIcon} alt="Meeting" />
        </Flex>
      ) : item.item_type === "follow_up" ? (
        <Flex
          style={{
            backgroundColor: "white",
            border: `1px solid ${grayA.grayA4}`,
            padding: 6,
            borderRadius: 6,
          }}
        >
          <img src={MeetingCompleted} alt="Meeting" />
        </Flex>
      ) : (
        <Avatar
          size="1"
          radius="large"
          src={item.item_user.user_organization.organization_avatar}
          style={{
            backgroundColor: "white",
            border: `1px solid ${grayA.grayA4}`,
            padding: 3,
          }}
        />
      )}
      <Flex direction="column" style={{ flex: 1 }}>
        <Flex justify="between" align="center">
          <Flex gap="2" align="center">
            <Text size="1" style={{ color: grayA.grayA9 }}>
              {item.item_user.user_name} •{" "}
              {item.item_user.user_organization.organization_name}
            </Text>
          </Flex>
          <Text size="1" style={{ color: grayA.grayA8 }}>
            {item.item_type === "meeting"
              ? new Date(item.starts_at).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : item.item_type === "follow_up"
                ? toTimeAgo(item.starts_at, true)
                : toTimeAgo(item.created_at, true)}
          </Text>
        </Flex>
        <Text size="2" weight="medium" truncate style={{ maxWidth: 196 }}>
          {item.item_title}
        </Text>
      </Flex>
    </Flex>
    <Separator
      size="4"
      style={{
        width: "92%",
        opacity: 0.3,
        alignSelf: "center",
      }}
    />
  </Flex>
);

const HeaderNavigation = ({ items, selectedItemId, setSelectedItemId }) => {
  const currentIndex = items.findIndex((s) => s.item_id === selectedItemId);

  const handleNavigation = (direction) => {
    if (direction === "up" && currentIndex > 0) {
      const newSelectedId = items[currentIndex - 1].item_id;
      setSelectedItemId(newSelectedId);
    } else if (direction === "down" && currentIndex < items.length - 1) {
      const newSelectedId = items[currentIndex + 1].item_id;
      setSelectedItemId(newSelectedId);
    }
  };

  return (
    <Flex align="center" gap="5">
      <Text size="2" style={{ color: grayA.grayA10 }}>
        {items.length > 0 ? currentIndex + 1 : 0}{" "}
        <span style={{ color: grayA.grayA6 }}>/</span>{" "}
        <span style={{ color: grayA.grayA8 }}>{items.length}</span>
      </Text>
      <Flex gap="14px">
        <IconButton
          variant="ghost"
          color="gray"
          size="1"
          disabled={currentIndex <= 0}
          onClick={() => handleNavigation("up")}
          style={{
            borderRadius: 8,
            border: `1px solid ${grayA.grayA4}`,
            cursor: currentIndex <= 0 ? "not-allowed" : "pointer",
            opacity: currentIndex <= 0 ? 0.6 : 1,
            padding: 3,
          }}
        >
          <ChevronDown
            size={16}
            style={{ transform: "rotate(180deg)" }}
            color={grayA.grayA9}
          />
        </IconButton>
        <IconButton
          variant="ghost"
          color="gray"
          size="1"
          disabled={currentIndex >= items.length - 1}
          onClick={() => handleNavigation("down")}
          style={{
            borderRadius: 8,
            border: `1px solid ${grayA.grayA4}`,
            cursor:
              currentIndex >= items.length - 1 ? "not-allowed" : "pointer",
            opacity: currentIndex >= items.length - 1 ? 0.6 : 1,
            padding: 3,
          }}
        >
          <ChevronDown size={16} color={grayA.grayA9} />
        </IconButton>
      </Flex>
    </Flex>
  );
};

const MainHeader = ({ type, items, selectedItemId, setSelectedItemId }) => {
  const types = {
    meeting: { title: "Meeting", color: "#FFEFD6", icon: MeetingIcon },
    follow_up: {
      title: "Meeting follow-up",
      color: "#E9F9EE",
      icon: MeetingCompleted,
    },
    outreach: {
      title: "Account opening",
      color: "#FEE9F5",
      icon: OpeningIcon,
    },
  };
  const { title, color, icon } = types[type];

  return (
    <Flex
      align="center"
      justify="between"
      px="5"
      style={{ minHeight: 53, borderBottom: `1px solid ${grayA.grayA4}` }}
    >
      <Flex align="center" gap="2">
        <Flex p="1" style={{ backgroundColor: color, borderRadius: 6 }}>
          <img src={icon} alt={title} />
        </Flex>
        <Text size="2" weight="medium" style={{ color: grayA.grayA10 }}>
          {title}
        </Text>
      </Flex>
      <HeaderNavigation
        items={items}
        selectedItemId={selectedItemId}
        setSelectedItemId={setSelectedItemId}
      />
    </Flex>
  );
};

const SectionTitle = ({ title, icon: Icon, description }) => (
  <Flex
    align="center"
    gap="4"
    pb="2"
    px="2"
    style={{ borderBottom: `1px solid ${grayA.grayA4}`, margin: "0 -8px" }}
  >
    <Flex gap="2" align="center">
      {Icon && <Icon size={16} color={violet.violet11} />}
      <Text size="3" weight="medium" style={{ color: violet.violet11 }}>
        {title}
      </Text>
    </Flex>
    {description && (
      <Text size="2" style={{ color: grayA.grayA9 }}>
        {description}
      </Text>
    )}
  </Flex>
);

const OpeningContent = ({
  opening,
  isEditing,
  setIsEditing,
  onEmailUpdate,
}) => (
  <Flex direction="column">
    {/* Details */}
    <Flex justify="between" align="center" mt="4" mb="5">
      <Flex align="center" gap="2">
        <Avatar
          radius="none"
          src={opening.item_user.user_organization.organization_avatar}
          style={{
            width: 18,
            height: 18,
            border: `1px solid ${grayA.grayA4}`,
            padding: 2,
            borderRadius: 4,
          }}
        />
        <Text size="2" style={{ color: grayA.grayA9 }}>
          {opening.item_user.user_organization.organization_name}
        </Text>
      </Flex>
    </Flex>

    {/* Header + body */}
    <Flex direction="column" gap="7">
      <Flex direction="column" gap="4">
        <Heading weight="medium" size="8">
          {opening.item_title}
        </Heading>
        <Flex gap="3">
          <Button size="1" radius="large" style={{ height: 26 }}>
            <ArrowDown size={12} />
            Review & send email
          </Button>
          <Button
            size="1"
            radius="large"
            variant="outline"
            color="gray"
            style={{ opacity: 0.8, height: 26 }}
          >
            <EyeOff size={12} />
            Ignore
          </Button>
        </Flex>
      </Flex>
      <Flex direction="column" gap="5" mb="7">
        <SectionTitle title="Summary" icon={Sparkles} />
        <Flex direction="column" gap="2" mb="2">
          <Text size="3" weight="medium" style={{ color: grayA.grayA11 }}>
            Situation
          </Text>
          <Text size="3" style={{ color: grayA.grayA10 }}>
            <Markdown className="markdown-content">
              {opening.item_details.summary}
            </Markdown>
          </Text>
        </Flex>
        <Flex direction="column" gap="2">
          <Text size="3" weight="medium" style={{ color: grayA.grayA11 }}>
            Relevant activity
          </Text>
          <Text size="3" style={{ color: grayA.grayA10 }}>
            <ul style={{ padding: 0, margin: 0, marginLeft: 24 }}>
              {opening.item_details.supporting_data.map((detail) => (
                <li key={detail}>
                  <Markdown className="markdown-content">{detail}</Markdown>
                </li>
              ))}
            </ul>
          </Text>
        </Flex>
      </Flex>
    </Flex>

    <SectionTitle
      title="Suggested action"
      icon={Zap}
      description="Send an email offering to answer questions or connect them with an expert"
    />

    {/* Email preview */}
    <EmailPreview
      email={opening.item_details.draft_email}
      isEmailSent={opening.item_status === "completed"}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      onSave={onEmailUpdate}
      style={{ marginTop: 24 }}
    />
  </Flex>
);

const EmailPreview = ({
  email,
  isEmailSent,
  isEditing,
  setIsEditing,
  onSave,
  style,
}) => {
  const [editedSubject, setEditedSubject] = useState(email.email_subject);
  const [editedBody, setEditedBody] = useState(email.email_body);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setEditedSubject(email.email_subject);
    setEditedBody(email.email_body);
  }, [email]);

  const handleSave = () => {
    onSave({
      email_subject: editedSubject,
      email_body: editedBody,
    });
  };

  const handleCancel = () => {
    setEditedSubject(email.email_subject);
    setEditedBody(email.email_body);
    setIsEditing(false);
  };

  const handleMarkAsSent = () => {
    onSave({
      item_status: "completed",
    });
  };

  const EmailClientButton = ({
    icon: Icon,
    label,
    color = gray.gray11,
    externalLink = true,
    disabled,
    onClick,
  }) => (
    <Button
      variant="ghost"
      size="2"
      radius="large"
      style={{
        padding: "8px 12px",
        color,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Flex align="center" justify="between" style={{ width: "100%" }}>
        <Flex align="center" gap="2">
          <Icon size={15} strokeWidth={1.5} />
          <Text size="2" weight="medium">
            {label}
          </Text>
        </Flex>
        {externalLink && (
          <ExternalLink size={14} strokeWidth={1.5} color={grayA.grayA8} />
        )}
      </Flex>
    </Button>
  );

  return (
    <Flex
      direction="column"
      style={{
        border: `1px solid ${grayA.grayA4}`,
        borderRadius: 12,
        overflow: "hidden",
        ...style,
      }}
    >
      <Flex
        justify="between"
        align="center"
        px="4"
        py="3"
        style={{
          borderBottom: `1px solid ${grayA.grayA4}`,
        }}
      >
        <Flex align="center" gap="3">
          <img
            src={isEmailSent ? EmailSentIcon : EmailIcon}
            alt="Email"
            style={{
              width: 22,
              height: 22,
            }}
          />
          <Text
            size="2"
            weight="medium"
            style={isEmailSent && { color: green.green11 }}
          >
            {isEmailSent ? "Email sent" : "Email to send"}
          </Text>
          {isEmailSent && (
            <Text size="1" style={{ color: grayA.grayA9 }}>
              2m ago
            </Text>
          )}
          {isEditing && (
            <Badge size="1" color="blue">
              Editing
            </Badge>
          )}
        </Flex>

        <Flex gap="2">
          {isEmailSent ? (
            <Badge
              size="1"
              color="gray"
              variant="outline"
              radius="full"
              style={{ opacity: 0.6 }}
            >
              <RefreshCw size={12} style={{ marginLeft: 2 }} />
              Synced with SFDC
            </Badge>
          ) : (
            <>
              {isEditing ? (
                <>
                  <Button
                    variant="soft"
                    size="1"
                    color="gray"
                    radius="large"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="1"
                    radius="large"
                    variant="soft"
                    onClick={handleSave}
                  >
                    <Save size={12} style={{ marginRight: 2 }} />
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="soft"
                    size="1"
                    color="gray"
                    radius="large"
                    onClick={() => setIsEditing(true)}
                  >
                    <Edit size={12} style={{ marginRight: 2 }} />
                    Edit
                  </Button>
                  <Popover.Root>
                    <Popover.Trigger asChild>
                      <Button size="1" radius="large" variant="soft">
                        <Send size={12} style={{ marginRight: 2 }} />
                        Send
                        <ChevronDown size={12} style={{ marginLeft: 0 }} />
                      </Button>
                    </Popover.Trigger>

                    <Popover.Content
                      align="end"
                      radius="large"
                      size="1"
                      style={{
                        width: 248,
                      }}
                    >
                      <Flex direction="column" gap="3">
                        <Flex
                          direction="column"
                          gap="2px"
                          p="3"
                          style={{
                            backgroundColor: gray.gray2,
                            borderRadius: 8,
                          }}
                        >
                          <Text size="2" weight="medium">
                            Send email
                          </Text>
                          <Text size="1" style={{ color: grayA.grayA11 }}>
                            Choose your preferred email client
                          </Text>
                        </Flex>

                        <Flex direction="column" gap="2" px="1">
                          <EmailClientButton
                            icon={Mail}
                            label="Open in Gmail"
                            color={red.red11}
                            onClick={() =>
                              window.open(
                                `https://mail.google.com/mail/?view=cm&fs=1&to=${email.email_to}&su=${encodeURIComponent(email.email_subject)}&body=${encodeURIComponent(email.email_body)}`
                              )
                            }
                          />
                          <EmailClientButton
                            icon={Mail}
                            label="Open in Outlook"
                            color={blue.blue11}
                            onClick={() =>
                              window.open(
                                `ms-outlook://compose?to=${email.email_to}&subject=${encodeURIComponent(email.email_subject)}&body=${encodeURIComponent(email.email_body)}`
                              )
                            }
                          />
                          <EmailClientButton
                            icon={Mail}
                            label="Open in default client"
                            onClick={() =>
                              window.open(
                                `mailto:${email.email_to}?subject=${encodeURIComponent(email.email_subject)}&body=${encodeURIComponent(email.email_body)}`
                              )
                            }
                          />

                          <Separator size="4" my="1" style={{ opacity: 0.3 }} />

                          <EmailClientButton
                            icon={copied ? ClipboardCheck : ClipboardCopy}
                            externalLink={false}
                            label={copied ? "Copied!" : "Copy to clipboard"}
                            color={copied ? green.green11 : gray.gray11}
                            disabled={copied}
                            onClick={() => {
                              navigator.clipboard.writeText(email.email_body);
                              setCopied(true);
                              setTimeout(() => setCopied(false), 2000);
                            }}
                          />

                          <Separator size="4" my="1" style={{ opacity: 0.3 }} />

                          <Button
                            size="2"
                            radius="large"
                            variant="soft"
                            color="green"
                            onClick={handleMarkAsSent}
                          >
                            <CircleCheckBig size={12} />
                            Mark as sent
                          </Button>
                        </Flex>
                      </Flex>
                    </Popover.Content>
                  </Popover.Root>
                </>
              )}
            </>
          )}
        </Flex>
      </Flex>

      <Flex direction="column" gap="5" p="5" pt="4">
        <Flex
          direction="column"
          gap="3"
          pb="4"
          style={{ borderBottom: `1px solid ${grayA.grayA4}` }}
        >
          {isEditing ? (
            <TextField.Root
              value={editedSubject}
              onChange={(e) => setEditedSubject(e.target.value)}
              size="2"
              radius="large"
            />
          ) : (
            <Text size="3" weight="medium">
              {email.email_subject}
            </Text>
          )}
          <Flex direction="column" gap="1" style={{ color: grayA.grayA9 }}>
            <Text size="1">To: {email.email_to}</Text>
            {email.email_cc && <Text size="1">CC: {email.email_cc}</Text>}
          </Flex>
        </Flex>

        <Flex
          direction="column"
          gap="4"
          style={{
            color: gray.gray11,
            lineHeight: "1.5",
          }}
        >
          {isEditing ? (
            <TextArea
              value={editedBody}
              onChange={(e) => setEditedBody(e.target.value)}
              size="2"
              radius="large"
              style={{
                minHeight: "280px",
              }}
            />
          ) : (
            <Text size="2" style={{ whiteSpace: "pre-wrap" }}>
              {email.email_body}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const DetailSection = ({ title, icon: Icon, children }) => (
  <Flex direction="column" gap="2" className="detail-section">
    <Flex align="center" gap="2">
      <Icon size={14} strokeWidth={1.75} color={grayA.grayA9} />
      <Text size="1" weight="medium" style={{ color: gray.gray11 }}>
        {title}
      </Text>
    </Flex>
    {children}
  </Flex>
);

// Define the CollapsibleSection component
const CollapsibleSection = ({
  title,
  icon: Icon,
  children,
  defaultCollapsed = true,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  return (
    <Flex direction="column" gap="2">
      <Flex align="center" gap="2" onClick={() => setIsCollapsed(!isCollapsed)}>
        <Icon size={14} strokeWidth={1.75} color={grayA.grayA9} />
        <Text
          size="1"
          weight="medium"
          style={{ color: gray.gray11, userSelect: "none" }}
        >
          {title}
        </Text>
        <ChevronDown
          size={14}
          style={{
            marginLeft: "auto",
            color: grayA.grayA9,
            transform: isCollapsed ? "rotate(-90deg)" : "rotate(0deg)",
          }}
        />
      </Flex>
      {!isCollapsed && (
        <Flex direction="column" gap="4" pt="2">
          {children}
        </Flex>
      )}
    </Flex>
  );
};

// Update the UserDetails component
const UserDetails = ({ user, metrics }) => (
  <Flex
    direction="column"
    gap="5"
    px="20px"
    pt="20px"
    pb="5"
    style={{ borderBottom: `1px solid ${grayA.grayA4}` }}
  >
    {/* User Header - Name, Email, Avatar */}
    <Flex direction="column" gap="3">
      <Flex align="center" gap="4">
        <Avatar
          size="3"
          radius="full"
          src={user.user_avatar}
          style={{ border: `1px solid ${grayA.grayA4}` }}
        />
        <Flex direction="column">
          <Text size="3" weight="medium">
            {user.user_name}
          </Text>
          <Text size="1" style={{ color: grayA.grayA9, marginTop: -2 }}>
            {user.user_email}
          </Text>
        </Flex>
      </Flex>
    </Flex>

    {/* Collapsible Section */}
    <CollapsibleSection title="User Activity" icon={Activity}>
      <Flex
        gap="3"
        align="center"
        px="4"
        py="6px"
        style={{
          backgroundColor: gray.gray2,
          borderRadius: 8,
          margin: "0 -8px",
        }}
      >
        <Attribute icon={UserSquare2} text={user.user_role} />
        <Attribute
          icon={KeyRound}
          text={`Logged in ${toTimeAgo(user.user_metrics.last_login, true)}`}
        />
      </Flex>

      <DetailSection title="Recent activity" icon={Activity}>
        <Text size="1">
          <ul>
            {metrics.recent_activity.map((activity, i) => (
              <li key={i}>{activity}</li>
            ))}
          </ul>
        </Text>
      </DetailSection>

      <DetailSection title="Average session" icon={Route}>
        <Text size="1">
          <ul>
            {metrics.average_session.map((session, i) => (
              <li key={i}>{session}</li>
            ))}
          </ul>
        </Text>
      </DetailSection>

      <DetailSection title="Interactions with you" icon={MessagesSquare}>
        <Text size="1">
          <ul>
            {metrics.recent_interactions.map((interaction, i) => (
              <li key={i}>
                {interaction.item_title}{" "}
                <span style={{ color: grayA.grayA8 }}>
                  ({interaction.created_at})
                </span>
              </li>
            ))}
          </ul>
        </Text>
      </DetailSection>
    </CollapsibleSection>
  </Flex>
);

const DetailsHeader = () => (
  <Flex
    justify="between"
    align="center"
    py="3"
    px="4"
    style={{ borderBottom: `1px solid ${grayA.grayA4}`, minHeight: 53 }}
  >
    <Text size="2" weight="medium" style={{ color: grayA.grayA10 }}>
      Details
    </Text>
    <IconButton
      size="1"
      radius="large"
      variant="outline"
      color="gray"
      style={{
        padding: 3,
        borderRadius: 8,
        boxShadow: "none",
        border: `1px solid ${grayA.grayA4}`,
      }}
    >
      <EllipsisVertical size={16} color={grayA.grayA9} />
    </IconButton>
  </Flex>
);

const OpeningDetails = ({ opening }) => (
  <Flex
    direction="column"
    gap="4"
    p="20px"
    style={{ borderBottom: `1px solid ${grayA.grayA4}` }}
  >
    {opening.item_status === "completed" ? (
      <Badge size="1" color="green" style={{ width: "fit-content" }}>
        <CircleCheckBig size={12} />
        Completed
      </Badge>
    ) : (
      <Badge size="1" color="gray" style={{ width: "fit-content" }}>
        Active
      </Badge>
    )}
    <Flex gap="2" align="center">
      <Clock size={14} strokeWidth={1.75} color={grayA.grayA8} />
      <Text size="1" style={{ color: grayA.grayA9 }}>
        {toTimeAgo(opening.created_at)}
      </Text>
    </Flex>
    <Flex gap="2" align="center">
      <Avatar
        radius="full"
        src={opening.item_user.user_avatar}
        style={{
          width: 16,
          height: 16,
          border: `1px solid ${grayA.grayA4}`,
          borderRadius: 999,
        }}
      />
      <Text size="1" style={{ color: grayA.grayA9 }}>
        {opening.item_user.user_name}
      </Text>
    </Flex>
  </Flex>
);

const AccountDetails = ({ opening }) => {
  const account = opening.item_user.user_organization;

  return (
    <Flex direction="column" gap="4" p="4">
      {/* <Text
        weight="medium"
        style={{
          color: grayA.grayA10,
          textTransform: "uppercase",
          fontSize: 10,
        }}
      >
        Account details
      </Text> */}

      <Flex align="center" justify="between" style={{ width: "100%" }}>
        <Flex align="center" gap="3">
          <Avatar
            size="2"
            radius="large"
            src={account.organization_avatar}
            style={{ border: `1px solid ${grayA.grayA4}`, padding: 6 }}
          />
          <Flex direction="column">
            <Text size="3" weight="medium">
              {account.organization_name}
            </Text>
            <Text style={{ color: grayA.grayA9, fontSize: 10, marginTop: -2 }}>
              ${abbrevNum(account.organization_value)} ARR
            </Text>
          </Flex>
        </Flex>
        <Button
          variant="outline"
          size="1"
          color="gray"
          radius="large"
          style={{ opacity: 0.7 }}
        >
          Open
          <ExternalLink size={12} strokeWidth={1.5} />
        </Button>
      </Flex>

      <Flex
        gap="2"
        px="3"
        py="6px"
        align="center"
        style={{
          backgroundColor: gray.gray2,
          borderRadius: 8,
          margin: "0 -8px 2px",
        }}
      >
        <RefreshCcw size={12} strokeWidth={1.75} color={gray.gray11} />
        <Text size="1" weight="medium" style={{ color: gray.gray11 }}>
          Entitlements synced
        </Text>
        <ArrowRight
          size={12}
          strokeWidth={1.75}
          color={gray.gray11}
          style={{ marginLeft: "auto", opacity: 0.8 }}
        />
      </Flex>
    </Flex>
  );
};

const RevenueHealth = ({ opening }) => {
  const account = opening.item_user.user_organization;

  const getDaysUntilRenewal = () => {
    if (!account.renewal_date) return null;

    const today = new Date();
    const renewal = new Date(account.renewal_date);
    const diffTime = renewal - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const daysUntilRenewal = getDaysUntilRenewal();

  return (
    <Flex
      direction="column"
      gap="4"
      p="4"
      style={{
        borderTop: `1px solid ${grayA.grayA4}`,
        borderBottom: `1px solid ${grayA.grayA4}`,
      }}
    >
      <Text
        weight="medium"
        style={{
          color: grayA.grayA11,
          textTransform: "uppercase",
          fontSize: 11,
        }}
      >
        Revenue health
      </Text>

      <Flex direction="column" gap="2">
        {account.organization_entitlement === "under" && (
          <Flex
            gap="2"
            px="3"
            py="2"
            align="center"
            style={{
              backgroundColor: amber.amber2,
              borderRadius: 8,
              margin: "0 -8px 2px",
            }}
          >
            <TriangleAlert size={14} strokeWidth={1.75} color={amber.amber11} />
            <Text size="1" weight="medium" style={{ color: amber.amber11 }}>
              Churn risk: usage below threshold
            </Text>
          </Flex>
        )}

        {account.organization_entitlement === "over" && (
          <Flex
            gap="2"
            px="3"
            py="2"
            align="center"
            style={{
              backgroundColor: blue.blue2,
              borderRadius: 8,
              margin: "0 -8px 2px",
            }}
          >
            <Gauge size={14} strokeWidth={1.75} color={blue.blue11} />
            <Text size="1" weight="medium" style={{ color: blue.blue11 }}>
              Expansion opportunity: over-usage
            </Text>
          </Flex>
        )}

        {daysUntilRenewal !== null && daysUntilRenewal <= 90 && (
          <Flex
            gap="2"
            px="3"
            py="6px"
            align="center"
            style={{
              backgroundColor: gray.gray2,
              borderRadius: 8,
              margin: "0 -8px 2px",
            }}
          >
            <Repeat size={12} strokeWidth={1.5} color={gray.gray11} />
            <Text size="1" weight="medium" style={{ color: gray.gray11 }}>
              Renewal coming up in {daysUntilRenewal} days
            </Text>
          </Flex>
        )}
      </Flex>

      {opening.item_details.retention_risks.length > 0 && (
        <DetailSection title="Retention risks" icon={TrendingDown}>
          <Text size="1">
            <ul>
              {opening.item_details.retention_risks.map((risk, i) => (
                <li key={i}>{risk}</li>
              ))}
            </ul>
          </Text>
        </DetailSection>
      )}

      {opening.item_details.growth_opportunities.length > 0 && (
        <DetailSection title="Growth opportunities" icon={TrendingUp}>
          <Text size="1">
            <ul>
              {opening.item_details.growth_opportunities.map(
                (opportunity, i) => (
                  <li
                    key={i}
                    style={
                      typeof opportunity === "object"
                        ? { listStyle: "circle" }
                        : undefined
                    }
                  >
                    {typeof opportunity === "string"
                      ? opportunity
                      : `Recommended: ${opportunity.recommendation}`}
                  </li>
                )
              )}
            </ul>
          </Text>
        </DetailSection>
      )}

      <Link href="" size="1" weight="medium">
        <Flex align="center" gap="1">
          See all insights
          <ArrowRight size={12} strokeWidth={2} />
        </Flex>
      </Link>
    </Flex>
  );
};

const MeetingResource = ({ resource }) => {
  const types = {
    support: {
      icon: Ticket,
      color: cyan.cyan8,
      text: "Zendesk",
    },
    docs: {
      icon: BookOpenText,
      color: green.green8,
      text: "Developer docs",
    },
    enablement: {
      icon: FileText,
      color: pink.pink8,
      text: "Enablement material",
    },
  };

  const Icon = types[resource.type].icon;

  return (
    <Link>
      <Flex
        align="center"
        gap="2"
        py="2"
        px="3"
        style={{
          borderRadius: 10,
          border: `1px solid ${grayA.grayA3}`,
          cursor: "default",
        }}
      >
        <Flex direction="column" style={{ flex: 1 }}>
          <Text
            weight="medium"
            size="2"
            truncate
            style={{ color: grayA.grayA12, maxWidth: 188 }}
          >
            {resource.title}
          </Text>
          <Flex gap="1" align="center">
            <Icon size={12} color={types[resource.type].color} />
            <Text size="1" style={{ color: grayA.grayA9 }}>
              {types[resource.type].text} {resource.id && `- ${resource.id}`}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Link>
  );
};

const StatCard = ({ label, value, icon: Icon }) => (
  <Flex
    direction="column"
    py="3"
    px="4"
    style={{
      backgroundColor: gray.gray1,
      borderRadius: 10,
      border: `1px solid ${gray.gray3}`,
    }}
  >
    <Flex align="center" justify="between">
      <Text size="1" weight="medium" style={{ color: grayA.grayA9 }}>
        {label}
      </Text>
      <Icon size={16} strokeWidth={1.5} color={gray.gray8} />
    </Flex>

    <Text size="5" weight="medium" style={{ color: grayA.grayA11 }}>
      {value}
    </Text>
  </Flex>
);

const RecapTable = ({ data, maxItems = 5 }) => (
  <Flex
    className="recap-table"
    direction="column"
    px="3"
    style={{
      backgroundColor: gray.gray1,
      borderRadius: 12,
      border: `1px solid ${grayA.grayA3}`,
      overflow: "hidden",
    }}
  >
    <Table.Root>
      <Table.Body>
        {data.slice(0, maxItems).map((item, i) => (
          <Table.Row key={i} className="recap-table-row">
            <Table.Cell>
              <Flex align="center" justify="center" style={{ height: "100%" }}>
                {item.type === "ticket" ? (
                  <Ticket size={16} color={cyan.cyan9} />
                ) : (
                  <Activity size={16} color={violet.violet9} />
                )}
              </Flex>
            </Table.Cell>
            <Table.Cell>
              <Flex style={{ maxWidth: 544 }}>
                <Text size="2" truncate>
                  {item.description}
                </Text>
              </Flex>
            </Table.Cell>
            <Table.Cell width="88px">
              <Text size="2" style={{ color: grayA.grayA9 }}>
                {item.time}
              </Text>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  </Flex>
);

// Replace the existing risks & concerns section and add usage insights
const AccountRecap = ({ item }) => (
  <Flex direction="column" gap="4" mt="8">
    <SectionTitle title="Account recap" icon={BarChart2} />

    <Flex align="center" gap="5" pl="4" pr="6" my="4">
      <Flex align="center" gap="3">
        <Avatar
          src={item.item_user.user_organization.organization_avatar}
          radius="medium"
          style={{
            padding: 6,
            width: 32,
            height: 32,
            border: `1px solid ${grayA.grayA4}`,
          }}
        />
        <Text size="6" weight="medium">
          {item.item_user.user_organization.organization_name}
        </Text>
      </Flex>

      <Flex gap="3" align="center">
        <Badge
          size="1"
          color="gray"
          radius="large"
          style={{ padding: "4px 8px" }}
        >
          ${abbrevNum(item.item_user.user_organization.organization_value)} ARR
        </Badge>
        <Badge
          size="1"
          color="gray"
          radius="large"
          style={{ padding: "4px 8px" }}
        >
          <Repeat
            size={12}
            strokeWidth={1.5}
            style={{ color: gray.gray10, marginRight: 2 }}
          />
          Renewal in {toTimeAgo(item.item_user.user_organization.renewal_date)}
        </Badge>
      </Flex>

      <Button
        variant="outline"
        color="gray"
        size="1"
        radius="large"
        style={{ opacity: 0.8, marginLeft: "auto" }}
      >
        Open
        <ExternalLink size={12} strokeWidth={1.5} />
      </Button>
    </Flex>

    {/* Revenue Health Section */}
    <Flex
      direction="column"
      gap="3"
      p="3"
      style={{ border: `1px solid ${grayA.grayA4}`, borderRadius: 16 }}
    >
      <Flex align="center" gap="4" ml="1">
        <Flex align="center" gap="3">
          <Flex
            align="center"
            justify="center"
            p="6px"
            style={{ background: green.green3, borderRadius: 999 }}
          >
            <PiggyBank size={18} color={green.green10} />
          </Flex>
          <Text size="3" weight="medium">
            Revenue health
          </Text>
        </Flex>
        <Badge color="amber" size="1">
          <TriangleAlert size={12} strokeWidth={1.8} />
          Churn risk
        </Badge>
      </Flex>

      <Flex gap="3">
        <Flex direction="column" gap="2">
          {/* Growth Opportunities */}
          <Flex
            direction="column"
            style={{ border: `1px solid ${grayA.grayA3}`, borderRadius: 10 }}
          >
            <Flex
              align="center"
              gap="2"
              px="4"
              py="2"
              style={{ borderBottom: `1px solid ${grayA.grayA3}` }}
            >
              <ChartNoAxesCombined size={16} color={gray.gray8} />
              <Text size="2" weight="medium" style={{ color: grayA.grayA11 }}>
                Growth opportunities
              </Text>
            </Flex>
            <Text size="2" m="3" mb="4" style={{ color: grayA.grayA11 }}>
              <ul style={{ margin: 0, paddingLeft: 20 }}>
                {item.item_details.account_recap.revenue_health.growth_opportunities.map(
                  (opportunity, i) => (
                    <li
                      key={i}
                      style={
                        typeof opportunity === "object"
                          ? { listStyle: "circle", marginLeft: 20 }
                          : undefined
                      }
                    >
                      {typeof opportunity === "string"
                        ? opportunity
                        : `Recommended: ${opportunity.recommendation}`}
                    </li>
                  )
                )}
              </ul>
            </Text>
          </Flex>

          {/* Entitlement Utilization */}
          <Flex
            direction="column"
            style={{ border: `1px solid ${grayA.grayA3}`, borderRadius: 10 }}
          >
            <Flex
              align="center"
              gap="2"
              px="4"
              py="2"
              style={{ borderBottom: `1px solid ${grayA.grayA3}` }}
            >
              <SquareActivity size={16} color={gray.gray8} />
              <Text size="2" weight="medium" style={{ color: grayA.grayA11 }}>
                Entitlement utilization
              </Text>
            </Flex>
            <Text size="2" m="3" mb="4" style={{ color: grayA.grayA11 }}>
              <ul style={{ margin: 0, paddingLeft: 20 }}>
                {item.item_details.account_recap.revenue_health.entitlement_utilization.map(
                  (item, i) => (
                    <li key={i}>{item}</li>
                  )
                )}
              </ul>
            </Text>
          </Flex>
        </Flex>

        {/* Usage Metrics Grid */}
        <Grid
          columns="1"
          gap="3"
          style={{ width: "100%", maxWidth: 180, alignSelf: "start" }}
        >
          {[
            {
              label: "Seats purchased",
              value:
                item.item_details.account_recap.revenue_health.usage_metrics
                  .seats_purchased,
              icon: Receipt,
            },
            {
              label: "Daily active users",
              value:
                item.item_details.account_recap.revenue_health.usage_metrics
                  .daily_active_users,
              icon: CalendarX,
            },
            {
              label: "Weekly active users",
              value:
                item.item_details.account_recap.revenue_health.usage_metrics
                  .weekly_active_users,
              icon: CalendarRange,
            },
            {
              label: "Monthly active users",
              value:
                item.item_details.account_recap.revenue_health.usage_metrics
                  .monthly_active_users,
              icon: CalendarFold,
            },
          ].map((metric) => (
            <StatCard key={metric.label} {...metric} />
          ))}
        </Grid>
      </Flex>
    </Flex>

    {/* Support Metrics */}
    <Flex
      gap="4"
      p="3"
      justify="between"
      align="start"
      style={{ border: `1px solid ${grayA.grayA4}`, borderRadius: 16 }}
    >
      <Flex align="center" justify="between" pl="1">
        <Flex align="center" gap="3">
          <Flex
            align="center"
            justify="center"
            p="7px"
            style={{ background: blue.blue3, borderRadius: 999 }}
          >
            <Headset size={18} color={blue.blue10} />
          </Flex>
          <Flex direction="column">
            <Text size="3" weight="medium">
              Support metrics
            </Text>
            <Text
              weight="medium"
              style={{ color: grayA.grayA9, fontSize: 10, marginTop: -3 }}
            >
              Synced {item.item_details.account_recap.support_metrics.synced}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Grid columns="3" gap="3" style={{ minWidth: 506 }}>
        {[
          {
            label: "Open tickets",
            value: item.item_details.account_recap.support_metrics.open_tickets,
            icon: Ticket,
          },
          {
            label: "Closed tickets",
            value:
              item.item_details.account_recap.support_metrics.closed_tickets,
            icon: TicketCheck,
          },
          {
            label: "Avg response time",
            value:
              item.item_details.account_recap.support_metrics.avg_response_time,
            icon: Timer,
          },
        ].map((metric) => (
          <StatCard key={metric.label} {...metric} />
        ))}
      </Grid>
    </Flex>

    {/* Risks & Concerns */}
    <Flex
      direction="column"
      gap="3"
      p="3"
      style={{ border: `1px solid ${grayA.grayA4}`, borderRadius: 16 }}
    >
      <Flex align="center" justify="between" pl="1" pr="2">
        <Flex align="center" gap="3">
          <Flex
            align="center"
            justify="center"
            p="6px"
            style={{ background: red.red3, borderRadius: 999 }}
          >
            <Siren size={16} color={red.red11} />
          </Flex>
          <Text size="3" weight="medium">
            Risks & concerns
          </Text>
        </Flex>
        <Button
          variant="outline"
          size="1"
          color="gray"
          radius="large"
          style={{ opacity: 0.8 }}
        >
          See all
          <ArrowRight size={12} strokeWidth={1.5} />
        </Button>
      </Flex>

      <RecapTable data={item.item_details.account_recap.risks_concerns} />
    </Flex>

    {/* Usage Insights */}
    <Flex
      direction="column"
      gap="3"
      p="3"
      style={{ border: `1px solid ${grayA.grayA4}`, borderRadius: 16 }}
    >
      <Flex align="center" justify="between" pl="1" pr="2">
        <Flex align="center" gap="3">
          <Flex
            align="center"
            justify="center"
            p="6px"
            style={{ background: violet.violet3, borderRadius: 999 }}
          >
            <Telescope size={16} color={violet.violet11} />
          </Flex>
          <Text size="3" weight="medium">
            Usage insights
          </Text>
        </Flex>
        <Button
          variant="outline"
          size="1"
          color="gray"
          radius="large"
          style={{ opacity: 0.8 }}
        >
          See all
          <ArrowRight size={12} strokeWidth={1.5} />
        </Button>
      </Flex>

      <RecapTable data={item.item_details.account_recap.usage_insights} />
    </Flex>
  </Flex>
);

const MeetingContent = ({ meeting }) => {
  return (
    <Flex direction="column">
      {/* Meeting Header */}
      <Flex justify="between" align="center" mt="4" mb="5">
        <Flex align="center" gap="2">
          <Avatar
            radius="none"
            src={meeting.item_user.user_organization.organization_avatar}
            style={{
              width: 18,
              height: 18,
              border: `1px solid ${grayA.grayA4}`,
              padding: 2,
              borderRadius: 4,
            }}
          />
          <Text size="2" style={{ color: grayA.grayA9 }}>
            {meeting.item_user.user_organization.organization_name}
          </Text>
        </Flex>

        <Flex gap="2" align="center">
          <Timer size={14} strokeWidth={1.75} color={grayA.grayA9} />
          <Text size="2" style={{ color: grayA.grayA9 }}>
            {(() => {
              const now = new Date();
              now.setHours(8, 15, 0); // Set current time to 8:15am
              const diff = meeting.starts_at - now;
              const hours = Math.floor(diff / (1000 * 60 * 60));
              const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
              return `Starts in ${hours}h ${mins}m`;
            })()}
          </Text>
        </Flex>
      </Flex>

      {/* Header + body */}
      <Flex direction="column" gap="7">
        <Flex direction="column" gap="4">
          <Heading weight="medium" size="8">
            {meeting.item_title}
          </Heading>
          <Flex gap="3">
            <Button size="1" radius="large" style={{ height: 26 }}>
              <Video size={16} strokeWidth={1.5} style={{ marginRight: 2 }} />
              Join meeting
            </Button>
            <Button
              size="1"
              radius="large"
              variant="outline"
              color="gray"
              style={{ opacity: 0.8, height: 26 }}
            >
              <Copy size={12} strokeWidth={1.5} style={{ marginRight: 2 }} />
              Copy invite link
            </Button>
          </Flex>
        </Flex>

        <Flex direction="column" gap="5" mb="7">
          <SectionTitle title="Meeting prep" icon={Sparkles} />
          <Flex direction="column" gap="2" mb="2">
            <Text size="3" weight="medium" style={{ color: grayA.grayA11 }}>
              Situation
            </Text>
            <Text size="3" style={{ color: grayA.grayA10 }}>
              <Markdown className="markdown-content">
                {meeting.item_details.summary}
              </Markdown>
            </Text>
          </Flex>
          <Flex direction="column" gap="2">
            <Text size="3" weight="medium" style={{ color: grayA.grayA11 }}>
              Diagnosis
            </Text>
            <Text size="3" style={{ color: grayA.grayA10 }}>
              {meeting.item_details.diagnosis}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      {/* Resources */}
      {meeting.item_details.resources && (
        <Flex direction="column" gap="3">
          <Text size="3" weight="medium" style={{ color: grayA.grayA11 }}>
            Resources
          </Text>
          <Flex gap="3">
            <Grid columns="3" gap="2">
              {meeting.item_details.resources.slice(0, 6).map((resource, i) => (
                <MeetingResource key={i} resource={resource} />
              ))}
            </Grid>
            <Flex
              direction="column"
              p="3"
              style={{
                backgroundColor: gray.gray2,
                borderRadius: 10,
                cursor: "default",
                userSelect: "none",
              }}
            >
              <Text size="2" weight="medium">
                +{meeting.item_details.resources.length - 3} more
              </Text>
              <Text size="1" style={{ color: grayA.grayA9 }}>
                See all
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}

      {meeting.item_details.account_recap && <AccountRecap item={meeting} />}
    </Flex>
  );
};

const MeetingDetails = ({ meeting, isFollowUp }) => {
  return (
    <Flex direction="column">
      {/* Meeting Info */}
      <Flex direction="column" gap="5" px="20px" py="5">
        <Flex gap="4" align="center">
          <Calendar size={18} strokeWidth={1.7} color={grayA.grayA9} />
          <Flex align="center" gap="2">
            <Text size="2" style={{ color: grayA.grayA11 }}>
              Today
            </Text>
            <Text size="2" style={{ color: grayA.grayA8 }}>
              {new Date(meeting.starts_at).toLocaleDateString("en-US", {
                weekday: "short",
                month: "short",
                day: "numeric",
              })}
            </Text>
          </Flex>
        </Flex>
        <Flex gap="4" align="center">
          <Clock size={18} strokeWidth={1.7} color={grayA.grayA9} />
          <Flex align="center" gap="2">
            <Text size="2" style={{ color: grayA.grayA11 }}>
              {new Date(meeting.starts_at)
                .toLocaleTimeString([], {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                })
                .replace(" ", "")}{" "}
              –{" "}
              {new Date(meeting.starts_at.getTime() + meeting.duration * 60000)
                .toLocaleTimeString([], {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                })
                .replace(" ", "")}
            </Text>
            <Text size="2" style={{ color: grayA.grayA8 }}>
              {meeting.duration}min
            </Text>
          </Flex>
        </Flex>

        {/* Generate agenda */}
        {!isFollowUp && (
          <Flex gap="4" align="center">
            <FileText size={18} strokeWidth={1.7} color={grayA.grayA9} />
            <Flex direction="column">
              <Text size="2" weight="medium" style={{ color: violet.violet11 }}>
                Generate agenda
              </Text>
              <Text size="1" style={{ color: grayA.grayA9 }}>
                Key questions, topics, next steps
              </Text>
            </Flex>
          </Flex>
        )}

        {/* Participants */}
        <Flex direction="column" gap="4">
          <Flex gap="4" align="center" style={{ marginBottom: 4 }}>
            <Users size={18} strokeWidth={1.8} color={grayA.grayA9} />
            <Text size="2" style={{ color: grayA.grayA11 }}>
              {meeting.item_details.participants.length} participants
            </Text>
          </Flex>
          <Flex direction="column" gap="4">
            {meeting.item_details.participants.map((participant, i) => (
              <Flex key={i} align="center" gap="3">
                <Avatar
                  size="1"
                  radius="full"
                  src={participant.avatar}
                  style={{ border: `1px solid ${grayA.grayA4}` }}
                />
                <Flex direction="column" style={{ flex: 1 }}>
                  <Text
                    size="1"
                    weight="medium"
                    style={{ color: grayA.grayA11 }}
                  >
                    {participant.name}
                  </Text>
                  <Text
                    size="1"
                    style={{
                      color: grayA.grayA9,
                    }}
                  >
                    {participant.role}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>

      {/* Account Section */}
      <Flex
        direction="column"
        gap="4"
        p="4"
        style={{
          borderTop: `1px solid ${grayA.grayA4}`,
          borderBottom: `1px solid ${grayA.grayA4}`,
        }}
      >
        <Flex align="center" justify="between" style={{ width: "100%" }}>
          <Flex align="center" gap="3">
            <Avatar
              size="2"
              radius="large"
              src={meeting.item_user.user_organization.organization_avatar}
              style={{ border: `1px solid ${grayA.grayA4}`, padding: 6 }}
            />
            <Flex direction="column">
              <Text size="3" weight="medium">
                {meeting.item_user.user_organization.organization_name}
              </Text>
              <Text
                style={{ color: grayA.grayA9, fontSize: 10, marginTop: -2 }}
              >
                $
                {abbrevNum(
                  meeting.item_user.user_organization.organization_value
                )}{" "}
                ARR
              </Text>
            </Flex>
          </Flex>
          <Button
            variant="outline"
            size="1"
            color="gray"
            radius="large"
            style={{ opacity: 0.7 }}
          >
            Open
            <ExternalLink size={12} strokeWidth={1.5} />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const CallNotesContent = ({
  notes,
  isEditing,
  setIsEditing,
  onEmailUpdate,
}) => {
  const [editedSubject, setEditedSubject] = useState(
    notes.item_details.draft_email.email_subject
  );
  const [editedBody, setEditedBody] = useState(
    notes.item_details.draft_email.email_body
  );
  const [copied, setCopied] = useState(false);

  const handleSave = () => {
    onEmailUpdate({
      email_subject: editedSubject,
      email_body: editedBody,
    });
  };

  const handleCancel = () => {
    setEditedSubject(notes.item_details.draft_email.email_subject);
    setEditedBody(notes.item_details.draft_email.email_body);
    setIsEditing(false);
  };

  return (
    <Flex direction="column">
      {/* Meeting Header */}
      <Flex justify="between" align="center" mt="4" mb="5">
        <Flex align="center" gap="2">
          <Avatar
            radius="none"
            src={notes.item_user.user_organization.organization_avatar}
            style={{
              width: 18,
              height: 18,
              border: `1px solid ${grayA.grayA4}`,
              padding: 2,
              borderRadius: 4,
            }}
          />
          <Text size="2" style={{ color: grayA.grayA9 }}>
            {notes.item_user.user_organization.organization_name}
          </Text>
        </Flex>
      </Flex>

      {/* Header + body */}
      <Flex direction="column" gap="7">
        <Flex direction="column" gap="4">
          <Heading weight="medium" size="8">
            {notes.item_title}
          </Heading>
          <Flex gap="3">
            <Button size="1" radius="large" style={{ height: 26 }}>
              <ArrowDown size={12} strokeWidth={1.5} />
              Review & send follow-up
            </Button>
            <Button
              size="1"
              radius="large"
              variant="outline"
              color="gray"
              style={{ opacity: 0.8, height: 26 }}
            >
              <CirclePlay size={12} strokeWidth={1.5} />
              Watch recording
            </Button>
          </Flex>
        </Flex>

        <Flex direction="column" gap="5">
          <SectionTitle title="Meeting recap" icon={Sparkles} />

          {/* Summary */}
          <Flex direction="column" gap="2" mb="2">
            <Text size="3" weight="medium" style={{ color: grayA.grayA11 }}>
              Summary
            </Text>
            <Text size="3" style={{ color: grayA.grayA10 }}>
              {notes.item_details.summary}
            </Text>
          </Flex>

          {/* Key Points */}
          <Flex direction="column" gap="2">
            <Text size="3" weight="medium" style={{ color: grayA.grayA11 }}>
              Key points
            </Text>
            <Text size="3" style={{ color: grayA.grayA10 }}>
              <ul style={{ paddingLeft: 20, margin: 0 }}>
                {notes.item_details.key_points.map((point, i) => (
                  <li key={i}>{point}</li>
                ))}
              </ul>
            </Text>
          </Flex>

          {/* Next Steps */}
          <Flex direction="column" gap="2">
            <Text size="3" weight="medium" style={{ color: grayA.grayA11 }}>
              Next steps
            </Text>
            <Text size="3" style={{ color: grayA.grayA10 }}>
              <ul style={{ paddingLeft: 20, margin: 0 }}>
                {notes.item_details.next_steps.map((step, i) => (
                  <li key={i}>{step}</li>
                ))}
              </ul>
            </Text>
          </Flex>
        </Flex>

        {/* Email Preview */}
        <Flex direction="column" gap="5">
          <SectionTitle
            title="Suggested action"
            description={notes.item_details.suggested_action}
            icon={Zap}
          />

          <EmailPreview
            email={notes.item_details.draft_email}
            isEmailSent={notes.item_status === "completed"}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            onSave={onEmailUpdate}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const Inbox = () => {
  const { openingId } = useParams();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Flatten all items for navigation
  const allItems = items.reduce(
    (acc, section) => [...acc, ...section.data],
    []
  );
  const selectedItem = allItems.find((item) => item.item_id === selectedItemId);

  useEffect(() => {
    setItems(mockData.items);
    if (!openingId && mockData.items.length > 0) {
      const firstItem = mockData.items[0].data[0];
      setSelectedItemId(firstItem.item_id);
      navigate(`/inbox/${firstItem.item_id}`, { replace: true });
    }
  }, [openingId, navigate]);

  useEffect(() => {
    if (openingId) {
      setSelectedItemId(Number(openingId));
    }
  }, [openingId]);

  useEffect(() => {
    if (selectedItemId && selectedItemId !== Number(openingId)) {
      navigate(`/inbox/${selectedItemId}`);
    }
  }, [selectedItemId, openingId, navigate]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();
        const currentIndex = allItems.findIndex(
          (s) => s.item_id === selectedItemId
        );

        if (e.key === "ArrowUp" && currentIndex > 0) {
          const newSelectedId = allItems[currentIndex - 1].item_id;
          setSelectedItemId(newSelectedId);
        } else if (
          e.key === "ArrowDown" &&
          currentIndex < allItems.length - 1
        ) {
          const newSelectedId = allItems[currentIndex + 1].item_id;
          setSelectedItemId(newSelectedId);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedItemId, allItems]);

  // TODO: fix this, hook up to localStorage
  // Mark opening as read after 1 second of viewing
  // useEffect(() => {
  //   if (!selectedItem || selectedItem.item_status !== "unread") {
  //     return;
  //   }

  //   const timer = setTimeout(() => {
  //     setOpenings(
  //       openings.map((opening) =>
  //         opening.opening_id === selectedOpeningId
  //           ? { ...opening, item_status: "active" }
  //           : opening
  //       )
  //     );
  //   }, 500);

  //   return () => clearTimeout(timer);
  // }, [selectedOpeningId]);

  const handleEmailUpdate = (updates) => {
    setItems(
      items.map((section) => ({
        ...section,
        data: section.data.map((item) =>
          item.item_id === selectedItemId
            ? {
                ...item,
                ...(updates.item_status && {
                  item_status: updates.item_status,
                }),
                item_details: {
                  ...item.item_details,
                  draft_email: {
                    ...item.item_details.draft_email,
                    ...(updates.email_subject && {
                      email_subject: updates.email_subject,
                    }),
                    ...(updates.email_body && {
                      email_body: updates.email_body,
                    }),
                  },
                },
              }
            : item
        ),
      }))
    );
    setIsEditing(false);
  };

  if (!items.length || !selectedItem) {
    return null;
  }

  return (
    <Flex className="inbox" style={{ height: "100%" }}>
      {/* Left Sidebar - Items */}
      <Flex
        direction="column"
        style={{
          width: 280,
          minWidth: 280,
          borderRight: `1px solid ${grayA.grayA4}`,
          height: "100%",
          backgroundColor: gray.gray1,
        }}
      >
        <ListHeader title="Inbox" count={allItems.length} />
        <ScrollArea scrollbars="vertical">
          {items.map((section) => (
            <React.Fragment key={section.section}>
              <ListSectionTitle title={section.section} />
              <Flex key={section.section} direction="column" pt="1">
                {section.data.map((item) => (
                  <ListItem
                    key={item.item_id}
                    item={item}
                    isSelected={item.item_id === selectedItemId}
                    onClick={() => setSelectedItemId(item.item_id)}
                  />
                ))}
              </Flex>
            </React.Fragment>
          ))}
        </ScrollArea>
      </Flex>

      {/* Main Content */}
      <Flex direction="column" style={{ flex: 1 }}>
        <MainHeader
          type={selectedItem.item_type}
          items={allItems}
          selectedItemId={selectedItemId}
          setSelectedItemId={setSelectedItemId}
        />
        <ScrollArea scrollbars="vertical">
          <Flex
            direction="column"
            p="5"
            pb="8"
            style={{
              width: 800,
              margin: "0 auto",
            }}
          >
            {selectedItem?.item_details &&
              (selectedItem.item_type === "meeting" ? (
                <MeetingContent meeting={selectedItem} />
              ) : selectedItem.item_type === "follow_up" ? (
                <CallNotesContent
                  notes={selectedItem}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  onEmailUpdate={handleEmailUpdate}
                />
              ) : (
                <OpeningContent
                  opening={selectedItem}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  onEmailUpdate={handleEmailUpdate}
                />
              ))}
          </Flex>
        </ScrollArea>
      </Flex>

      {/* Right Sidebar - Details */}
      <ScrollArea
        scrollbars="vertical"
        style={{ width: 275, minWidth: 275, height: "100%" }}
      >
        <Flex
          direction="column"
          style={{
            borderLeft: `1px solid ${grayA.grayA4}`,
            backgroundColor: "white",
            height: "100%",
          }}
        >
          {selectedItem && (
            <>
              <DetailsHeader />
              {selectedItem.item_type === "meeting" ||
              selectedItem.item_type === "follow_up" ? (
                <MeetingDetails
                  meeting={selectedItem}
                  isFollowUp={selectedItem.item_type === "follow_up"}
                />
              ) : (
                <>
                  <OpeningDetails opening={selectedItem} />
                  <AccountDetails opening={selectedItem} />
                  <RevenueHealth opening={selectedItem} />
                  <UserDetails
                    user={selectedItem.item_user}
                    metrics={selectedItem.item_user.user_metrics}
                  />
                </>
              )}
            </>
          )}
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

export default Inbox;

const mockData = {
  items: [
    {
      section: "Meetings",
      data: [
        {
          item_id: 1,
          item_type: "meeting",
          item_status: "upcoming",
          item_title: "Technical review: Java implementation",
          starts_at: (() => {
            const today = new Date();
            today.setHours(10, 0, 0, 0);
            return today;
          })(),
          duration: 30,
          item_user: {
            user_name: "Steve Jobs",
            user_organization: {
              organization_name: "Apple",
              organization_value: 450000,
              organization_avatar:
                "https://images.vexels.com/media/users/3/129234/isolated/lists/73970c892d748c7507db8e10d71535b0-apple-logo-icon.png",
              renewal_date: new Date(Date.now() + 41 * 24 * 60 * 60 * 1000), // Added for "Renewal in 41d"
            },
            user_avatar:
              "https://www.famousbirthdays.com/faces/jobs-steven-image.jpg",
          },
          item_details: {
            summary:
              "Technical blocker on Java driver implementation. Customer showing signs of self-service attempts without success (high doc visits, multiple config changes).",
            diagnosis:
              "Based on documentation visits and API log downloads combined with prior network resolution tickets, customer is likely hitting VPC/firewall issues with Java driver authentication.",
            participants: [
              {
                name: "Alex Kim",
                role: "Engineering Manager, Apple",
                avatar: "https://i.pravatar.cc/150?img=34",
              },
              {
                name: "Steve Jobs",
                role: "Engineer, Apple",
                avatar:
                  "https://www.famousbirthdays.com/faces/jobs-steven-image.jpg",
              },
              {
                name: "Bradley Barry",
                role: "You",
                avatar: "https://i.pravatar.cc/150?img=68",
              },
            ],
            resources: [
              {
                title: "Firewall issues with Java driver",
                type: "support",
                id: "#421",
              },
              {
                title: "Java connection troubleshooting",
                type: "docs",
                id: null,
              },
              {
                title: "Common Java objections: talk track",
                type: "enablement",
                id: null,
              },
              {
                title: "Java driver not found",
                type: "support",
                id: "#502",
              },
              {
                title: "Initial config: Java examples",
                type: "docs",
                id: null,
              },
              {
                title: "First 30 days: technical success",
                type: "enablement",
                id: null,
              },
            ],
            account_recap: {
              revenue_health: {
                status: "churn_risk",
                growth_opportunities: [
                  "This account isn't storing Schema Validations in their collections",
                  "Account is manually adding aggregation pipelines that appear to be from automated testing flows",
                  {
                    recommendation: 'suggest a "Realm Triggers" entitlement',
                  },
                  "Similar enterprise accounts commonly use the Atlas Search feature",
                ],
                entitlement_utilization: [
                  "Using Atlas Data Lake heavily but Schema Validation features remain untouched",
                  "Account is actively utilizing all features of Atlas Charts",
                  "63% of purchased seats haven't logged in during the last 30 days",
                  "No activity from 3 engineering teams that were part of initial rollout",
                ],
                usage_metrics: {
                  seats_purchased: 35,
                  daily_active_users: 7,
                  weekly_active_users: 13,
                  monthly_active_users: 21,
                },
              },
              support_metrics: {
                synced: "2m ago",
                open_tickets: 21,
                closed_tickets: 9,
                avg_response_time: "12h",
              },
              risks_concerns: [
                {
                  type: "usage",
                  description:
                    "22 out of 35 purchased seats have not logged in since August 1st",
                  time: "2d ago",
                },
                {
                  type: "usage",
                  description:
                    "Platform Engineering, DevOps, and QA teams showing zero logins since onboarding completed June 15",
                  time: "4h ago",
                },
                {
                  type: "ticket",
                  id: "#492",
                  description:
                    "Data Lake queries timing out during ETL jobs, no Schema Validation configured",
                  time: "1d ago",
                },
                {
                  type: "usage",
                  description:
                    "CI/CD pipelines making 2,400+ direct MongoDB queries instead of using Realm Triggers",
                  time: "28m ago",
                },
                {
                  type: "ticket",
                  id: "#507",
                  description: "Degraded search performance",
                  time: "3d ago",
                },
              ],
              usage_insights: [
                {
                  type: "usage",
                  description:
                    "Daily active users dropped from 15 to 7 in past 30 days, mostly from engineering teams",
                  time: "30m ago",
                },
                {
                  type: "usage",
                  description:
                    "3 teams that completed training June 15 have not accessed platform since (total 12 seats)",
                  time: "2h ago",
                },
                {
                  type: "usage",
                  description:
                    "Data Lake processing 250GB/day but Schema Validation disabled on all collections",
                  time: "4h ago",
                },
                {
                  type: "usage",
                  description:
                    "Automated test suite running 400+ aggregation pipelines per hour via direct MongoDB queries",
                  time: "1d ago",
                },
                {
                  type: "usage",
                  description:
                    "Customer implementing custom text search solution despite having Atlas Search entitlement",
                  time: "2d ago",
                },
              ],
            },
          },
        },
        {
          item_id: 2,
          item_type: "meeting",
          item_status: "upcoming",
          item_title: "Quarterly business review",
          starts_at: (() => {
            const today = new Date();
            today.setHours(14, 0, 0, 0);
            return today;
          })(),
          duration: 45,
          item_user: {
            user_name: "Sarah Martinez",
            user_organization: {
              organization_name: "Airbnb",
              organization_value: 720000,
              organization_avatar:
                "https://a0.muscache.com/airbnb/static/icons/android-icon-192x192-c0465f9f0380893768972a31a614b670.png",
            },
            user_avatar: "https://i.pravatar.cc/150?img=45",
          },
          item_details: {
            summary:
              "Airbnb's account shows critical underutilization of enterprise features. Teams are heavily using legacy tools instead of purchased features - Jenkins pipelines instead of native CI/CD, manual data imports instead of Data Pipeline automation, and unused Analytics dashboards. Only 31 of 88 seats are active monthly.",
            diagnosis:
              "Despite having CI/CD automation and Data Pipeline entitlements, 6 of 8 dev teams are still using legacy tools. Analytics dashboard was created but has zero usage in 30 days. Multiple failed SSO configuration attempts suggest technical blockers in enterprise feature adoption. Teams may need targeted enablement and migration support.",
            participants: [
              {
                name: "Sarah Martinez",
                role: "Engineering director, Airbnb",
                avatar: "https://i.pravatar.cc/150?img=44",
              },
              {
                name: "Bradley Barry",
                role: "You",
                avatar: "https://i.pravatar.cc/150?img=68",
              },
            ],
            resources: [
              {
                title: "Issue with logging in via SSO",
                type: "support",
                id: "#834",
              },
              {
                title: "Enterprise implementation guide",
                type: "docs",
                id: null,
              },
              {
                title: "Feature adoption metrics Q3",
                type: "enablement",
                id: null,
              },
              {
                title: "Performance optimization",
                type: "support",
                id: "#892",
              },
              {
                title: "Advanced features overview",
                type: "docs",
                id: null,
              },
              {
                title: "QBR presentation template",
                type: "enablement",
                id: null,
              },
              {
                title: "QBR presentation template",
                type: "enablement",
                id: null,
              },
              {
                title: "QBR presentation template",
                type: "enablement",
                id: null,
              },
            ],
            account_recap: {
              revenue_health: {
                status: "churn_risk",
                growth_opportunities: [
                  "Multiple teams implementing custom CI/CD workflows - opportunity for Automated Deployments add-on",
                  "High volume of manual data imports detected",
                  {
                    recommendation: "introduce Data Pipeline automation",
                  },
                  "Development teams manually handling schema migrations - opportunity for Schema Management tools",
                ],
                entitlement_utilization: [
                  "CI/CD automation features purchased but teams still using legacy Jenkins pipelines",
                  "Data Pipeline entitlements active but only used by 2 of 8 dev teams",
                  "Advanced Analytics dashboard created but no active users in past 30d",
                ],
                usage_metrics: {
                  seats_purchased: 88,
                  daily_active_users: 15,
                  weekly_active_users: 24,
                  monthly_active_users: 31,
                },
              },
              support_metrics: {
                synced: "5m ago",
                open_tickets: 14,
                closed_tickets: 23,
                avg_response_time: "8h",
              },
              risks_concerns: [
                {
                  type: "ticket",
                  id: "#482",
                  description: "SSO configuration issues",
                  time: "2d ago",
                },
                {
                  type: "usage",
                  description:
                    "Jenkins pipeline usage increased 300% while native CI/CD features unused",
                  time: "1d ago",
                },
                {
                  type: "usage",
                  description:
                    "6 teams still using legacy data import tools despite Pipeline entitlements",
                  time: "3h ago",
                },
                {
                  type: "ticket",
                  id: "#497",
                  description: "Jenkins integration support",
                  time: "4d ago",
                },
              ],
              usage_insights: [
                {
                  type: "usage",
                  description:
                    "Data Pipeline feature enabled but 75% of imports still using legacy tools",
                  time: "45m ago",
                },
                {
                  type: "usage",
                  description:
                    "Schema changes being handled manually despite Management tools access",
                  time: "1h ago",
                },
                {
                  type: "usage",
                  description:
                    "SSO configuration attempts failed 12 times in past week",
                  time: "3h ago",
                },
                {
                  type: "usage",
                  description:
                    "Analytics dashboard created but only accessed once",
                  time: "1d ago",
                },
              ],
            },
          },
        },
      ],
    },
    {
      section: "Follow-up",
      data: [
        {
          item_id: 8,
          item_type: "follow_up",
          item_status: "active",
          item_title:
            "Enterprise security features: deep dive & implementation planning",
          starts_at: (() => {
            const now = new Date();
            const mins = now.getMinutes();
            const roundedMins = Math.floor(mins / 5) * 5; // Round down to nearest 5
            now.setMinutes(roundedMins);
            return new Date(now - 45 * 60 * 1000); // At least 45 mins ago
          })(), // Meeting ended at a time divisible by 5
          duration: 30,
          item_user: {
            user_name: "Alex Thompson",
            user_organization: {
              organization_name: "Slack",
              organization_value: 580000,
              organization_avatar:
                "https://a.slack-edge.com/80588/marketing/img/meta/favicon-32.png",
              renewal_date: "2024-12-15",
            },
            user_avatar: "https://i.pravatar.cc/150?img=33",
            user_email: "athompson@slack.com",
            user_role: "Security Engineering",
            user_metrics: {
              last_login: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
              recent_activity: [
                "Viewed enterprise security documentation",
                "Downloaded compliance reports",
                "Accessed SSO configuration",
              ],
              average_session: [
                "Regular security feature reviews",
                "Compliance documentation access",
                "Logs in 3x/week, avg. 25m in product",
              ],
              recent_interactions: [
                { item_title: "Security features demo", created_at: "8/29" },
                { item_title: "Compliance requirements", created_at: "8/15" },
              ],
            },
          },
          item_details: {
            summary:
              "Alex showed strong interest in our enterprise security features after multiple visits to security documentation. Meeting focused on SSO implementation, audit logging, and compliance requirements.",
            participants: [
              {
                name: "Alex Thompson",
                role: "Security Eng. Lead, Slack",
                avatar: "https://i.pravatar.cc/150?img=33",
              },
              {
                name: "Bradley Barry",
                role: "You",
                avatar: "https://i.pravatar.cc/150?img=68",
              },
            ],
            key_points: [
              "Team is evaluating enterprise security features for potential upgrade",
              "Current pain points: limited audit logging, basic SSO options",
              "Interested in advanced compliance reporting and RBAC features",
              "Timeline: security review completion in 2 weeks",
            ],
            next_steps: [
              "Share detailed SSO implementation guide",
              "Schedule technical review with security team",
              "Provide enterprise compliance documentation",
            ],
            resources: [
              {
                title: "Enterprise SSO Implementation",
                type: "docs",
              },
              {
                title: "Compliance & Audit Features",
                type: "enablement",
              },
              {
                title: "RBAC Configuration Guide",
                type: "docs",
              },
            ],
            recording_url: "https://zoom.us/rec/share/example-recording-url",
            suggested_action:
              "Send follow-up email with useful notes and resources from the call",
            draft_email: {
              email_to: "Alex Thompson",
              email_subject:
                "Follow-up: Enterprise security features discussion",
              email_body: `Hi Alex,

Thank you for the detailed discussion about your enterprise security requirements today. I wanted to follow up with specific resources and next steps to help accelerate your implementation:

Immediate Access & Resources:
• I've provisioned early access to our Enterprise SSO sandbox environment (expires in 14 days)
• Here's the direct link to our SAML configuration guide for your specific IdP setup
• Attached our complete audit logging implementation guide with the custom retention policies we discussed

Action Items From Our Side:
1. Our security team will review your current RBAC structure tomorrow and provide optimization recommendations
2. I'm setting up priority monitoring for your SSO testing window next week
3. We'll have the compliance documentation package (including SOC 2 Type II) delivered to you by EOD

For Your Team:
• Test the SSO configuration using the sandbox credentials I'll send separately
• Review the attached audit logging schema - let me know if you need any adjustments
• Share the RBAC worksheet with your team leads to map current permissions

Alex, successful implementation of these security features is a top priority for us. Don't hesitate to reach out directly - I can be reached at 659-555-1234 for urgent questions.

Would it be helpful to schedule a brief check-in next week to review the SSO testing results?

Best,
Brad

P.S. I noticed your team's interest in our advanced compliance reporting features - happy to arrange a dedicated session on those capabilities if valuable for your security review.`,
            },
          },
        },
      ],
    },
    {
      section: "Outreach",
      data: [
        {
          item_id: 3,
          item_type: "outreach",
          item_status: "active",
          item_title: "Sarah revisiting pricing page for upgrade",
          created_at: new Date(Date.now() - 2 * 60 * 1000),
          item_user: {
            user_name: "Sarah Chen",
            user_organization: {
              organization_name: "Oracle",
              organization_value: 280000,
              organization_top_tier: false,
              organization_entitlement: "under",
              organization_avatar:
                "https://cdn.iconscout.com/icon/free/png-256/free-oracle-logo-icon-download-in-svg-png-gif-file-formats--brand-development-tools-pack-logos-icons-226044.png?f=webp&w=256",
              renewal_date: "2025-02-15",
              economic_buyer: {
                name: "James Wilson",
                role: "VP of Engineering",
                avatar: "https://i.pravatar.cc/150?img=61",
              },
            },
            user_avatar: "https://i.pravatar.cc/150?img=25",
            user_email: "schen@oracle.com",
            user_role: "Product",
            user_metrics: {
              last_login: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000),
              recent_activity: [
                "Viewed pricing pages",
                "Compared enterprise plans",
                "Downloaded pricing PDF",
              ],
              average_session: [
                "Regular visits to pricing section",
                "Downloads documentation",
                "Logs in 2x/week, avg. 15m in product",
              ],
              recent_interactions: [
                { item_title: "Pricing consultation", created_at: "8/28" },
                { item_title: "Plan upgrade discussion", created_at: "8/15" },
              ],
            },
          },
          item_details: {
            summary:
              "Sarah has been frequently visiting the pricing pages and comparing enterprise plans. This activity suggests she may be evaluating pricing options for a potential upgrade.",
            supporting_data: [
              "Multiple visits to pricing documentation",
              "Downloaded enterprise comparison sheet",
              "Viewed upgrade options",
            ],
            account_insights: [
              "Oracle is a $280k ARR account with 3x expansion potential",
              "They're currently using basic tier - upgrade could be $500k+ opportunity",
              "Competitor X has been actively targeting their team",
            ],
            suggested_action:
              "Schedule a pricing consultation to discuss upgrade options",
            draft_email: {
              email_to: "Sarah Chen",
              email_cc: "",
              email_subject: "Questions about our pricing plans?",
              email_body: `Hi Sarah,

I noticed you've been exploring our pricing pages recently. I wanted to reach out to see if you had any questions about our plans or if you're considering an upgrade.

I'd be happy to walk you through the different options and help you find the best fit for your needs.

Best,
Brad
`,
            },
            relevant_context: [
              "Renewal discussion starting next month",
              "Recent budget approval for platform expansion",
              "Buyer expressed interest in premium features",
            ],
            retention_risks: [
              "Only 35% of purchased seats logged in last month",
              "Documentation visits dropped 50% month-over-month",
              "No product login from economic buyer in 60 days",
            ],
            growth_opportunities: [],
          },
        },
        {
          item_id: 4,
          item_type: "outreach",
          item_status: "active",
          item_title: "Migrating data out of product",
          created_at: new Date(Date.now() - 4 * 60 * 60 * 1000),
          item_user: {
            user_name: "Maria Rodriguez",
            user_organization: {
              organization_name: "Oracle",
              organization_value: 280000,
              organization_top_tier: false,
              organization_entitlement: "under",
              organization_avatar:
                "https://cdn.iconscout.com/icon/free/png-256/free-oracle-logo-icon-download-in-svg-png-gif-file-formats--brand-development-tools-pack-logos-icons-226044.png?f=webp&w=256",
              renewal_date: "2025-02-15",
              economic_buyer: {
                name: "James Wilson",
                role: "VP of Engineering",
                avatar: "https://i.pravatar.cc/150?img=61",
              },
            },
            user_avatar: "https://i.pravatar.cc/150?img=47",
            user_email: "mrodriguez@oracle.com",
            user_role: "Engineer",
            user_metrics: {
              last_login: new Date(),
              recent_activity: [
                "Downloaded 3 complete db exports",
                "Accessed migration docs 12 times",
                "Enabled advanced monitoring",
              ],
              average_session: [
                "Spends 45m/day in data console",
                "Regular schema validation checks",
                "Frequent access to backup configs",
                "Logs in daily, avg. 2.5h in product",
              ],
              recent_interactions: [
                { item_title: "Performance review", created_at: "8/15" },
                { item_title: "Schema design consultation", created_at: "8/1" },
                {
                  item_title: "Initial architecture planning",
                  created_at: "7/25",
                },
              ],
            },
          },
          item_details: {
            summary:
              "Maria has shown patterns suggesting she may be evaluating alternative platforms. Her recent activities indicate a critical opportunity to demonstrate our platform's unique value and ensure her team's needs are being fully met.",
            supporting_data: [
              "Exported over 500GB of data across multiple collections in the past week",
              "High engagement with migration-related documentation",
              "Significant increase in monitoring and performance tracking activities",
              "Recent expansion of API permissions and tooling access",
            ],
            account_insights: [
              "Oracle is our #3 target account for this quarter",
              "Risk of churn could impact $280k ARR",
              "Data migration patterns often precede platform switches",
            ],
            suggested_action:
              "Schedule technical review to demonstrate platform value",
            draft_email: {
              email_to: "Maria Rodriguez",
              email_cc: "Technical Architecture Team",
              email_subject: "Let's discuss your data architecture needs",
              email_body: `Hi Maria,

I noticed your team has been working extensively with our platform's data management features recently. Given the scale of your operations, I wanted to reach out to ensure you're getting the most value from our enterprise capabilities.

We've recently enhanced our performance optimization tools and introduced several features that could significantly improve your data operations. I'd love to schedule a brief technical review to share these updates and understand your specific requirements better.

Many of our enterprise customers have found our advanced clustering and data distribution features particularly valuable for large-scale operations. I'd be happy to connect you with our solution architects to explore how these capabilities could benefit your use case.

Best,
Brad
`,
            },
            relevant_context: [
              "Buyer mentioned performance concerns last week",
              "Team evaluating competitive solutions",
              "Critical migration project deadline approaching",
            ],
            retention_risks: [
              "Only 35% of purchased seats logged in last month",
              "Documentation visits dropped 50% month-over-month",
              "No product login from economic buyer in 60 days",
            ],
            growth_opportunities: [],
          },
        },
        {
          item_id: 5,
          item_type: "outreach",
          item_status: "active",
          item_title: "Increased documentation activity",
          created_at: new Date(Date.now() - 8 * 60 * 60 * 1000),
          item_user: {
            user_name: "Michael Smith",
            user_organization: {
              organization_name: "Netflix",
              organization_value: 820000,
              organization_top_tier: true,
              organization_entitlement: "over",
              organization_avatar:
                "https://assets.nflxext.com/us/ffe/siteui/common/icons/nficon2016.ico",
              renewal_date: "2025-01-07",
              economic_buyer: {
                name: "Rachel Park",
                role: "Director of Engineering",
                avatar: "https://i.pravatar.cc/150?img=45",
              },
            },
            user_avatar: "https://i.pravatar.cc/150?img=51",
            user_email: "msmith@netflix.com",
            user_role: "DevOps",
            user_metrics: {
              last_login: new Date(Date.now() - 12 * 60 * 60 * 1000),
              recent_activity: [
                "Multiple visits to best practices docs",
                "Downloaded implementation guides",
                "Accessed API documentation",
              ],
              average_session: [
                "Daily documentation visits",
                "Regular API reference checks",
                "Logs in 5x/day, avg. 45m in product",
              ],
              recent_interactions: [
                { item_title: "Implementation review", created_at: "8/29" },
                { item_title: "Architecture discussion", created_at: "8/20" },
              ],
            },
          },
          item_details: {
            summary:
              "Michael's team has shown increased activity in our documentation, particularly around implementation patterns and best practices. This could indicate they're working on a new feature or implementation.",
            supporting_data: [
              "Frequent visits to implementation guides",
              "Multiple documentation downloads",
              "High engagement with API reference",
            ],
            account_insights: [
              "$820k ARR at stake - our largest media customer",
              "Current usage indicates 2x expansion opportunity",
              "Strategic reference for media industry vertical",
            ],
            suggested_action:
              "Offer implementation guidance and best practices review",
            draft_email: {
              email_to: "Michael Chang",
              email_subject: "Help with your implementation?",
              email_body: `Hi Michael,

I noticed you've been exploring our documentation extensively recently. I wanted to reach out to see if you're working on a new implementation or have any questions about best practices.

Our team has extensive experience with similar implementations. I'd be happy to set up a call to discuss your use case and share relevant patterns we've seen work well.

Best,
Brad
`,
            },
            relevant_context: [
              "Expansion discussion next quarter",
              "User reports directly to buyer",
              "Strategic reference account for media vertical",
            ],
            retention_risks: ["Decreased login frequency from admin users"],
            growth_opportunities: [
              "90% of available seats actively used last month",
              "Consistent usage of analytics dashboard by 3 teams",
              "Multiple teams accessing enterprise documentation",
            ],
          },
        },
        {
          item_id: 6,
          item_type: "outreach",
          item_status: "active",
          item_title: "Exploring sharding options",
          created_at: new Date(Date.now() - 16 * 60 * 60 * 1000),
          item_user: {
            user_name: "Emma Wilson",
            user_organization: {
              organization_name: "Spotify",
              organization_value: 650000,
              organization_top_tier: false,
              organization_entitlement: "healthy",
              organization_avatar: "https://www.spotify.com/favicon.ico",
              renewal_date: "2024-12-22",
              economic_buyer: {
                name: "Mike Thompson",
                role: "Head of Infrastructure",
                avatar: "https://i.pravatar.cc/150?img=17",
              },
            },
            user_avatar: "https://i.pravatar.cc/150?img=32",
            user_email: "ewilson@spotify.com",
            user_role: "Architecture",
            user_metrics: {
              last_login: new Date(Date.now() - 4 * 60 * 60 * 1000),
              recent_activity: [
                "Reviewed sharding documentation",
                "Accessed cluster scaling guides",
                "Downloaded architecture whitepapers",
              ],
              average_session: [
                "Regular architecture reviews",
                "Cluster monitoring",
                "Logs in 3x/week, avg. 1h in product",
              ],
              recent_interactions: [
                { item_title: "Architecture consultation", created_at: "8/25" },
                {
                  item_title: "Scaling strategy discussion",
                  created_at: "8/10",
                },
              ],
            },
          },
          item_details: {
            summary:
              "Emma is researching sharding and distributed architecture options, indicating potential scale planning for their growing deployment.",
            supporting_data: [
              "Multiple visits to sharding documentation",
              "Downloaded distributed systems whitepaper",
              "Reviewing cluster configuration options",
            ],
            account_insights: [
              "$650k ARR with identified $1.2M expansion opportunity",
              "Sharding interest indicates major growth - perfect timing",
              "Technical blocker could delay expansion by 6+ months",
            ],
            suggested_action:
              "Share scaling best practices and architecture guidance",
            draft_email: {
              email_to: "Emma Wilson",
              email_subject: "Planning for scale? Let's chat",
              email_body: `Hi Emma,

I noticed you've been exploring our sharding and distributed architecture documentation. Are you planning for future scale?

I'd love to schedule some time to discuss your scaling strategy and share some best practices we've learned from similar deployments.

Best,
Brad
`,
            },
            relevant_context: [
              "Infrastructure review meeting next week",
              "Buyer concerned about scaling costs",
              "Competitive POC in progress",
            ],
            retention_risks: ["Dashboard engagement declined 20% this month"],
            growth_opportunities: [
              "5 users viewed enterprise features this week",
              "Increased usage of reporting features",
            ],
          },
        },
        {
          item_id: 7,
          item_type: "outreach",
          item_status: "active",
          item_title: "Security compliance review",
          created_at: new Date(Date.now() - 24 * 60 * 60 * 1000),
          item_user: {
            user_name: "David Kim",
            user_organization: {
              organization_name: "Airbnb",
              organization_value: 720000,
              organization_top_tier: true,
              organization_entitlement: "over",
              organization_avatar:
                "https://a0.muscache.com/airbnb/static/icons/android-icon-192x192-c0465f9f0380893768972a31a614b670.png",
              renewal_date: "2025-01-31",
              economic_buyer: {
                name: "Sarah Martinez",
                role: "VP of Platform Engineering",
                avatar: "https://i.pravatar.cc/150?img=44",
              },
            },
            user_avatar: "https://i.pravatar.cc/150?img=68",
            user_email: "dkim@airbnb.com",
            user_role: "Security",
            user_metrics: {
              last_login: new Date(Date.now() - 1 * 60 * 60 * 1000),
              recent_activity: [
                "Accessed security documentation",
                "Downloaded compliance reports",
                "Reviewed audit logs",
              ],
              average_session: [
                "Daily security reviews",
                "Regular compliance checks",
                "Logs in daily, avg. 30m in product",
              ],
              recent_interactions: [
                { item_title: "Security assessment", created_at: "8/28" },
                { item_title: "Compliance review", created_at: "8/15" },
              ],
            },
          },
          item_details: {
            summary:
              "David is conducting a comprehensive security review, focusing on compliance documentation and security features.",
            supporting_data: [
              "Reviewing security certifications",
              "Accessing compliance documentation",
              "Analyzing audit capabilities",
            ],
            account_insights: [
              "$720k ARR at risk if security requirements aren't met",
              "Security approval unlocks $500k expansion opportunity",
              "Competitor Y recently got SOC 2 certification",
            ],
            suggested_action:
              "Provide additional security documentation and compliance details",
            draft_email: {
              email_to: "David Kim",
              email_subject: "Supporting your security review",
              email_body: `Hi David,

I noticed you've been reviewing our security documentation and compliance materials. I wanted to reach out to see if you need any additional information or clarification.

We have extensive security resources and can arrange a detailed review with our security team if helpful.

Best,
Brad
`,
            },
            relevant_context: [
              "Security audit due next month",
              "Buyer prioritizing infrastructure stability",
              "Recent outage escalated to executive team",
            ],
            retention_risks: [],
            growth_opportunities: [
              "High engagement with security documentation",
              "8 teams accessing advanced feature guides",
              "Increased usage of team management features",
            ],
          },
        },
      ],
    },
  ],
};

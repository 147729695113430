import React, { useEffect, useState, useCallback } from "react";
import {
  DataList,
  Flex,
  Heading,
  Text,
  Tabs,
  Table,
  Box,
  Button,
  IconButton,
  ScrollArea,
} from "@radix-ui/themes";
import { CopyIcon, CheckIcon, PlusIcon } from "@radix-ui/react-icons";
import { Settings } from "lucide-react";
import { useOrganization } from "./context/OrganizationContext";
import NewTouchpointLocationDialog from "./NewTouchpointLocationDialog";
import { grayA } from "@radix-ui/colors";

const Toolbar = () => {
  return (
    <Flex
      direction="column"
      justify="between"
      align="center"
      style={{ borderBottom: `1px solid ${grayA.grayA4}` }}
    >
      <Flex
        align="center"
        justify="between"
        px="5"
        style={{
          width: "100%",
          maxWidth: 1024,
          height: 60,
        }}
      >
        <Flex align="center" gap="3">
          <Settings size={20} strokeWidth={2} color={grayA.grayA8} />
          <Text size="5" style={{ fontWeight: 600 }}>
            Organization settings
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const CopyButton = ({ textToCopy, title }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  }, [textToCopy]);

  return (
    <IconButton
      size="1"
      title={title}
      color={copied ? "green" : "gray"}
      variant="ghost"
      style={{ opacity: copied ? 1 : 0.5 }}
      onClick={handleCopy}
    >
      {copied ? <CheckIcon /> : <CopyIcon />}
    </IconButton>
  );
};

const LocationRow = ({ location }) => {
  return (
    <Table.Row>
      <Table.Cell maxWidth="72px">
        <Flex align="center" gap="2">
          <CopyButton
            textToCopy={location.location_id}
            title="Copy location id"
          />
          <Text truncate="true" title={location.location_name}>
            {location.location_name}
          </Text>
        </Flex>
      </Table.Cell>
      <Table.Cell maxWidth="96px">
        <Flex>
          <Text wrap="wrap" title={location.location_description}>
            {location.location_description}
          </Text>
        </Flex>
      </Table.Cell>
      <Table.Cell maxWidth="96px">
        <Flex>
          <Text wrap="wrap">
            <DataList.Root>
              {location.location_fields.map((field) => (
                <DataList.Item key={field.field_id}>
                  <DataList.Label minWidth="64">
                    <Flex align="center" gap="2">
                      <CopyButton
                        textToCopy={field.field_id}
                        title="Copy field id"
                      />
                      {field.field_name}
                    </Flex>
                  </DataList.Label>
                  <DataList.Value>{field.field_description}</DataList.Value>
                </DataList.Item>
              ))}
            </DataList.Root>
          </Text>
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
};

const LocationsTable = ({ locations }) => {
  return (
    <Table.Root variant="surface">
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell width="20%">Name</Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell width="30%">
            Description
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell width="40%">Fields</Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {locations.length > 0 ? (
          locations.map((location) => (
            <LocationRow key={location.location_id} location={location} />
          ))
        ) : (
          <Table.Row>
            <Table.Cell colSpan="5">
              <Flex justify="center" py="4">
                <Text color="gray">
                  No touchpoint locations. Click the "Add" button above to get
                  started.
                </Text>
              </Flex>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table.Root>
  );
};

const OrganizationSettings = () => {
  const { touchpointLocations, getTouchpointLocations } = useOrganization();

  useEffect(() => {
    getTouchpointLocations();
  }, [getTouchpointLocations]);

  return (
    <Flex direction="column">
      <Toolbar />
      <ScrollArea
        scrollbars="vertical"
        type="scroll"
        style={{ height: "calc(100vh - 80px)" }}
      >
        <Flex
          direction="column"
          gap="5"
          style={{
            maxWidth: 1024,
            minWidth: 860,
            margin: "0 auto",
            padding: "24px 24px 48px",
            width: "100%",
          }}
        >
          <Flex justify="between" align="center" my="5">
            <Flex direction="column">
              <Heading size="3">Touchpoint locations</Heading>
              <Text color="gray">
                Designated areas for marketing touchpoints. Developers can add
                locations by integrating our components.
              </Text>
            </Flex>
            <NewTouchpointLocationDialog
              trigger={
                <Button size="2">
                  <PlusIcon />
                  Add
                </Button>
              }
            />
          </Flex>
          <LocationsTable locations={touchpointLocations} />
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

export default OrganizationSettings;

import { useReactFlow } from "reactflow";
import { NODE_STATUS } from "../utils/constants";
import { gray } from "@radix-ui/colors";

const DEFAULT_PROPS = {
  className: "nopan",
  style: { opacity: 0, cursor: "default" },
  position: { x: 0, y: 0 },
  deletable: false,
  draggable: false,
};

// todo: make generic nodes functions
const useFlowActions = () => {
  const { setNodes, setEdges } = useReactFlow();

  const updateNode = (id, updates) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, ...updates } } : node
      )
    );
  };

  return {
    createUserCountNode: (event) => ({
      id: "userCount",
      type: "userCount",
      data: {
        event,
        updateStatus: (status) =>
          updateNode("userCount", {
            status,
          }),
      },
      ...DEFAULT_PROPS,
      selectable: false,
    }),

    createEventGroupNode: (event_group, selected) => ({
      id: `eventGroup-${event_group.group_id}`,
      type: "eventGroup",
      data: { event_group },
      ...DEFAULT_PROPS,
      selected,
    }),

    createEventsNode: (events, parentNode) => ({
      id: `events-${parentNode.id}`,
      type: "events",
      data: { events },
      ...DEFAULT_PROPS,
    }),

    createBadgeNode: (label, color, parentNode) => ({
      id: `badge-${parentNode.id}-${label}`,
      type: "badge",
      data: { label, color },
      ...DEFAULT_PROPS,
      position: {
        x: parentNode.position.x + parentNode.width / 2,
        y: parentNode.position.y + parentNode.height,
      },
    }),

    createPlaceholderNode: (segment, parentNode) => {
      const nodeId = `placeholder`;
      return {
        id: nodeId,
        type: "placeholder",
        data: {
          status: NODE_STATUS.CREATING,
          segment,
          deleteNode: () => {
            setNodes((nds) => nds.filter((node) => node.id !== nodeId));
            setEdges((eds) => eds.filter((edge) => edge.target !== nodeId));
          },
        },
        selected: true,
        ...DEFAULT_PROPS,
        ...(parentNode && {
          position: {
            x: parentNode.position.x + parentNode.width / 2,
            y: parentNode.position.y + parentNode.height,
          },
        }),
      };
    },

    createVariantNode: (variant, isNew = false) => {
      const nodeId = `variant-${variant.variant_id}`;

      return {
        id: nodeId,
        type: "variant",
        data: {
          variant,
          status: isNew ? NODE_STATUS.CREATING : null,
          updateStatus: (status) =>
            updateNode(nodeId, {
              status,
            }),
          deleteNode: () => {
            setNodes((nds) => nds.filter((node) => node.id !== nodeId));
            setEdges((eds) => eds.filter((edge) => edge.target !== nodeId));
          },
        },
        selected: isNew,
        ...DEFAULT_PROPS,
      };
    },

    createUserCriteriaNode: (touchpoint) => ({
      id: "userCriteria",
      data: {
        conditions: touchpoint?.eligibility_criteria?.user_criteria || [],
        onChange: (updates) =>
          updateNode("userCriteria", { conditions: updates }),
      },
      type: "userCriteria",
      ...DEFAULT_PROPS,
    }),

    createSessionCriteriaNode: (touchpoint) => ({
      id: "sessionCriteria",
      data: {
        conditions: touchpoint?.eligibility_criteria?.session_criteria || [],
        onChange: (updates) =>
          updateNode("sessionCriteria", { conditions: updates }),
      },
      type: "sessionCriteria",
      ...DEFAULT_PROPS,
    }),

    createLocationNode: (touchpoint) => ({
      id: "location",
      type: "location",
      data: {
        selectedLocation: touchpoint.touchpoint_location,
        onChange: (updates) =>
          updateNode("location", {
            selectedLocation: updates.selectedLocation,
          }),
      },
      ...DEFAULT_PROPS,
    }),

    createVariantRootNode: () => ({
      id: "variantRoot",
      type: "variantRoot",
      data: {},
      ...DEFAULT_PROPS,
    }),

    createSegmentRootNode: () => ({
      id: "segmentRoot",
      type: "segmentRoot",
      data: {
        updateStatus: (status) =>
          updateNode("segmentRoot", {
            status,
          }),
      },
      ...DEFAULT_PROPS,
    }),

    createSegmentNode: (segment, isSelected) => ({
      id: `segment-${segment.segment_id}`,
      type: "segment",
      data: { segment },
      ...DEFAULT_PROPS,
      selected: isSelected,
    }),

    createEdge: (source, target, strokeColor = gray.gray7, hidden = false) => ({
      id: `edge-${source}-${target}`,
      source,
      target,
      hidden,
      style: {
        opacity: 0,
        stroke: strokeColor,
      },
    }),
  };
};

export default useFlowActions;

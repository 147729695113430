import React from "react";
import { Handle } from "reactflow";
import { Flex, Text } from "@radix-ui/themes";
import { gray, violet } from "@radix-ui/colors";
import { useOpportunity } from "../context/OpportunityContext";
import Icon from "../Icon";
import { NODE_STYLE } from "../utils/constants";
import { abbrevNum, pluralize } from "../utils";

const ROTATED_SHADOW =
  "-2px -2px 0px 0px rgba(0, 0, 0, 0.05) inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.70) inset, 2px 2px 0px 0px rgba(0, 0, 0, 0.02) inset, -0.125px -0.125px 0px 0px rgba(0, 0, 0, 0.8) inset";

const UserCountNode = ({ data, selected }) => {
  const { opportunity } = useOpportunity();

  const size = !!opportunity
    ? opportunity.opportunity_audience.estimated_size
    : 0;

  return (
    <div>
      <Flex direction="column" position="relative">
        <Flex
          style={{
            ...NODE_STYLE,
            borderRadius: 8,
            width: 80,
            height: 80,
            transform: "rotate(45deg)",
            boxShadow: ROTATED_SHADOW,
            ...(selected && {
              border: "1px solid transparent",
              boxShadow: `0 0 0 2px ${violet.violet9}, ` + ROTATED_SHADOW,
            }),
          }}
        />
        <Icon
          name="PersonFilledIcon"
          color={gray.gray8}
          height={30}
          width={30}
          style={{
            position: "absolute",
            top: "34%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            opacity: 0.9,
          }}
        />
        <Text
          weight="medium"
          color="gray"
          style={{
            fontSize: 11,
            position: "absolute",
            top: "66%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            whiteSpace: "nowrap",
            opacity: 0.9,
          }}
        >
          {`${abbrevNum(size)} ${pluralize("user", size)}`}
        </Text>
      </Flex>
      <Handle
        type="source"
        position="bottom"
        isConnectable={false}
        style={{ visibility: "hidden" }}
      />
    </div>
  );
};

export default UserCountNode;

import React, { createContext, useCallback, useContext, useState } from "react";
import { useAuth } from "./AuthContext";
import { BASE_URL } from "../App";

const TouchpointContext = createContext();

export const useTouchpoint = () => useContext(TouchpointContext);

export const TouchpointProvider = ({ children }) => {
  const { user } = useAuth();
  const [touchpoint, setTouchpoint] = useState(null);

  const getTouchpoints = useCallback(async () => {
    const response = await fetch(
      `${BASE_URL}/api/v1/organizations/${user.organization_id}/touchpoints`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );
    const data = await response.json();
    return data.touchpoints;
  }, [user.organization_id]);

  const getTouchpoint = useCallback(
    async (touchpoint_id) => {
      const response = await fetch(
        `${BASE_URL}/api/v1/organizations/${user.organization_id}/touchpoints/${touchpoint_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const data = await response.json();
      return data;
    },
    [user.organization_id]
  );

  const getDraftTouchpoint = useCallback(
    async (opportunityId) => {
      const response = await fetch(
        `${BASE_URL}/api/v1/organizations/${user.organization_id}/opportunities/${opportunityId}/draft-touchpoint`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const data = await response.json();
      return data;
    },
    [user.organization_id]
  );

  const getDismissalInsights = useCallback(
    async (touchpoint_external_id) => {
      const response = await fetch(
        `${BASE_URL}/api/v1/organizations/${user.organization_id}/touchpoints/${touchpoint_external_id}/insights/dismissals`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const data = await response.json();
      return data.snap_dismissal_patterns.reverse(); // todo: remove once backend is fixed
    },
    [user.organization_id]
  );

  const createTouchpoint = useCallback(
    async (touchpoint) => {
      const response = await fetch(
        `${BASE_URL}/api/v1/organizations/${user.organization_id}/touchpoints`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(touchpoint),
          credentials: "include",
        }
      );
      const data = await response.json();
      return data.touchpoint_id;
    },
    [user.organization_id]
  );

  const createVariant = useCallback(
    async (touchpoint_id, variant) => {
      const response = await fetch(
        `${BASE_URL}/api/v1/organizations/${user.organization_id}/touchpoints/${touchpoint_id}/variants`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(variant),
          credentials: "include",
        }
      );
      const data = await response.json();
      return data.variant_id;
    },
    [user.organization_id]
  );

  return (
    <TouchpointContext.Provider
      value={{
        getTouchpoints,
        getTouchpoint,
        getDraftTouchpoint,
        getDismissalInsights,
        createTouchpoint,
        createVariant,
        touchpoint,
        setTouchpoint,
      }}
    >
      {children}
    </TouchpointContext.Provider>
  );
};

import React, { useMemo, useState } from "react";
import {
  TextField,
  Button,
  Flex,
  Text,
  Heading,
  IconButton,
  TextArea,
  Select,
  ScrollArea,
} from "@radix-ui/themes";
import { Cross2Icon } from "@radix-ui/react-icons";
import { gray, grayA } from "@radix-ui/colors";
import {
  Component,
  AppWindow,
  Zap,
  Wrench,
  Split,
  Target,
  TextIcon,
  Shapes,
  CircleHelp,
} from "lucide-react";
import { useReactFlow } from "reactflow";
import { cloneDeep, set } from "lodash";
import useEscapeKey from "./hooks/useEscapeKey";
import { useOrganization } from "./context/OrganizationContext";
import { NODE_STATUS } from "./utils/constants";
import ConditionBuilder from "./ConditionBuilder";
import { useTouchpoint } from "./context/TouchpointContext";
import { useNavigate } from "react-router-dom";

const VARIANT_FIELDS = [
  {
    field_id: "variant_name",
    field_name: "Name",
    field_type: "text",
    field_required: true,
  },
  {
    field_id: "variant_description",
    field_name: "Description",
    field_type: "text",
    field_required: false,
  },
];

const InputLabel = ({ label, description, error, children }) => (
  <label>
    <Flex justify="between" align="center" mb="1">
      <Text title={description} as="div" size="1" color="gray" weight="medium">
        {label}
      </Text>
      {error && (
        <Text color="red" size="1">
          {error}
        </Text>
      )}
    </Flex>
    {children}
  </label>
);

const SectionHeader = ({ icon: Icon, text, ...styleProps }) => (
  <Flex align="center" gap="2" mt="5" {...styleProps}>
    <Icon color={gray.gray9} size={16} strokeWidth={1.75} />
    <Text size="2" color="gray" weight="medium">
      {text}
    </Text>
  </Flex>
);

const Form = ({ variant, status, touchpoint, onChange, onSave, onCancel }) => {
  const [errors, setErrors] = useState({});

  if (!touchpoint.touchpoint_location) return null;

  const isDefaultVariant = variant.is_default;
  const isNewTouchpoint = !touchpoint.touchpoint_id;

  const validateForm = () => {
    const newErrors = {};

    // Validate fields directly on variant
    if (variant.variant_name?.trim() === "") {
      newErrors["variant.variant_name"] = "Name is required";
    }

    // Validate fields in variant_data
    touchpoint.touchpoint_location.location_fields.forEach((field) => {
      const value = variant.variant_data[field.field_id];
      if (field.field_required && (!value || !value.trim())) {
        newErrors[`variant.variant_data.${field.field_id}`] =
          `${field.field_name} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (key, value) => {
    onChange(key, value);

    if (errors[key]) {
      setErrors((prevErrors) => ({ ...prevErrors, [key]: null }));
    }
  };

  const handleCreate = () => {
    if (validateForm()) {
      // todo: clean this up, error states for ConditionBuilder
      variant.eligibility_criteria = variant.eligibility_criteria.filter(
        (c) => c.field && c.operator && String(c.value).trim()
      );
      onSave();
    }
  };

  const renderField = (field, isDirectField = false) => {
    let InputComponent;
    switch (field.field_type) {
      case "text":
      case "url":
        InputComponent = TextField.Root;
        break;
      case "textarea":
        InputComponent = TextArea;
        break;
      case "select":
        InputComponent = Select.Root;
        break;
      default:
        InputComponent = TextField.Root;
    }

    const key = isDirectField
      ? `variant.${field.field_id}`
      : `variant.variant_data.${field.field_id}`;
    const value = isDirectField
      ? variant[field.field_id]
      : variant.variant_data[field.field_id] || "";

    return (
      <InputLabel
        key={field.field_id}
        label={field.field_name + (!field.field_required ? " (optional)" : "")}
        description={field.field_description}
        error={errors[key]}
      >
        <InputComponent
          placeholder={field.field_name}
          value={value}
          onChange={(e) => handleInputChange(key, e.target.value)}
          {...(errors[key] && {
            color: "red",
            style: { border: "2px solid #EC8E7B", boxShadow: "none" },
          })}
          {...(field.field_type === "textarea" && { style: { height: 80 } })}
        />
      </InputLabel>
    );
  };

  return (
    <Flex direction="column" style={{ height: "100%", width: 480 }}>
      <Flex
        align="center"
        justify="between"
        px="5"
        py="4"
        style={{ borderBottom: `1px solid ${gray.gray6}` }}
      >
        <Heading size="4" weight="medium">
          {status === NODE_STATUS.CREATING ? "New" : "Edit"}{" "}
          {isDefaultVariant ? "message" : "message variant"}
        </Heading>
        <IconButton variant="ghost" color="gray" onClick={onCancel}>
          <Cross2Icon />
        </IconButton>
      </Flex>

      <ScrollArea scrollbars="vertical">
        <Flex
          direction="column"
          gap="4"
          p="5"
          style={{ flexGrow: 1, overflow: "scroll" }}
        >
          {VARIANT_FIELDS.slice(0, 1).map((field) => renderField(field, true))}

          {!isDefaultVariant && (
            <>
              <Flex direction="column" gap="3">
                <SectionHeader icon={Target} text="Additional targeting?" />
                <Text>
                  {variant.eligibility_criteria.length > 0
                    ? "Users must meet ALL of the following conditions:"
                    : "None. Add conditions to narrow down variant recipients."}
                </Text>
              </Flex>
              <ConditionBuilder
                conditions={variant.eligibility_criteria}
                onChange={(updates) =>
                  onChange("variant.eligibility_criteria", updates)
                }
              />
            </>
          )}

          <SectionHeader icon={TextIcon} text="What to say?" />
          {touchpoint.touchpoint_location.location_fields.map((field) =>
            renderField({ ...field, field_type: "textarea" })
          )}
        </Flex>
      </ScrollArea>

      <Flex
        gap="3"
        justify="end"
        px="5"
        py="4"
        style={{ borderTop: `1px solid ${gray.gray6}` }}
      >
        <Button variant="soft" color="gray" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleCreate}>
          {/* {status === NODE_STATUS.CREATING ? "Create" : "Save"} */}
          Save
          {isNewTouchpoint && " & deploy"}
        </Button>
      </Flex>
    </Flex>
  );
};

const VariantForm = ({ activeNodeId }) => {
  const { getNode, setNodes } = useReactFlow();
  const navigate = useNavigate();
  const { createTouchpoint, createVariant, touchpoint } = useTouchpoint();

  const activeNode = getNode(activeNodeId);
  const initialData = useMemo(() => cloneDeep(activeNode.data), [activeNodeId]);

  const handleChange = (key, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === activeNode.id) {
          const data = { ...node.data };
          set(data, key, value);
          return { ...node, data };
        }
        return node;
      })
    );
  };

  const handleCancel = () => {
    if (activeNode.data.status === NODE_STATUS.CREATING) {
      return activeNode.data.deleteNode();
    }

    setNodes((nds) =>
      nds.map((node) =>
        node.id === activeNode.id
          ? { ...node, selected: false, data: { ...initialData, status: null } }
          : node
      )
    );
  };

  useEscapeKey(handleCancel);

  const handleSave = async () => {
    const { variant } = activeNode.data;
    const isNewTouchpoint = !touchpoint.touchpoint_id;

    if (isNewTouchpoint) {
      touchpoint.touchpoint_id = await createTouchpoint({
        ...touchpoint,
        touchpoint_location_id: touchpoint.touchpoint_location.location_id,
      });
    }

    await createVariant(touchpoint.touchpoint_id, variant);

    if (isNewTouchpoint) {
      navigate(`/touchpoints/${touchpoint.touchpoint_id}`);
    }

    // todo: update node with ids etc
    setNodes((nds) =>
      nds.map((node) =>
        node.id === activeNode.id
          ? { ...node, selected: false, data: { ...node.data, status: null } }
          : node
      )
    );
  };

  return (
    <Form
      variant={activeNode.data.variant}
      status={activeNode.data.status}
      touchpoint={touchpoint}
      onCancel={handleCancel}
      onChange={handleChange}
      onSave={handleSave}
    />
  );
};

export default VariantForm;

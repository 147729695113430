import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Flex,
  Badge,
  Text,
  ScrollArea,
  Button,
  Separator,
  Spinner,
} from "@radix-ui/themes";
import { gray, grayA } from "@radix-ui/colors";
import {
  Eye,
  Filter,
  MapPin,
  MessageSquareText,
  MessageSquareX,
} from "lucide-react";
import { useTouchpoint } from "./context/TouchpointContext";
import { pluralize, toPercent } from "./utils";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import DismissalPatternInspector from "./DismissalPatternInspector";
import { TouchpointPreview } from "./TouchpointEditor";
import { useOrganization } from "./context/OrganizationContext";

const Attribute = ({ icon: Icon, text }) => (
  <Flex align="center" gap="5px" style={{ opacity: 0.8 }}>
    <Icon size={14} color={grayA.grayA10} />
    <Text size="1" style={{ color: grayA.grayA10 }}>
      {text}
    </Text>
  </Flex>
);

const Toolbar = ({
  touchpoint_id,
  createdAt,
  variants,
  workflow,
  touchpoint_location,
}) => {
  return (
    <Flex
      direction="column"
      justify="between"
      align="center"
      style={{ borderBottom: `1px solid ${grayA.grayA4}` }}
    >
      <Flex
        align="center"
        justify="between"
        px="5"
        style={{
          width: "100%",
          maxWidth: 1024,
          height: 60,
        }}
      >
        <Badge variant="outline" color="green" style={{ width: "fit-content" }}>
          <span style={{ fontSize: 14, margin: "0 -1px 2px 1px" }}>•</span>
          Active
        </Badge>
        <Flex gap="5">
          <Flex gap="4" align="center">
            {/* <Attribute icon={Calendar} text={toTimeAgo(createdAt)} /> */}
            <Attribute
              icon={MessageSquareText}
              text={`${variants.length} ${pluralize("variant", variants.length)}`}
            />
            <Attribute icon={MapPin} text={touchpoint_location.location_name} />
          </Flex>
          <a
            href={`/touchpoints/${touchpoint_id}/edit`}
            style={{ textDecoration: "none" }}
          >
            <Button size="1">
              <ExternalLinkIcon />
              Open in editor
            </Button>
          </a>
        </Flex>
      </Flex>
    </Flex>
  );
};

const PerformanceStat = ({ icon: Icon, title, value }) => (
  <Flex
    direction="column"
    px="3"
    py="2"
    style={{
      backgroundColor: gray.gray2,
      borderRadius: 8,
      width: 124,
    }}
  >
    <Flex align="start" justify="between">
      <Text size="3" color="gray" weight="medium" style={{ lineHeight: 1.2 }}>
        {value}
      </Text>
      <Icon size={14} color={grayA.grayA9} style={{ opacity: 0.8 }} />
    </Flex>

    <Text weight="medium" color="gray" style={{ fontSize: 10, opacity: 0.7 }}>
      {title}
    </Text>
  </Flex>
);

const PerformanceStats = ({ performance }) => (
  <Flex gap="3">
    <PerformanceStat
      icon={Eye}
      title="Impressions"
      value={performance.total_impressions}
    />
    <PerformanceStat
      icon={Filter}
      title="Conversion"
      value={toPercent(performance.conversion_rate, 1)}
    />
    <PerformanceStat
      icon={MessageSquareX}
      title="Snap dismissal"
      value={toPercent(performance.snap_dismissal_rate, 1)}
    />
  </Flex>
);

const TouchpointDetail = () => {
  const { id } = useParams();
  const { touchpointLocations } = useOrganization();
  const { getTouchpoint, getDismissalInsights, touchpoint, setTouchpoint } =
    useTouchpoint();
  const [dismissalPatterns, setDismissalPatterns] = useState(null);

  useEffect(() => {
    const fetchTouchpointData = async () => {
      const [touchpoint, dismissalInsights] = await Promise.all([
        getTouchpoint(id),
        getDismissalInsights(id),
      ]);

      setTouchpoint(touchpoint);
      setDismissalPatterns(dismissalInsights);
    };

    fetchTouchpointData();

    // Cleanup
    return () => {
      setTouchpoint(null);
      setDismissalPatterns(null);
    };
  }, [getTouchpoint, getDismissalInsights, id, setTouchpoint]);

  if (!touchpoint) {
    return null;
  }

  const { touchpoint_name, touchpoint_description } = touchpoint;

  return (
    <Flex direction="column">
      <Toolbar {...touchpoint} />
      <ScrollArea
        scrollbars="vertical"
        type="scroll"
        style={{ height: "calc(100vh - 80px)" }}
      >
        <Flex
          direction="column"
          gap="2"
          style={{
            maxWidth: 1024,
            minWidth: 860,
            margin: "0 auto",
            padding: "40px 24px 48px",
            width: "100%",
          }}
        >
          <Flex gap="7" align="stretch">
            <Flex direction="column" mt="6" mb="9" gap="5" style={{ flex: 1 }}>
              <Flex direction="column" gap="2">
                <Text size="7" weight="medium">
                  {touchpoint_name}
                </Text>
                <Text size="2" color="gray">
                  {touchpoint_description}
                </Text>
              </Flex>
              {touchpoint.performance && <PerformanceStats {...touchpoint} />}
            </Flex>
            <Flex
              style={{
                flex: 1,
                width: 432,
                background: gray.gray2,
                borderRadius: 12,
                border: `1px solid ${grayA.grayA4}`,
                overflow: "hidden",
              }}
            >
              <TouchpointPreview />
            </Flex>
          </Flex>

          <Flex direction="column" gap="6">
            <Flex direction="column" gap="2">
              <Text size="5" weight="medium">
                Insights
              </Text>
              <Separator size="4" style={{ opacity: 0.5 }} />
            </Flex>
            {dismissalPatterns?.length > 0 ? (
              <>
                <Flex direction="column" gap="2">
                  <Text size="3" weight="medium">
                    What's not working
                  </Text>
                  <Text size="2" color="gray" style={{ maxWidth: 720 }}>
                    This touchpoint's performance suggests room for
                    optimization. The dismissal patterns below indicate various
                    reasons for user resistance, which are manifested in
                    different ways:
                  </Text>
                </Flex>
                <DismissalPatternInspector
                  dismissalPatterns={dismissalPatterns}
                />
              </>
            ) : (
              <>
                <Flex
                  direction="column"
                  gap="2"
                  py="6"
                  px="8"
                  style={{
                    backgroundColor: gray.gray2,
                    borderRadius: 12,
                  }}
                >
                  <Flex align="center" gap="2">
                    <Spinner size="1" />
                    <Text size="2" weight="medium">
                      Collecting performance data...
                    </Text>
                  </Flex>

                  <Text size="2" color="gray">
                    We're currently gathering data as users interact with this
                    touchpoint. This process may take some time depending on
                    user traffic. Check back soon for valuable insights that
                    will help you optimize your user experience and boost
                    engagement.
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

export default TouchpointDetail;

import React from "react";
import { useReactFlow } from "reactflow";
import { motion, AnimatePresence } from "framer-motion";
import VariantForm from "./VariantForm";
import AddNodeForm from "./AddNodeForm";

import EditSegmentsForm from "./EditSegmentsForm";
const formComponents = {
  variant: VariantForm,
  placeholder: AddNodeForm,
  segmentRoot: EditSegmentsForm,
};

const Sidebar = ({ activeNodeId }) => {
  const { getNode } = useReactFlow();
  const activeNode = activeNodeId && getNode(activeNodeId);
  const FormComponent = formComponents[activeNode?.type];

  return (
    <AnimatePresence>
      {activeNode && (
        <motion.div {...animationProps} style={style}>
          <FormComponent {...{ activeNodeId }} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Sidebar;

const animationProps = {
  initial: { x: 200, opacity: 0, scale: 0.95, rotate: 2 },
  animate: { x: 0, opacity: 1, scale: 1, rotate: 0 },
  exit: {
    x: 200,
    opacity: 0,
    scale: 0.95,
    rotate: 2,
    transition: { duration: 0.15 },
  },
  transition: {
    type: "spring",
    stiffness: 340,
    damping: 30,
  },
};

const style = {
  zIndex: 1000,
  position: "absolute",
  right: 24,
  top: 24,
  height: "calc(100vh - (8px * 2) - (24px * 2))", // main card padding & canvas padding
  background: "#fff",
  color: "#000",
  borderRadius: "12px",
  border: "1px solid #ddd",
  boxShadow:
    "0px 1px 3px 0px rgba(0, 0, 0, 0.01), 0px 2px 1px -1px rgba(0, 0, 0, 0.01), 0px 1px 4px 0px rgba(2, 2, 52, 0.08), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.01)",
  fontSize: "14px",
};

import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { FlowProvider } from "./context/FlowContext";
import Flow from "./Flow";
import useFlowActions from "./hooks/useFlowActions";
import { useTouchpoint } from "./context/TouchpointContext";
import { useState, useEffect } from "react";
import { useOpportunity } from "./context/OpportunityContext";
import { generateUUID } from "./utils";

const TouchpointEditor = ({ isPreview }) => {
  const { nodes, edges } = useFlowData();

  if (!nodes.length) return null;
  return (
    <Flow initialNodes={nodes} initialEdges={edges} isPreview={isPreview} />
  );
};

const withProviders = (Component) => (props) => (
  <ReactFlowProvider>
    <FlowProvider>
      <Component {...props} />
    </FlowProvider>
  </ReactFlowProvider>
);

export default withProviders(TouchpointEditor);

export const TouchpointPreview = withProviders((props) => (
  <TouchpointEditor {...props} isPreview />
));

// Custom hook for managing flow data
const useFlowData = () => {
  const { id: touchpointId } = useParams();
  const [searchParams] = useSearchParams();
  const opportunityId = searchParams.get("opportunity");
  const { getTouchpoint, getDraftTouchpoint, setTouchpoint } = useTouchpoint();
  const { getOpportunity, setOpportunity } = useOpportunity();
  const flowActions = useFlowActions();
  const [flowData, setFlowData] = useState({ nodes: [], edges: [] });

  useEffect(() => {
    const fetchTouchpoint = async () => {
      let touchpoint;
      if (touchpointId) {
        touchpoint = await getTouchpoint(touchpointId);
      } else if (opportunityId) {
        const draftTouchpoint = await getDraftTouchpoint(opportunityId);
        touchpoint = {
          ...draftTouchpoint,
          variants: [createInitialVariant()],
        };
      } else {
        touchpoint = createBlankTouchpoint();
      }
      setTouchpoint(touchpoint);

      setFlowData({
        nodes: initializeNodes(touchpoint, opportunityId, flowActions),
        edges: initializeEdges(touchpoint, flowActions),
      });
    };

    const fetchOpportunity = async () => {
      const opportunity = opportunityId
        ? await getOpportunity(opportunityId)
        : null;
      setOpportunity(opportunity);
    };

    fetchTouchpoint();
    fetchOpportunity();

    // Cleanup
    return () => {
      setTouchpoint(null);
      setOpportunity(null);
    };
  }, [
    touchpointId,
    getTouchpoint,
    setTouchpoint,
    opportunityId,
    getOpportunity,
    setOpportunity,
  ]);

  return flowData;
};

const createInitialVariant = () => ({
  variant_id: generateUUID(),
  variant_name: "",
  variant_description: "",
  eligibility_criteria: [],
  variant_data: {},
  is_default: true,
});

const createBlankTouchpoint = () => ({
  touchpoint_name: "Untitled touchpoint",
  touchpoint_description: "",
  touchpoint_location: null,
  eligibility_criteria: [],
  variants: [createInitialVariant()],
});

// Helper function to initialize nodes
const initializeNodes = (
  touchpoint,
  opportunityId,
  {
    createUserCriteriaNode,
    createSessionCriteriaNode,
    createLocationNode,
    createVariantRootNode,
    createVariantNode,
  }
) => [
  createUserCriteriaNode(touchpoint),
  createSessionCriteriaNode(touchpoint),
  createLocationNode(touchpoint),
  createVariantRootNode(),
  ...touchpoint.variants.map((variant) =>
    createVariantNode(variant, !!opportunityId)
  ),
];

// Helper function to initialize edges
const initializeEdges = (touchpoint, { createEdge }) => [
  createEdge("userCriteria", "sessionCriteria"),
  createEdge("sessionCriteria", "location"),
  createEdge("location", "variantRoot"),
  ...touchpoint.variants.map((variant) =>
    createEdge("variantRoot", `variant-${variant.variant_id}`)
  ),
];

import { Flex, Text } from "@radix-ui/themes";
import { violet, grayA } from "@radix-ui/colors";
import {
  Users,
  SquareUserRound,
  Building,
  KeyRound,
  MonitorX,
  MessageSquareX,
  OctagonX,
  CircleAlert,
} from "lucide-react";
import { useState } from "react";
import { ScrollArea, Select } from "@radix-ui/themes";
import { gray } from "@radix-ui/colors";
import SessionPreview from "./SessionPreview";
import { abbrevNum, pluralize } from "./utils";
import SessionIcon from "./assets/session.svg";

const Attribute = ({ icon: Icon, text }) => (
  <Flex align="center" gap="5px" style={{ opacity: 0.8 }}>
    <Icon size={14} color={grayA.grayA10} />
    <Text size="1" style={{ color: grayA.grayA10 }}>
      {text}
    </Text>
  </Flex>
);

const DismissalPattern = ({
  pattern_name,
  pattern_description,
  isSelected,
  onClick,
}) => (
  <Flex
    onClick={onClick}
    direction="column"
    gap="1"
    px="20px"
    py="4"
    style={{
      borderColor: isSelected ? violet.violet9 : grayA.grayA4,
      borderWidth: isSelected ? 2 : 1,
      borderStyle: "solid",
      cursor: "pointer",
      borderRadius: 12,
      width: 368,
      height: 92,
      boxShadow:
        "0px 1px 3px 0px rgba(0, 0, 0, 0.01), 0px 2px 1px -1px rgba(0, 0, 0, 0.01), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.01)",
    }}
  >
    <Flex align="center" justify="between">
      <Flex align="center" gap="2">
        <CircleAlert
          size={14}
          strokeWidth={2.25}
          color={isSelected ? violet.violet9 : grayA.grayA10}
        />
        <Text
          size="2"
          weight="medium"
          style={{ color: isSelected ? violet.violet10 : grayA.grayA12 }}
        >
          {pattern_name}
        </Text>
      </Flex>
    </Flex>
    <Text
      title={pattern_description}
      size="1"
      color="gray"
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
      }}
    >
      {pattern_description}
    </Text>
  </Flex>
);

const DismissalPatternsRow = ({
  dismissalPatterns,
  selectedDismissalPatternId,
  onDismissalPatternSelect,
}) => (
  <ScrollArea scrollbars="horizontal" style={{ width: "100%" }}>
    <Flex align="center" gap="2" style={{ paddingBottom: "16px" }}>
      {dismissalPatterns.map((pattern) => (
        <DismissalPattern
          key={pattern.pattern_id}
          {...pattern}
          isSelected={pattern.pattern_id === selectedDismissalPatternId}
          onClick={() => onDismissalPatternSelect(pattern.pattern_id)}
        />
      ))}
    </Flex>
  </ScrollArea>
);

const Sessions = ({ sessions }) => {
  const [selectedSessionId, setSelectedSessionId] = useState(
    sessions[0].session_id
  );

  const selectedSession = sessions.find(
    (session) => session.session_id === selectedSessionId
  );

  const selectedSessionIndex = sessions.findIndex(
    (session) => session.session_id === selectedSessionId
  );

  return (
    <Flex
      style={{
        border: `1px solid ${grayA.grayA4}`,
        borderRadius: 12,
        boxShadow:
          "0px 1px 3px 0px rgba(0, 0, 0, 0.01), 0px 2px 1px -1px rgba(0, 0, 0, 0.01), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.01)",
        overflow: "hidden",
      }}
    >
      <Flex
        direction="column"
        style={{ flex: 1, borderRight: `1px solid ${grayA.grayA4}` }}
      >
        <Flex
          align="center"
          justify="between"
          px="4"
          py="3"
          style={{ borderBottom: `1px solid ${grayA.grayA4}` }}
        >
          <Flex align="center" gap="3">
            <img
              src={SessionIcon}
              alt="Session"
              style={{ width: 22, height: 22 }}
            />
            <Select.Root
              value={selectedSession.session_id}
              onValueChange={setSelectedSessionId}
            >
              <Select.Trigger variant="ghost" size="1" color="gray">
                <Text size="2" weight="medium" mr="3">
                  Example session {selectedSessionIndex + 1}
                </Text>
              </Select.Trigger>
              <Select.Content position="popper">
                {sessions.map((session, index) => (
                  <Select.Item
                    key={session.session_id}
                    value={session.session_id}
                  >
                    Example session {index + 1}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </Flex>
          {/* <Flex align="center" gap="4">
            <Attribute icon={SquareUserRound} text="Developer" />
            <Attribute icon={Building} text="Acme Inc" />
            <Attribute icon={KeyRound} text="Last login 3d ago" />
          </Flex> */}
        </Flex>
        <ScrollArea scrollbars="vertical" style={{ maxHeight: 480 }}>
          <Flex direction="column" pt="16px" p="20px">
            <Text size="2" color="gray" style={{ whiteSpace: "pre-wrap" }}>
              {selectedSession.dismissal_rationale}
            </Text>
          </Flex>
        </ScrollArea>
      </Flex>
      <Flex
        style={{
          minHeight: 372,
          width: 360,
          backgroundColor: gray.gray2,
        }}
      >
        {/* <SessionPreview session={selectedSession.archetypal_session} /> */}
      </Flex>
    </Flex>
  );
};

const DismissalPatternInspector = ({ dismissalPatterns }) => {
  const [selectedDismissalPatternId, setSelectedDismissalPatternId] = useState(
    dismissalPatterns[0].pattern_id
  );

  const selectedDismissalPattern = dismissalPatterns.find(
    (pattern) => pattern.pattern_id === selectedDismissalPatternId
  );

  return (
    <Flex direction="column" gap="2">
      <DismissalPatternsRow
        dismissalPatterns={dismissalPatterns}
        selectedDismissalPatternId={selectedDismissalPatternId}
        onDismissalPatternSelect={setSelectedDismissalPatternId}
      />
      {selectedDismissalPattern.example_sessions.length > 0 ? (
        <Sessions sessions={selectedDismissalPattern.example_sessions} />
      ) : (
        <Flex
          align="center"
          justify="center"
          px="6"
          py="9"
          style={{ border: `1px solid ${grayA.grayA4}`, borderRadius: 12 }}
        >
          <Text size="2" color="gray">
            No example sessions found for this dismissal pattern.
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default DismissalPatternInspector;

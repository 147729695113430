import React, { useState } from "react";
import {
  TextField,
  Button,
  Flex,
  Text,
  IconButton,
  Select,
  Dialog,
} from "@radix-ui/themes";
import { gray } from "@radix-ui/colors";
import { PlusIcon } from "@radix-ui/react-icons";
import { List, X } from "lucide-react";
import { useOrganization } from "./context/OrganizationContext";

const LOCATION_TYPES = [
  { key: "embedded", label: "Embedded" },
  { key: "overlay", label: "Overlay" },
];

const FIELD_TYPES = [
  { key: "text", label: "Text" },
  { key: "url", label: "URL" },
  { key: "image", label: "Image" },
];

const InputLabel = ({ label, error, children, style }) => (
  <label style={style}>
    <Flex justify="between" align="center" mb="1">
      <Text as="div" size="1" color="gray" weight="medium">
        {label}
      </Text>
      {error && (
        <Text color="red" size="1">
          {error}
        </Text>
      )}
    </Flex>
    {children}
  </label>
);

const SectionHeader = ({ icon: Icon, text, ...styleProps }) => (
  <Flex align="center" gap="2" mt="5" {...styleProps}>
    <Icon color={gray.gray9} size={16} strokeWidth={1.75} />
    <Text size="2" color="gray" weight="medium">
      {text}
    </Text>
  </Flex>
);

const InterventionField = ({ field, index, onRemove, onChange, errors }) => {
  const handleChange = (key) => (value) => onChange(index, key, value);

  const inputProps = (key, Component = TextField.Root) => ({
    value: field[key],
    [Component === Select ? "onValueChange" : "onChange"]: (e) =>
      handleChange(key)(e.target ? e.target.value : e),
    ...(errors[`fields.${index}.${key}`] && {
      color: "red",
      style: { border: "2px solid #EC8E7B", boxShadow: "none" },
    }),
  });

  return (
    <Flex
      direction="column"
      gap="4"
      py="4"
      style={{ borderBottom: `1px solid ${gray.gray4}` }}
    >
      <Flex justify="between" align="center">
        <Flex gap="3" style={{ width: "100%" }}>
          <InputLabel label="Field name" style={{ width: "70%" }}>
            <TextField.Root placeholder="Name" {...inputProps("field_name")} />
          </InputLabel>

          <InputLabel label="Description" style={{ width: "100%" }}>
            <TextField.Root
              placeholder="Description"
              {...inputProps("field_description")}
            />
          </InputLabel>

          <InputLabel label="Type">
            <Select.Root {...inputProps("field_type", Select)}>
              <Select.Trigger
                placeholder="Select type"
                style={{ width: "100%" }}
              />
              <Select.Content position="popper" variant="soft">
                {FIELD_TYPES.map(({ key, label }) => (
                  <Select.Item key={key} value={key}>
                    {label}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </InputLabel>

          <InputLabel
            label="Required"
            error={errors[`fields.${index}.required`]}
          >
            <Select.Root {...inputProps("field_required", Select)}>
              <Select.Trigger
                placeholder="Select required"
                style={{ width: "100%" }}
              />
              <Select.Content position="popper" variant="soft">
                {[true, false].map((required) => (
                  <Select.Item key={required} value={required}>
                    {required ? "Yes" : "No"}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </InputLabel>
        </Flex>

        <IconButton
          variant="ghost"
          color="gray"
          onClick={() => onRemove(index)}
          ml="4"
          mr="2"
        >
          <X color={gray.gray9} size={16} strokeWidth={1.8} />
        </IconButton>
      </Flex>
    </Flex>
  );
};

const initialInterventionField = {
  field_name: "",
  field_description: "",
  field_type: "text",
  field_required: true,
};
const initialFormValues = {
  name: "",
  description: "",
  fields: [initialInterventionField],
  type: "embedded",
  url: "",
};

const NewTouchpointLocationDialog = ({ trigger }) => {
  const { createTouchpointLocation, getTouchpointLocations } =
    useOrganization();
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);

  const handleOpenChange = (isOpen) => {
    setOpen(isOpen);
    setFormValues(initialFormValues);
    setErrors({});
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.name) newErrors.name = "Name is required";
    if (!formValues.description)
      newErrors.description = "Description is required";

    formValues.fields.forEach((field, index) => {
      if (!field.field_name.trim())
        newErrors[`fields.${index}.field_name`] = "Field name is required";
      if (!field.field_description.trim())
        newErrors[`fields.${index}.field_description`] =
          "Field description is required";
      if (!field.field_type)
        newErrors[`fields.${index}.field_type`] = "Field type is required";
      if (field.field_required === "")
        newErrors[`fields.${index}.field_required`] =
          "Required field must be set";
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (field) => (value) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
    if (errors[field]) setErrors((prev) => ({ ...prev, [field]: null }));
  };

  const handleAddInterventionField = () => {
    setFormValues((prev) => ({
      ...prev,
      fields: [...prev.fields, initialInterventionField],
    }));
  };

  const handleInterventionFieldChange = (index, key, value) => {
    setFormValues((prev) => ({
      ...prev,
      fields: prev.fields.map((field, i) =>
        i === index ? { ...field, [key]: value } : field
      ),
    }));
    if (errors[`fields.${index}.${key}`]) {
      setErrors((prev) => ({ ...prev, [`fields.${index}.${key}`]: null }));
    }
  };

  const handleRemoveInterventionField = (index) => {
    setFormValues((prev) => ({
      ...prev,
      fields:
        prev.fields.length > 1
          ? prev.fields.filter((_, i) => i !== index)
          : prev.fields,
    }));
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      await createTouchpointLocation(
        formValues.name,
        formValues.type,
        formValues.description,
        formValues.fields,
        formValues.url
      );
      await getTouchpointLocations();
      setOpen(false);
    }
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>
      <Dialog.Content style={{ maxWidth: 720 }}>
        <Dialog.Title mb="4">New touchpoint location</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Fill in the details below to create a new touchpoint location.
        </Dialog.Description>

        <Flex direction="column" gap="4">
          <Flex gap="3">
            <InputLabel
              label="Name"
              error={errors.name}
              style={{ width: "40%" }}
            >
              <TextField.Root
                placeholder="Name"
                value={formValues.name}
                onChange={(e) => handleChange("name")(e.target.value)}
                {...(errors.name && {
                  color: "red",
                  style: { border: "2px solid #EC8E7B", boxShadow: "none" },
                })}
              />
            </InputLabel>

            <InputLabel
              label="Description"
              error={errors.description}
              style={{ width: "60%" }}
            >
              <TextField.Root
                placeholder="Description"
                value={formValues.description}
                onChange={(e) => handleChange("description")(e.target.value)}
                {...(errors.description && {
                  color: "red",
                  style: { border: "2px solid #EC8E7B", boxShadow: "none" },
                })}
              />
            </InputLabel>

            <InputLabel label="Type">
              <Select.Root
                value={formValues.type}
                onValueChange={(e) => handleChange("type")(e)}
                {...(errors.type && {
                  color: "red",
                  style: { border: "2px solid #EC8E7B", boxShadow: "none" },
                })}
              >
                <Select.Trigger
                  placeholder="Select type"
                  style={{ width: "100%" }}
                />
                <Select.Content position="popper" variant="soft">
                  {LOCATION_TYPES.map(({ key, label }) => (
                    <Select.Item key={key} value={key}>
                      {label}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </InputLabel>
          </Flex>

          <Flex gap="3">
            {formValues.type === "embedded" && (
              <InputLabel
                label="URL pattern"
                error={errors.url}
                style={{ width: "100%" }}
              >
                <TextField.Root
                  placeholder="URL"
                  value={formValues.url}
                  onChange={(e) => handleChange("url")(e.target.value)}
                  {...(errors.url && {
                    color: "red",
                    style: { border: "2px solid #EC8E7B", boxShadow: "none" },
                  })}
                />
              </InputLabel>
            )}
          </Flex>

          <SectionHeader icon={List} text="Fields" mt="3" />

          <Flex
            direction="column"
            px="4"
            style={{ borderRadius: 8, background: gray.gray2 }}
          >
            {formValues.fields.map((field, index) => (
              <InterventionField
                key={index}
                field={field}
                index={index}
                onChange={handleInterventionFieldChange}
                onRemove={handleRemoveInterventionField}
                errors={errors}
              />
            ))}

            <Button
              size="2"
              variant="ghost"
              onClick={handleAddInterventionField}
              my="4"
              style={{ width: "fit-content" }}
            >
              <PlusIcon />
              Add Field
            </Button>
          </Flex>
        </Flex>

        <Flex gap="3" mt="5" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </Dialog.Close>
          <Button onClick={handleSubmit}>Create</Button>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default NewTouchpointLocationDialog;

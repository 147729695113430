import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Text,
  Flex,
  TextField,
  Button,
  Badge,
  ScrollArea,
} from "@radix-ui/themes";
import { gray, grayA } from "@radix-ui/colors";
import * as radixColors from "@radix-ui/colors";
import {
  ChevronDown,
  Calendar,
  Building,
  SquareUserRound,
  SlidersHorizontal,
  ArrowDownWideNarrow,
  Goal,
  Route,
  SearchX,
  MessageSquareHeart,
  ChartPie,
  Radar,
  FilterIcon,
  ArrowDownNarrowWide,
  Users,
  UserSquare,
  Workflow,
  Target,
  Radio,
} from "lucide-react";
import LateralUI from "@lateral-ai/ui-react";
import { MagnifyingGlassIcon, PlusIcon } from "@radix-ui/react-icons";
import { useOpportunity } from "./context/OpportunityContext";
import LucideIcon from "./LucideIcon";
import Icon from "./Icon";
import Select from "./Select";
import {
  toTimeAgo,
  abbrevNum,
  capitalize,
  toPercent,
  pluralize,
} from "./utils";

// TODO: create shared components for Toolbar, Search, EmptyState, Filter, ActionCard, FilterBar
const Toolbar = ({ onSearch }) => {
  return (
    <Flex
      direction="column"
      justify="between"
      align="center"
      style={{ borderBottom: `1px solid ${grayA.grayA4}` }}
    >
      <LateralUI.Frame lateralLocationId="ia_tp_loc_7beffa238ffc45e1">
        <div>
          <p data-lateral-field-id="ia_tp_loc_field_13456b6f8c67472c">
            Default hello world
          </p>
          <button data-lateral-touchpoint-dismissal>Close</button>
        </div>
      </LateralUI.Frame>
      <Flex
        align="center"
        justify="between"
        px="5"
        style={{
          width: "100%",
          maxWidth: 1024,
          height: 60,
        }}
      >
        <Flex align="center" gap="3">
          <Radar size={20} strokeWidth={2} color={grayA.grayA8} />
          <Text size="5" style={{ fontWeight: 600 }}>
            Opportunities
          </Text>
        </Flex>
        <Search onSearch={onSearch} />
      </Flex>
    </Flex>
  );
};

const Search = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    onSearch(term);
  };

  return (
    <TextField.Root
      size="2"
      placeholder="Search..."
      radius="large"
      style={{ width: "240px" }}
      value={searchTerm}
      onChange={handleSearch}
    >
      <TextField.Slot>
        <MagnifyingGlassIcon height="16" width="16" />
      </TextField.Slot>
    </TextField.Root>
  );
};

const EmptyState = ({ message, icon: Icon }) => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    py="9"
    gap="4"
    style={{ border: `1px solid ${grayA.grayA4}`, borderRadius: 16 }}
  >
    <Icon size={40} strokeWidth={1.5} color={gray.gray6} />
    <Text size="2" color="gray" align="center">
      {message}
    </Text>
  </Flex>
);

const Filter = ({ icon: Icon, value, options, onChange }) => {
  const selectedOption =
    options.find((option) => option.value === value) || options[0];

  return (
    <Flex
      display={{ initial: "none", sm: "flex" }}
      style={{ userSelect: "none" }}
    >
      <Select.Root value={value} onValueChange={onChange}>
        <Select.Trigger variant="ghost" color="gray">
          <Flex align="center" gap="2">
            <Icon size={14} color={grayA.grayA9} />
            <Text size="1" weight="medium" style={{ color: grayA.grayA10 }}>
              {selectedOption.label}
            </Text>
            <ChevronDown size={14} color={gray.gray9} />
          </Flex>
        </Select.Trigger>
        <Select.Content position="popper">
          {options.map((option) => (
            <Select.Item key={option.value} value={option.value}>
              {option.label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    </Flex>
  );
};

const ActionCard = ({ icon: Icon, title, description, color }) => {
  const colorScale = radixColors[color];
  const bgColor = colorScale[`${color}3`];
  const iconColor = colorScale[`${color}9`];

  return (
    <Flex
      px="22px"
      py="14px"
      style={{
        width: "100%",
        borderRadius: 16,
        border: `1px solid ${grayA.grayA4}`,
        boxShadow:
          "0px 1px 3px 0px rgba(0, 0, 0, 0.01), 0px 2px 1px -1px rgba(0, 0, 0, 0.01), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.01)",
      }}
    >
      <Flex align="center" gap="4">
        <Flex
          align="center"
          justify="center"
          style={{
            backgroundColor: bgColor,
            borderRadius: 999,
            width: 32,
            height: 32,
          }}
        >
          <Icon size={16} strokeWidth={2} color={iconColor} />
        </Flex>
        <Flex direction="column" gap="2px">
          <Text size="2" weight="medium">
            {title}
          </Text>
          <Text size="1" weight="medium" style={{ color: grayA.grayA9 }}>
            {description}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const FilterBar = ({ sortBy, onSortChange }) => (
  <Flex
    justify="between"
    align="center"
    px="5"
    py="3"
    style={{
      backgroundColor: "#F7F7F9",
      borderRadius: 12,
    }}
  >
    <Flex gap="5">
      <Filter
        key="workflow"
        icon={Route}
        value="all"
        options={[
          { value: "all", label: "All workflows" },
          { value: "team-collaboration", label: "Team collaboration" },
          { value: "theme-customization", label: "Theme customization" },
          { value: "watermark", label: "Watermark" },
          { value: "screenshot-enhancement", label: "Screenshot enhancement" },
        ]}
      />
      <Filter
        key="customer"
        icon={Building}
        value="all"
        options={[
          { value: "all", label: "All customers" },
          { value: "ibm", label: "IBM" },
          { value: "microsoft", label: "Microsoft" },
          { value: "google", label: "Google" },
          { value: "amazon", label: "Amazon" },
          { value: "facebook", label: "Facebook" },
          { value: "twitter", label: "Twitter" },
          { value: "linkedin", label: "LinkedIn" },
          { value: "instagram", label: "Instagram" },
          { value: "snapchat", label: "Snapchat" },
          { value: "tiktok", label: "TikTok" },
        ]}
      />
      <Filter
        key="date"
        icon={Calendar}
        value="30"
        options={[
          { value: "7", label: "Last 7 days" },
          { value: "30", label: "Last 30 days" },
          { value: "90", label: "Last 90 days" },
        ]}
      />
      <Button variant="ghost" color="gray">
        <Flex align="center" gap="2" style={{ color: gray.gray11 }}>
          <SlidersHorizontal size={14} color={gray.gray9} />
          <Flex display={{ initial: "none", sm: "flex" }}>
            <Text size="1" weight="medium" style={{ color: grayA.grayA10 }}>
              More filters
            </Text>
          </Flex>
          <Flex display={{ initial: "flex", sm: "none" }}>
            <Text size="1" weight="medium">
              Filters
            </Text>
          </Flex>
        </Flex>
      </Button>
    </Flex>
    <Flex align="center" gap="2">
      <Filter
        icon={ArrowDownWideNarrow}
        value={sortBy}
        options={[
          { value: "reach", label: "Estimated reach" },
          { value: "dateDiscovered", label: "Recently discovered" },
          { value: "message_saturation", label: "Least saturated" },
        ]}
        onChange={onSortChange}
      />
    </Flex>
  </Flex>
);

const Attribute = ({ icon: Icon, text }) => (
  <Flex align="center" gap="5px" style={{ opacity: 0.8 }}>
    <Icon size={14} color={grayA.grayA10} />
    <Text size="1" style={{ color: grayA.grayA10 }}>
      {text}
    </Text>
  </Flex>
);

const OpportunityCard = ({
  id,
  opportunity_name,
  opportunity_summary,
  opportunity_size,
  // opportunity_workflow,
  // created_at,
  // message_saturation,
  onCardClick,
  onTouchpointCreate,
}) => (
  <Flex
    style={{
      borderRadius: 16,
      border: `1px solid ${grayA.grayA4}`,
      boxShadow:
        "0px 1px 3px 0px rgba(0, 0, 0, 0.01), 0px 2px 1px -1px rgba(0, 0, 0, 0.01), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.01)",
      // cursor: "pointer",
    }}
    // onClick={onCardClick}
  >
    <Flex
      direction="column"
      justify="between"
      px="5"
      py="20px"
      style={{ flex: 1 }}
    >
      <Flex direction="column" gap="4">
        {/* <Flex gap="2" align="center">
          <Badge
            color="gray"
            radius="full"
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            <Route size={12} color={grayA.grayA10} />
            {opportunity_workflow}
          </Badge>
        </Flex> */}
        <Flex direction="column" gap="2">
          <Text size="3" weight="medium">
            {opportunity_name}
          </Text>
          <Text size="2" color="gray" style={{ whiteSpace: "pre-wrap" }}>
            {opportunity_summary}
          </Text>
        </Flex>
      </Flex>
      <Button
        size="1"
        mt="4"
        variant="soft"
        style={{ width: "fit-content" }}
        onClick={onTouchpointCreate}
      >
        <PlusIcon height="12" width="12" />
        Create touchpoint
      </Button>
      {/* <Flex gap="4" align="center">
        <Attribute icon={Calendar} text={toTimeAgo(created_at)} />
        <Attribute
          icon={MessageSquareHeart}
          text={`${toPercent(message_saturation)} message saturation`}
        />
      </Flex> */}
    </Flex>
    <Flex p="20px" pl="0">
      <Flex
        direction="column"
        gap="3"
        p="4"
        pt="3"
        style={{
          backgroundColor: gray.gray2,
          borderRadius: 10,
          width: 276,
          height: "fit-content",
        }}
      >
        <Flex
          align="center"
          gap="10px"
          pb="3"
          mb="1"
          mx="-16px"
          px="16px"
          style={{ borderBottom: `1px solid ${grayA.grayA4}` }}
        >
          <Radio size={16} color={grayA.grayA8} />
          <Text
            size="1"
            weight="medium"
            wrap="nowrap"
            style={{ color: grayA.grayA11 }}
          >
            Estimated reach
          </Text>
          {/* <Flex>
            <Text
              size="1"
              weight="medium"
              wrap="nowrap"
              style={{ color: grayA.grayA11 }}
            >
              {opportunity_size.num_sessions}{" "}
              {pluralize("session", opportunity_size.num_sessions)} across{" "}
              {opportunity_size.num_users}{" "}
              {pluralize("user", opportunity_size.num_users)}
            </Text>
          </Flex> */}
        </Flex>
        <Flex direction="column" gap="4">
          {/* {opportunity_audience.breakdown.map(({ icon_name, text }, index) => (
            <Flex key={index} align="center" gap="2">
              <LucideIcon
                name={icon_name}
                size={12}
                strokeWidth={2.25}
                color={gray.gray9}
                style={{ opacity: 0.9 }}
              />
              <Text size="1" truncate style={{ color: grayA.grayA10 }}>
                {text}
              </Text>
            </Flex>
          ))} */}
          <Flex direction="column" gap="3">
            <Flex align="center" justify="between">
              <Flex align="center" gap="3">
                <Users size={16} strokeWidth={1.75} color={grayA.grayA8} />
                <Text size="1" style={{ color: grayA.grayA11 }}>
                  Users
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="center"
                px="2"
                py="2px"
                style={{ backgroundColor: "white", borderRadius: 999 }}
              >
                <Text size="1" style={{ color: grayA.grayA11 }}>
                  {opportunity_size.num_users}
                </Text>
              </Flex>
            </Flex>
            <Flex align="center" justify="between">
              <Flex align="center" gap="3">
                <Workflow size={16} strokeWidth={1.75} color={grayA.grayA8} />
                <Text size="1" style={{ color: grayA.grayA11 }}>
                  Sessions
                </Text>
              </Flex>
              <Flex
                align="center"
                justify="center"
                px="2"
                py="2px"
                style={{ backgroundColor: "white", borderRadius: 999 }}
              >
                <Text size="1" style={{ color: grayA.grayA11 }}>
                  {opportunity_size.num_sessions}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
);

const OpportunityList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { getOpportunities } = useOpportunity();
  const [opportunities, setOpportunities] = useState([]);
  const [sortBy, setSortBy] = useState("reach");
  const navigate = useNavigate();

  useEffect(() => {
    getOpportunities().then(setOpportunities);
  }, [getOpportunities]);

  const handleSortChange = useCallback((value) => {
    setSortBy(value);
  }, []);

  const filteredOpportunityCards = useMemo(() => {
    let cards = opportunities;

    if (searchTerm) {
      cards = cards.filter((card) =>
        Object.values(card).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    return cards.sort((a, b) => {
      if (sortBy === "reach") {
        const userDiff =
          b.opportunity_size.num_users - a.opportunity_size.num_users;
        return userDiff !== 0
          ? userDiff
          : b.opportunity_size.num_sessions - a.opportunity_size.num_sessions;
      } else if (sortBy === "dateDiscovered") {
        return new Date(b.created_at) - new Date(a.created_at);
      } else if (sortBy === "message_saturation") {
        return a.message_saturation - b.message_saturation;
      }
      return 0;
    });
  }, [searchTerm, opportunities, sortBy]);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  return (
    <Flex direction="column">
      <Toolbar onSearch={handleSearch} />
      <ScrollArea
        scrollbars="vertical"
        type="scroll"
        style={{ height: "calc(100vh - 80px)" }}
      >
        <Flex
          direction="column"
          gap="5"
          style={{
            maxWidth: 1024,
            minWidth: 860,
            margin: "0 auto",
            padding: "24px 24px 48px",
            width: "100%",
          }}
        >
          <FilterBar sortBy={sortBy} onSortChange={handleSortChange} />
          <Flex direction="column" gap="4">
            {filteredOpportunityCards.length > 0 ? (
              filteredOpportunityCards.map((opportunity, index) => (
                <OpportunityCard
                  key={index}
                  {...opportunity}
                  onCardClick={() =>
                    navigate(`/opportunities/${opportunity.id}/view`)
                  }
                  onTouchpointCreate={() =>
                    navigate(
                      `/touchpoints/create?opportunity=${opportunity.id}`
                    )
                  }
                />
              ))
            ) : (
              <EmptyState
                message={
                  <>
                    No matching opportunities found. <br /> Try adjusting your
                    search.
                  </>
                }
                icon={SearchX}
              />
            )}
          </Flex>
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

export default OpportunityList;

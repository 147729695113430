import React from "react";
import { Handle, useReactFlow } from "reactflow";
import { Badge, Button, Flex, Text } from "@radix-ui/themes";
import { gray, violet, amber, green, blue, blueA } from "@radix-ui/colors";
import { NODE_STYLE } from "../utils/constants";
import useFlowActions from "../hooks/useFlowActions";
import AnimatedNodeToolbar from "../AnimatedNodeToolbar";
import {
  FoldVertical,
  MousePointerClick,
  Plus,
  TriangleAlert,
  UnfoldVertical,
  TrendingUp,
  Lightbulb,
  MessageSquareDot,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useOpportunity } from "../context/OpportunityContext";

const STATUS_CONFIG = {
  stuck: {
    label: "Friction",
    icon: TriangleAlert,
    badgeStyle: {
      background: amber.amber9,
      color: "#915930",
      border: `1px solid #E2A630`,
    },
    nodeBackground: "#FFECB7",
    textColor: "#915930",
  },
  amenable: {
    label: "Engagement opportunity",
    icon: MessageSquareDot,
    badgeStyle: {
      background: blue.blue9,
      color: blue.blue1,
      border: `1px solid ${blueA.blueA10}`,
    },
    nodeBackground: blue.blue4,
    textColor: blueA.blueA11,
  },
  normal: {
    nodeBackground: NODE_STYLE.background,
    textColor: gray.gray11,
  },
};

const StatusBadge = ({ status }) => {
  const config = STATUS_CONFIG[status];
  if (!config || status === "normal") return null;

  const { label, icon: Icon, badgeStyle } = config;

  return (
    <Badge
      variant="solid"
      size="1"
      style={{
        borderRadius: "8px 8px 0 0",
        height: "16px",
        fontSize: 10,
        gap: "4px",
        width: "fit-content",
        paddingTop: "2px",
        zIndex: 1,
        ...badgeStyle,
        borderBottom: "none",
      }}
    >
      <Icon color={badgeStyle.color} height={10} width={10} />
      <Text>{label}</Text>
    </Badge>
  );
};

const NodeContent = ({ status, groupName }) => {
  const config = STATUS_CONFIG[status];
  return (
    <Flex gap="2" align="center">
      <MousePointerClick
        color={status !== "normal" ? config.textColor : gray.gray8}
        opacity={status !== "normal" ? 0.5 : 1}
        height={20}
        width={18}
      />
      <Text
        color="gray"
        size="3"
        weight="medium"
        style={{ color: config.textColor }}
      >
        {groupName}
      </Text>
    </Flex>
  );
};

const NodeToolbar = ({ status, expanded, onAddNode, onToggleExpand }) => (
  <AnimatedNodeToolbar position="bottom">
    <Flex gap="2">
      {status !== "normal" && (
        <Button size="1" onClick={onAddNode}>
          <Plus size={12} />
          Create touchpoint
        </Button>
      )}
      <Button
        variant="outline"
        size="1"
        style={{ background: gray.gray2 }}
        onClick={onToggleExpand}
      >
        {expanded ? <FoldVertical size={12} /> : <UnfoldVertical size={12} />}
        {expanded ? "Collapse" : "Expand"}
      </Button>
    </Flex>
  </AnimatedNodeToolbar>
);

const EventGroupNode = ({ id, data, selected }) => {
  const { getNode, getNodes, setNodes, setEdges } = useReactFlow();
  const { opportunity } = useOpportunity();
  const navigate = useNavigate();
  const {
    createEventsNode,
    createPlaceholderNode,
    createEdge,
    createBadgeNode,
  } = useFlowActions();

  const currentNode = getNode(id);
  const status = data.event_group.is_stuck
    ? "stuck"
    : data.event_group.is_amenable
      ? "amenable"
      : "normal";

  const updateEvents = (parentNode) => {
    const expanded = parentNode.data.expanded;
    if (expanded) {
      const eventsNode = createEventsNode(data.event_group.events, currentNode);

      setNodes((nds) => [
        ...nds.map((n) => (n.id === id ? parentNode : n)),
        eventsNode,
      ]);
      setEdges((eds) => {
        // Remove the edge connecting this event group to the next event group
        const filteredEdges = eds.filter((edge) => edge.source !== id);
        // Add the new edges for events, including the self-connecting edge
        const newEdges = [
          ...filteredEdges,
          createEdge(id, eventsNode.id),
          createEdge(eventsNode.id, eventsNode.id),
        ];
        // Connect the events node to the next event group
        const nextEventGroupEdge = eds.find((edge) => edge.source === id);
        if (nextEventGroupEdge) {
          newEdges.push(createEdge(eventsNode.id, nextEventGroupEdge.target));
        }
        return newEdges;
      });
    } else {
      setNodes((nds) =>
        nds
          .filter((node) => !node.id.startsWith(`events-${id}`))
          .map((n) => (n.id === id ? parentNode : n))
      );
      setEdges((eds) => {
        // Remove all edges connected to events
        const filteredEdges = eds.filter(
          (edge) =>
            !edge.source.startsWith(`events-${id}`) &&
            !edge.target.startsWith(`events-${id}`)
        );
        // Find the next event group
        const nextEventGroupEdge = eds.find(
          (edge) =>
            edge.source.startsWith(`events-${id}`) &&
            !edge.target.startsWith(`events-${id}`)
        );
        // If there's a next event group, connect this event group to it
        if (nextEventGroupEdge) {
          filteredEdges.push(createEdge(id, nextEventGroupEdge.target));
        }
        return filteredEdges;
      });
    }
  };

  const toggleExpand = () => {
    const updatedParentNode = {
      ...getNode(id),
      data: {
        ...data,
        expanded: !data.expanded,
      },
      selected: false,
    };
    updateEvents(updatedParentNode);
  };

  const handleAddNode = ({ beforeAfter = false }) => {
    setNodes((nds) => [
      ...nds.map((node) =>
        node.id === id ? { ...node, selected: false } : node
      ),
      ...(beforeAfter
        ? [
            createBadgeNode("BEFORE", "gray", currentNode),
            createBadgeNode("RESOLVED", "green", currentNode),
          ]
        : []),
      createPlaceholderNode(data.event_group, currentNode),
    ]);

    setEdges((eds) => {
      const directChild = eds
        .filter((edge) => edge.source === id)
        .map((edge) => getNode(edge.target))
        .pop();

      const newEdges = [...eds.filter((edge) => edge.source !== id)];

      if (beforeAfter) {
        newEdges.push(
          createEdge(id, `badge-${id}-BEFORE`),
          createEdge(`badge-${id}-BEFORE`, directChild.id),
          createEdge(id, `badge-${id}-RESOLVED`, green.green9),
          createEdge(`badge-${id}-RESOLVED`, "placeholder", green.green9)
        );
      } else {
        newEdges.push(createEdge(id, "placeholder"));
      }

      return newEdges;
    });
  };

  const nodeStyle = {
    ...NODE_STYLE,
    padding: "12px 16px 13px",
    background: STATUS_CONFIG[status].nodeBackground,
    ...(selected && {
      border: "1px solid transparent",
      boxShadow: `0 0 0 2px ${violet.violet9}, ` + NODE_STYLE.boxShadow,
    }),
  };

  return (
    <Flex
      direction="column"
      align="center"
      onDoubleClick={() => {
        if (!getNodes().some((node) => node.data.status)) {
          toggleExpand();
        }
      }}
    >
      <StatusBadge status={status} />
      <div style={nodeStyle}>
        <NodeContent status={status} groupName={data.event_group.group_name} />
        <Handle
          type="target"
          position="top"
          isConnectable={false}
          style={{ visibility: "hidden" }}
        />
        <Handle
          type="source"
          position="bottom"
          isConnectable={false}
          style={{ visibility: "hidden" }}
        />
        <NodeToolbar
          status={status}
          expanded={data.expanded}
          onAddNode={() =>
            navigate(`/touchpoints/create?opportunity=${opportunity.id}`)
          }
          onToggleExpand={toggleExpand}
        />
      </div>
    </Flex>
  );
};

export default EventGroupNode;
